import styled from "styled-components";
import { Animations } from "theme/styles/Animations";

const ContainerSC = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  background-color: transparent;
  backdrop-filter: blur(25px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 43px 28px;
  transition: 0.2s all linear;
`;

const IconContainerSC = styled("div")`
  margin-bottom: 20px;
`;

const DescSC = styled("p")`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #ffffff;
  white-space: pre-wrap;
`;

const HoverSC = styled("div")`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;

  border: 2px solid #ffffff;

  transition: ${Animations.LINEAR_02};
  &:hover {
    border-width: 6px;
  }
`;

export const useFlipFrontStyles = () => ({
  ContainerSC,
  DescSC,
  IconContainerSC,
  HoverSC,
});
