import React, { useState } from "react";
import { useCertificatePageStyles } from "routes/pages/CertificatePage/CertificatePage.styles";

import CPDNavbar from "routes/pages/CertificatePage/CPDNavbar/CPDNavbar";
import ErrorPage from "routes/pages/ErrorPage/ErrorPage";
import { UserData } from "routes/pages/CertificatePage/types";
import CPUser from "routes/pages/CertificatePage/CPUser/CPUser";

interface CertificatePageMobileProps {
  userData: UserData;
  isError: boolean;
  isLoading: boolean;
  isActivate: boolean;
}

const CertificatePageMobile = ({
  userData,
  isError,
  isLoading,
  isActivate,
}: CertificatePageMobileProps) => {
  const [selectedIcon, setSelectedIcon] = useState<string>("Cost");

  const handleIconClick = (iconName: string) => {
    if (selectedIcon === iconName) {
      setSelectedIcon("Cost");
    } else {
      setSelectedIcon(iconName);
    }
  };
  return (
    <>
      {!isError && isActivate && (
        <ContainerSC>
          <MainContainer isLoading={isLoading}>
            <CPUser userData={userData} selectedIcon={selectedIcon} />
            <CPDNavbar
              handleIconClick={handleIconClick}
              selectedIcon={selectedIcon}
            />
          </MainContainer>
          <LinkSC to={"/"}>на главную</LinkSC>
        </ContainerSC>
      )}
      {isError && <ErrorPage />}
      {!isActivate && !isError && (
        <MainContainer style={{ height: "100%" }}>
          <NonActivateSC>Сертификат пока не активирован</NonActivateSC>
          <LinkSC to={"/"}>на главную</LinkSC>
        </MainContainer>
      )}
    </>
  );
};

const { NonActivateSC, ContainerSC, LinkSC, MainContainer } =
  useCertificatePageStyles();

export default React.memo(CertificatePageMobile);
