import styled, { keyframes } from "styled-components";
import ForceIcon from "assets/icon/ForceIcon.svg";

const moveLeftRight = keyframes`
  0% {
    transform: translate(-6px, -50%);
  }
  50% {
    transform: translate(12px, -50%);
  }
  100% {
    transform: translate(-6px, -50%);
  }
`;

const ContainerSC = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  display: flex;
`;

const ButtonSC = styled.button`
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;

  &:before {
    display: inline-block;
    content: "";
    height: 12px;
    width: 12px;
    background: url(${ForceIcon});
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translate(0, -50%);
    animation: ${moveLeftRight} 2s linear infinite;
  }
`;

export const useDisscussProjectStyles = () => ({
  ContainerSC,
  ButtonSC,
});
