import React from "react";
import { MarqueeItemProps } from "UI/MarqueeUI/types";
import styled from "styled-components";
import MarqueeLetter from "UI/MarqueeUI/MarqueeLetter/MarqueeLetter";

const MarqueeWord = ({ word }: MarqueeItemProps) => {
  return (
    <WordSC>
      {word.split("").map((letter, letterIndex) => (
        <MarqueeLetter key={letterIndex} letter={letter} />
      ))}
      &nbsp;
    </WordSC>
  );
};

const WordSC = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  overflow: hidden;
  padding: 30px 0;
`;

export default React.memo(MarqueeWord);
