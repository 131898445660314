import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { Breakpoints } from "theme/styles/breakpoints";

const WrapperSC = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: ${ZIndex.POPUP};

  background: rgba(0, 0, 0, 0.9);
`;

const ContainerSC = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 810px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    padding: 0 16px;
    justify-content: center;
    align-items: center;
  }
`;

const TopContainerSC = styled.div`
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Breakpoints.TABLE}px) {
    margin-bottom: 18px;
  }
`;

const TitleSC = styled.h6`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const SubtitleSC = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 14px;
    line-height: normal;
    text-align: center;
  }
`;

const ListSC = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LiSC = styled.li`
  list-style-type: disc;

  @media (max-width: ${Breakpoints.TABLE}px) {
    list-style-type: none;
  }
`;

const ButtonSC = styled.button`
  @media (max-width: ${Breakpoints.TABLE}px) {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const styles = {
  WrapperSC,
  ContainerSC,
  TopContainerSC,
  TitleSC,
  SubtitleSC,
  ListSC,
  LiSC,
  ButtonSC,
};
