import React from "react";
import ContactsNav from "../ContactsNav/ContactsNav";
import { useContactsPageStyles } from "../ContactsPage.styles";
import Lottie from "lottie-react";
import EmailAnimation from "assets/animation/email.json";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const EmailPage = () => {
  return (
    <ContainerSC>
      <PageContainerSC>
        <TextSC>
          <a href="mailto:info@index-studio.ru">info@index-studio.ru</a>
        </TextSC>
        <ContactsNav />
        <ContentContainerCS>
          <Lottie animationData={EmailAnimation} />
        </ContentContainerCS>
        <ContactsNav />
      </PageContainerSC>
      <DiscusProject />
    </ContainerSC>
  );
};

const { ContainerSC, PageContainerSC, TextSC, ContentContainerCS } =
  useContactsPageStyles();

export default React.memo(EmailPage);
