import styled from "styled-components";
import { animated } from "@react-spring/web";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled(animated.div)`
  width: 100%;
  max-width: 512px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  max-height: 500px;
`;

const ParagraphSC = styled("p")`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  text-transform: uppercase;
  text-align: center;

  width: 512px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    padding: 0 10px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    padding: 0;
    width: 272px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    flex: 1;
  }
`;

const WrapperSC = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  height: 100%;

  flex-direction: column;
`;

export const useVacanciesPageStyles = () => ({
  ContainerSC,
  ParagraphSC,
  WrapperSC,
});
