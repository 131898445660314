import React from "react";
import { Size } from "theme/styles/Size";
import { useVacanciesSuccessSend } from "./useVacanciesSuccessSend";
import CircularTextUI from "UI/CircularTextUI/CircularTextUI";
import { useVacanciesSuccessSendStyles } from "./VacanciesSuccessSend.styles";
import styled from "styled-components";
import { motion } from "framer-motion";
import { ColorScheme } from "theme/styles/colorScheme";

interface InteractiveSuccessSendProps {
  handleClickRepeatSend: () => void;
}

const VacanciesSuccessSend = ({
  handleClickRepeatSend,
}: InteractiveSuccessSendProps) => {
  const { isText, isHover, mountedAnim, handleVisibleText, handleChangeHover } =
    useVacanciesSuccessSend();

  return (
    <ContainerSC>
      <CircleContainerSC
        transition={{ duration: 1 }}
        animate={{
          width: [Size.CIRCULAR_SIZE, Size.CIRCULAR_SIZE, 0],
          height: [Size.CIRCULAR_SIZE, Size.CIRCULAR_SIZE, 0],
          borderWidth: [0, Size.CIRCULAR_SIZE / 2, 0],
        }}
        onAnimationComplete={handleVisibleText}
      />
      {isText && (
        <TextContainerSC
          style={mountedAnim}
          onClick={handleClickRepeatSend}
          onMouseEnter={handleChangeHover(true)}
          onMouseLeave={handleChangeHover(false)}>
          <CircularTextUI rotate="right" isLarge={isHover}>
            отрпавить еще · отрпавить еще ·1
          </CircularTextUI>
        </TextContainerSC>
      )}
    </ContainerSC>
  );
};

const { ContainerSC, TextContainerSC } = useVacanciesSuccessSendStyles();

const CircleContainerSC = styled(motion.div)`
  position: relative;

  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;

  border-width: 2.5px;
  border-color: ${ColorScheme.WHITE};
  border-style: solid;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default React.memo(VacanciesSuccessSend);
