import styled from "styled-components";
import { Size } from "theme/styles/Size";
import { animated } from "@react-spring/web";

const ContainerSC = styled("div")`
  bottom: 150px;

  position: absolute;

  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextContainerSC = styled(animated.div)`
  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const useVacanciesSuccessSendStyles = () => ({
  ContainerSC,
  TextContainerSC,
});
