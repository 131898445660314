import styled from "styled-components";
import { ColorScheme } from "theme/styles/colorScheme";

const WrapperSC = styled.div`
  //height: 30%;
  min-height: 300px;

  color: ${ColorScheme.WHITE};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HelloSC = styled.h6`
  margin-bottom: 8px;

  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
`;

const SubtitleSC = styled.p`
  margin-bottom: 16px;

  font-size: 15px;
  font-weight: 600;
`;

const CostSC = styled.p`
  font-size: 57px;
  font-weight: 600;
  text-transform: uppercase;
`;

const ContainerNumbersSC = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkSC = styled.a`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;

export const useGPMToggleInfo = () => ({
  WrapperSC,
  HelloSC,
  SubtitleSC,
  CostSC,
  ContainerNumbersSC,
  LinkSC,
});
