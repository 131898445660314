import React from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";

interface MarqueeLetterProps {
  letter: string;
}

const MarqueeLetter = ({ letter }: MarqueeLetterProps) => {
  const anim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });
  return <SpanSC style={anim}>{letter}</SpanSC>;
};

const SpanSC = styled(animated.span)`
  position: relative;
`;

export default React.memo(MarqueeLetter);
