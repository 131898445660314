import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PriceNamespace } from "lib/constants/enums/PagesNamespace";
import { priceValues } from "lib/mock/priceData";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { popupStore } from "store/popupStore/popupStore";

export interface ValueItem {
  price: number;
  text: string;
  isOther?: boolean;
  time?: string;
}

const UseCalculatedPage = () => {
  const navigate = useNavigate();
  const { changeMouseProps } = changeCursorActive();
  const toggleOpenForm = popupStore((state) => state.changeToggleViewPopup);
  const { id } = useParams<{ id?: PriceNamespace }>();

  // получаем весь мок данных исходя от id страницы
  const prices = id ? priceValues[id] || [] : [];
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [addedValues, setAddedValues] = useState<ValueItem[]>([]);
  const [otherValues, setOtherValues] = useState<ValueItem[]>([]);
  const [allValues, setAllValues] = useState<ValueItem[]>([]);
  // анимация загрузки
  const [isLoading, setIsLoading] = useState(true);

  // показать форму
  const [showForm, setShowForm] = useState(false);

  // вернуться назад
  const goBack = () => {
    navigate(-1);
  };

  const handleShowForm = () => setShowForm(true);

  // добавляем предмет из массива
  const handleAddItem = (item: ValueItem) => {
    setAllValues((prevValues) =>
      prevValues.filter((addedItem) => addedItem.text !== item.text),
    );
  };

  // удаляем предмет из массива
  const handleDeleteItem = (item: ValueItem) => {
    setAllValues((prevValues) => [...prevValues, item]);
  };

  const handleToggleItem = (item: ValueItem) => {
    const isItemAdded = allValues.find(
      (addedItem) => addedItem.text === item.text,
    );

    if (isItemAdded) {
      handleAddItem(item);
    } else {
      handleDeleteItem(item);
    }
  };

  useEffect(() => {
    const otherItems = allValues.filter((item) => item.isOther);
    const addedItems = allValues.filter((item) => !item.isOther);

    setOtherValues(otherItems);
    setAddedValues(addedItems);
  }, [allValues]);

  // калькулируем стоимость
  const calculateTotalPrice = () => {
    let total = 0;
    addedValues.forEach((item) => {
      total += item.price;
    });
    setTotalPrice(total);
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [addedValues]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1400);

    return () => clearTimeout(timer);
  }, []);

  return {
    prices,
    changeMouseProps,
    totalPrice,
    otherValues,
    isLoading,
    showForm,

    toggleOpenForm,
    handleShowForm,
    goBack,
    handleToggleItem,
  };
};

export default UseCalculatedPage;
