import { useEffect, useRef } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { gsap, Draggable, ScrollTrigger } from "gsap/all";

export const usePricePageDesktop = () => {
  // Создаем референсы для элементов, с которыми будем работать.
  const galleryRef = useRef(null);
  const cardsRef = useRef(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const dragProxyRef = useRef(null);

  useEffect(() => {
    // Получаем текущие значения элементов из референсов.
    const gallery = galleryRef.current;
    const cards = cardsRef.current;
    const nextButton = nextButtonRef.current;
    const prevButton = prevButtonRef.current;
    const dragProxy = dragProxyRef.current;

    // Регистрируем плагины ScrollTrigger и Draggable из библиотеки gsap.
    gsap.registerPlugin(ScrollTrigger, Draggable);

    // Устанавливаем элементу gallery значение свойства autoAlpha: 1, чтобы сделать его видимым.
    gsap.set(gallery, { autoAlpha: 1 });

    // Устанавливаем начальные значения для карточек, которые будем анимировать.
    gsap.set(".cards li", {
      yPercent: 400,
      opacity: 0,
      scale: 0.2,
    });

    // Определяем функцию анимации для каждой карточки.
    // eslint-disable-next-line
    const animateFunc = (element: any) => {
      const tl = gsap.timeline();
      tl.fromTo(
        element,
        { scale: 0, opacity: 0, zIndex: -10, fontWeight: 500 },
        {
          scale: 1,
          opacity: 1,
          zIndex: 0,
          duration: 0.5,
          fontWeight: 600,
          filter: "blur(0px)",
          yoyo: true,
          repeat: 1,
          ease: "power1.in",
          immediateRender: false,
        },
      ).fromTo(
        element,
        { yPercent: 400 },
        { yPercent: -400, duration: 1, ease: "none", immediateRender: false },
        0,
      );
      return tl;
    };

    // Создаем бесшовную анимационную петлю для карточек.
    const spacing = 0.1;
    const snapTime = gsap.utils.snap(spacing);
    const cardsList = gsap.utils.toArray(".cards li");
    const seamlessLoop = buildSeamlessLoop(cardsList, spacing, animateFunc);

    // Инициализируем прогресс анимации для скроллинга.
    let iteration = 0;
    const playhead = { offset: 0 };
    const wrapTime = gsap.utils.wrap(0, seamlessLoop.duration());
    const scrub = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoop.time(wrapTime(playhead.offset));
      },
      duration: 0.5,
      ease: "power3",
      paused: true,
    });

    // Создаем ScrollTrigger для элемента gallery.
    const trigger = ScrollTrigger.create({
      start: 0,
      onUpdate(self: {
        scroll: () => never;
        end: number;
        direction: number;
        progress: number;
      }) {
        const scroll = self;
        // Проверяем, достигнут ли конец бесшовной петли анимации, и обновляем прогресс.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (scroll > self.end - 1) {
          wrap(1, 1);
        } else {
          // Если пользователь скроллит в обратном направлении, обновляем прогресс с учетом направления скролла.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (scroll < 1 && self.direction < 0) {
            wrap(-1, self.end - 1);
          } else {
            scrub.vars.offset =
              (iteration + self.progress) * seamlessLoop.duration();
            scrub.invalidate().restart();
          }
        }
      },
      end: "+=3000",
      pin: gallery,
    });

    // Преобразуем прогресс анимации в значение прокрутки.
    const progressToScroll = (progress: number) =>
      gsap.utils.clamp(
        1,
        trigger.end - 1,
        gsap.utils.wrap(0, 1, progress) * trigger.end,
      );

    // Обновляем состояние петли анимации и прокрутки на заданную величину и направление.
    const wrap = (iterationDelta: number, scrollTo: number) => {
      iteration += iterationDelta;
      trigger.scroll(scrollTo);
      trigger.update();
    };

    // Прокручиваем к определенному смещению в петле анимации.
    const scrollToOffset = (offset: number) => {
      const snappedTime = snapTime(offset),
        progress =
          (snappedTime - seamlessLoop.duration() * iteration) /
          seamlessLoop.duration(),
        scroll = progressToScroll(progress);
      if (progress >= 1 || progress < 0) {
        return wrap(Math.floor(progress), scroll);
      }
      trigger.scroll(scroll);
    };

    // Добавляем обработчики событий на кнопки next и prev для прокрутки анимации.
    nextButton?.addEventListener("click", () =>
      scrollToOffset(scrub.vars.offset + spacing),
    );

    prevButton?.addEventListener("click", () =>
      scrollToOffset(scrub.vars.offset - spacing),
    );

    // Функция для создания бесшовной анимационной петли из списка элементов.
    function buildSeamlessLoop(
      // eslint-disable-next-line
      items: any[],
      spacing: number,
      // eslint-disable-next-line
      animateFunc: { (element: never): any; (arg0: any): any },
    ) {
      const seamlessLoop = gsap.timeline({
        paused: true,
        repeat: -1,
        onRepeat() {
          this._time === this._dur && (this._tTime += this._dur - 0.01);
        },
        onReverseComplete() {
          this.totalTime(this.rawTime() + this.duration() * 100);
        },
      });
      const rawSequence = gsap.timeline({ paused: true });
      const cycleDuration = spacing * items.length;
      let dur = 0;

      items
        .concat(items)
        .concat(items)
        .forEach((item, i) => {
          const anim = animateFunc(items[i % items.length]);
          rawSequence.add(anim, i * spacing);
          dur || (dur = anim.duration());
        });

      seamlessLoop.fromTo(
        rawSequence,
        {
          time: cycleDuration + dur / 2,
        },
        {
          time: "+=" + cycleDuration,
          duration: cycleDuration,
          ease: "none",
        },
      );
      return seamlessLoop;
    }

    // Создаем DragProxy для возможности перетаскивания карточек мышью или касанием.
    Draggable.create(dragProxy, {
      type: "y",
      trigger: cards,
      onPress() {
        this.startOffset = scrub.vars.offset;
      },
      onDrag() {
        scrub.vars.offset = this.startOffset + (this.startY - this.y) * 0.002;
        scrub.invalidate().restart();
      },
      onDragEnd() {
        scrollToOffset(scrub.vars.offset);
      },
    });
  }, []);

  // Возвращаем референсы для использования в компоненте.
  return {
    cardsRef,
    galleryRef,
    dragProxyRef,
    nextButtonRef,
    prevButtonRef,
  };
};
