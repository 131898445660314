import { ApiNameSpace } from "lib/constants/enums/ApiNameSpace";

export const fetchGetGift = (id: string | undefined) => {
  return fetch(`${ApiNameSpace.GIFT}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
