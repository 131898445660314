import React from "react";
import { CasesModelType } from "lib/models/CasesModelType";
import { useFlipBackStyles } from "UI/FlipUI/FlipBack/FlipBack.styles";

const FlipBack = ({
  imageUrl,
  title,
}: Pick<CasesModelType, "imageUrl" | "title">) => {
  return (
    <BackSC>
      <ImageSC src={imageUrl} alt={title} />
    </BackSC>
  );
};

const { BackSC, ImageSC } = useFlipBackStyles();

export default React.memo(FlipBack);
