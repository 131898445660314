import styled, { css } from "styled-components";
import { Size } from "theme/styles/Size";
import { ZIndex } from "theme/styles/zIndex";
import { HEADER_ROUND_LENGTH } from "lib/mock/headerData";
import { Breakpoints } from "theme/styles/breakpoints";

const HeaderSC = styled("header")`
  position: relative;
  padding-top: 15px;
`;

const NavSC = styled("nav")`
  position: absolute;
  z-index: ${ZIndex.HEADER};
`;

const UlSC = styled("ul")<{ isVisible: boolean }>`
  overflow: hidden;
  max-height: ${(HEADER_ROUND_LENGTH - 1) * Size.HEADER_ITEM +
  Size.HEADER_ITEM_ACTIVE}px;
  padding: 0 15px;

  ${({ isVisible }) => isVisible && VisibleMixin};
`;

const VisibleMixin = css`
  width: 400px;
  min-height: 300px;
  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    width: 300px;
    min-height: 190px;
  }
`;

export const useHeaderStyles = () => ({
  HeaderSC,
  NavSC,
  UlSC,
});
