import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { memoize } from "hooks/useLodashMemoize";

const rotationStep = 90;
const minRotationDelay = 50;
const maxRotationDelay = 300;
const animationProbability = 0.7;

const getRandomRotationDelay = () =>
  Math.floor(Math.random() * (maxRotationDelay - minRotationDelay + 0.1)) +
  minRotationDelay;

interface RotatingLetterProps {
  children: ReactNode;
  isHovered: boolean;
}

const RotatingLetter = ({ children, isHovered }: RotatingLetterProps) => {
  const [rotation, setRotation] = useState<number>(0);
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  const rotateLetter = memoize((isUnmounted: boolean) => {
    if (isHovered && shouldAnimate) {
      // Проверяем, нужно ли анимировать букву.
      setTimeout(() => {
        if (!isUnmounted) {
          setRotation((prevRotation) => prevRotation + rotationStep);
        }
      }, getRandomRotationDelay());
    } else {
      setRotation(0);
    }
  });
  // const memoizeRotateLetter = memoize(rotateLetter);

  useEffect(() => {
    // Генерируем случайное число, чтобы определить, анимировать букву или нет.
    const randomAnimation = Math.random() < animationProbability;

    setShouldAnimate(randomAnimation);

    rotateLetter();
  }, [rotation, isHovered, shouldAnimate]);

  return <SpanSC rotation={rotation}>{children}</SpanSC>;
};

interface SpanProps {
  rotation: number;
}

const SpanSC = styled.span<SpanProps>`
  display: inline-block;
  transform: rotate(${(props) => props.rotation}deg);
  transition: transform 0.03s ease;
`;

export default RotatingLetter;
