import styled, { css } from "styled-components";

const buttonMixin = css`
  padding: 12.5px 48.5px;

  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  transition: all 0.3s ease;
`;

const ListSC = styled.ul`
  align-self: start;

  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;

  margin-top: 50px;
`;

const ButtonSC = styled.button<{ isVisible: boolean }>`
  ${buttonMixin};
  position: absolute;
  transform: translate(50%);
  transition-delay: ${({ isVisible }) => (isVisible ? "0.1s" : "0")};
  right: ${({ isVisible }) => (!isVisible ? "50%" : "30%")};
`;

const BackButtonSC = styled.button<{ isVisible: boolean }>`
  ${buttonMixin};
  position: absolute;
  transform: translate(-50%);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  left: ${({ isVisible }) => (isVisible ? "30%" : "-100px")};
`;

const ContainerButtonSC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 15px;
  margin-bottom: 8vh;
  position: relative;
  min-height: 39px;
`;

export const usePricePageMobileStyles = () => {
  return {
    ContainerButtonSC,
    ButtonSC,
    ListSC,
    BackButtonSC,
  };
};
