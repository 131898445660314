import React, { useState } from "react";
import { useCPUserStyles } from "routes/pages/CertificatePage/CPUser/CPUDesktop/CPUser.styles";
import CPTimer from "routes/pages/CertificatePage/CPTimer/CPTimer";
import { CPUMobileStyles } from "routes/pages/CertificatePage/CPUser/CPUMobile/CPUserMobile.styles";
import { ReactComponent as PartnerImg } from "assets/icon/PartnerCertificate.svg";
import { ReactComponent as LogoIndexImg } from "assets/icon/logoIndexCertificate.svg";
import { CPUserProps } from "routes/pages/CertificatePage/CPUser/CPUser";
import { infoData } from "lib/mock/infoData";
import CPConditionText from "routes/pages/CertificatePage/CPConditionText/CPConditionText";

export interface CPUMobileProps extends CPUserProps {
  cost: string;
}

const CPUMobile = ({ userData, selectedIcon, cost }: CPUMobileProps) => {
  const [isViewPopup, setIsViewPopup] = useState(false);

  const handleTogglePopup = () => {
    setIsViewPopup(!isViewPopup);
  };

  return (
    <>
      <HelloSC>Привет,</HelloSC>
      <NameSC>{userData.name}</NameSC>
      <DescriptionSC>
        Ура, ты {userData.sex === "Ж" ? "победила" : "победил"} в турнире{" "}
        <SpanContainerSC>{userData.tournament}</SpanContainerSC>!
      </DescriptionSC>
      <WrapperSC>
        {selectedIcon === "Cost" && (
          <>
            <TextSC>
              Мы дарим тебе{" "}
              <ButtonSC onClick={handleTogglePopup}>сертификат*</ButtonSC> на
            </TextSC>
            <CostSC>{cost} ₽</CostSC>
            <SubtitleSC>Используй его скорей!</SubtitleSC>
          </>
        )}
        {selectedIcon === "Timer" && <CPTimer time={userData.activate_at} />}
        {selectedIcon === "Phone" && (
          <ContainerNumbersSC>
            <LinkSC href={infoData.tel.first.num}>
              {infoData.tel.first.display}
            </LinkSC>
            <LinkSC href={infoData.tel.second.num}>
              {infoData.tel.second.display}
            </LinkSC>
          </ContainerNumbersSC>
        )}
        {selectedIcon === "Email" && (
          <LinkSC href={infoData.email.mail_to}>
            {infoData.email.display}
          </LinkSC>
        )}
        {selectedIcon === "Geo" && (
          <LinkSC>
            {infoData.geo.display.office}
            <br />
            {infoData.geo.display.city}
          </LinkSC>
        )}
        {selectedIcon === "Hand" && (
          <ImageContainerSC>
            <PartnerImg />
            <LogoIndexImg />
          </ImageContainerSC>
        )}
      </WrapperSC>

      {isViewPopup && <CPConditionText handleTogglePopup={handleTogglePopup} />}
    </>
  );
};

const {
  HelloSC,
  WrapperSC,
  TextSC,
  NameSC,
  CostSC,
  SubtitleSC,
  DescriptionSC,
  ButtonSC,
} = useCPUserStyles();

const { ImageContainerSC, SpanContainerSC, LinkSC, ContainerNumbersSC } =
  CPUMobileStyles();

export default React.memo(CPUMobile);
