import React, { CSSProperties } from "react";
import { useAboutPageMobileItem } from "./AboutPageMobileItem.styles";

interface AboutPageMobileItem {
  item: string;
  styles: CSSProperties;
}

const AboutPageMobileItem = ({ item, styles }: AboutPageMobileItem) => {
  const { fontVariationSettings, height, ...otherStyles } = styles;

  return (
    <>
      <ContainerSC style={{ height }}>
        <ItemSC
          style={otherStyles}
          initial={{ fontVariationSettings }}
          animate={{ fontVariationSettings }}
          transition={{
            fontVariationSettings: { duration: 0.5, delay: 0.6 },
          }}>
          {item}
        </ItemSC>
      </ContainerSC>
    </>
  );
};
const { ContainerSC, ItemSC } = useAboutPageMobileItem();
export default React.memo(AboutPageMobileItem);
