import { useState, useCallback, ChangeEvent } from "react";

type InputState = {
  [key: string]: string;
};

const isSymbol = (e: ChangeEvent<HTMLInputElement>) =>
  /^[a-zA-Zа-яА-Я]+$/.test(e.target.value);

//кастомный хук валидации и отображения ошибок

export const useFormAndValidation = () => {
  const [values, setValues] = useState<InputState>({});
  const [errors, setErrors] = useState<InputState>({});
  const [isValid, setIsValid] = useState<boolean>(false);

  //перебирает инпуты и записывает значения, ошибок и валидна ли форма
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const form = e.target.closest("form") as HTMLFormElement;

    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: e.target.validationMessage });
    setIsValid(form.checkValidity());

    if (name === "phone" && errors.phone && isSymbol(e)) {
      setErrors({
        ...errors,
        phone: "Введите номер телефона",
      });
    }
  };

  const checkValidity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const form = e.target.closest("form") as HTMLFormElement;
    const newErrors: InputState = {};

    for (const field of form.elements) {
      if (field instanceof HTMLInputElement && !field.checkValidity()) {
        newErrors[field.name] = field.validationMessage;
      }
    }
    setErrors(newErrors);
  };

  // сброс формы
  const resetForm = useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
    [setValues, setErrors, setIsValid],
  );

  return {
    values,
    errors,
    isValid,
    resetForm,

    handleChange,
    setValues,
    setIsValid,
    setErrors,
    checkValidity,
  };
};
