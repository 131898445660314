import React from "react";
import { useAboutPageMobile } from "./useAboutPageMobile";
import AboutPageMobileItem from "./AboutPageMobileItem/AboutPageMobileItem";
import { useAboutPageMobileStyles } from "./AboutPageMobile.styles";
import AboutPageMobileInfo from "./AboutPageMobileInfo/AboutPageMobileInfo";
import { aboutData } from "lib/mock/aboutData";

const AboutPageMobile = () => {
  const { data, isClicked, currentPattern, handleClickInfo } =
    useAboutPageMobile();

  return (
    <>
      <AboutPageMobileInfo
        isClicked={isClicked}
        handleClickInfo={handleClickInfo}
      />
      <ListSC isClicked={isClicked}>
        {data.map((item, index) => (
          <AboutPageMobileItem
            key={index}
            item={item}
            styles={currentPattern[index]}
          />
        ))}
      </ListSC>
      <AboutPageDescSC isClicked={isClicked}>
        {aboutData.textMobile}
      </AboutPageDescSC>
    </>
  );
};

const { ListSC, AboutPageDescSC } = useAboutPageMobileStyles();

export default React.memo(AboutPageMobile);
