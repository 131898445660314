import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled("nav")`
  width: 100%;

  position: absolute;
  bottom: -55px;
  z-index: ${ZIndex.UPPER_THREE};

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    bottom: -110px;
  }
`;

const ListSC = styled("ul")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:last-child {
    margin-right: 0;
  }
`;

export const useContactsNavStyles = () => ({
  ContainerSC,
  ListSC,
});
