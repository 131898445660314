import { ApiNameSpace } from "lib/constants/enums/ApiNameSpace";

type FetchContactsType = {
  name: string;
  phone: string;
  message: string;
};

export const fetchPostDiscount = (value: FetchContactsType) => {
  return fetch(ApiNameSpace.QRCODE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  });
};
