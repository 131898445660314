import styled, { css, keyframes } from "styled-components";
import ForceIcon from "assets/icon/ForceIcon.svg";
import { Breakpoints } from "theme/styles/breakpoints";

export const moveLeftRight = keyframes`
  0% {
    transform: translate(-6px, -50%);
  }
  50% {
    transform: translate(4px, -50%);
  }
  100% {
    transform: translate(-6px, -50%);
  }
`;

export const moveRightLeft = keyframes`
  0% {
    transform: translate(6px, -50%) rotate(180deg);
  }
  50% {
    transform: translate(-4px, -50%) rotate(180deg) ;
  }
  100% {
    transform: translate(6px, -50%) rotate(180deg);
  }
`;

const ContainerSC = styled.div<{
  config?: {
    pos: string;
    top: string;
    right: string;
  };
  isMobile?: boolean;
}>`
  position: absolute;

  top: 20px;
  right: 40px;

  display: flex;

  margin-bottom: 24px;

  ${({ config }) => css`
    position: ${config?.pos};
    top: ${config?.top};
    right: ${config?.right};
  `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 0;
      right: 20px;
    `}
`;

const ButtonSC = styled.button<{ isMobile: boolean }>`
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;

  &:before {
    display: ${({ isMobile }) => (isMobile ? "none" : "inline-block")};
    content: "";
    height: 12px;
    width: 12px;
    background: url(${ForceIcon});
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translate(0, -50%);
    animation: ${moveLeftRight} 1.7s linear infinite;
  }

  &:after {
    display: ${({ isMobile }) => (isMobile ? "none" : "inline-block")};
    content: "";
    height: 12px;
    width: 12px;
    background: url(${ForceIcon});
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translate(0, -50%) rotate(180deg);
    animation: ${moveRightLeft} 1.7s linear infinite;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 13px;
  }
`;

export const useDPMtStyles = () => ({
  ContainerSC,
  ButtonSC,
});
