import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";

const WrapperSC = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: none;
`;

const SwiperSlideSC = styled("ul")`
  position: absolute;
  width: 500px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
  padding: 0;
  margin: 0 auto;

  text-align: center;
`;

const SlideSC = styled("li")`
  width: 100%;
  height: 40px;
  text-align: center;

  position: absolute;
  top: 0;
  left: 0;

  margin: 0 auto;

  font-size: 30px;
  text-transform: uppercase;
  white-space: nowrap;

  filter: blur(4.5px);
`;

const ContainersButton = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 250px;
  width: 100%;
`;

const ButtonSC = styled.button`
  width: 100%;
  background: black;
  z-index: ${ZIndex.ELEMENT};
`;

export const usePricePageDesktopStyles = () => {
  return {
    WrapperSC,
    SwiperSlideSC,
    SlideSC,
    ContainersButton,
    ButtonSC,
  };
};
