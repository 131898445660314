import styled from "styled-components";
import { Size } from "theme/styles/Size";

const ContainerSC = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

const PageContainerSC = styled("div")`
  width: 100%;
  max-width: 359px;

  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const TextSC = styled("p")`
  position: absolute;
  top: -88px;

  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;

  & > *:first-child {
    display: block;
    margin-bottom: 16px;
  }
  & > * {
    font-style: normal;
  }
`;

const ContentContainerCS = styled("div")`
  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;
`;

export const useContactsPageStyles = () => ({
  ContainerSC,
  PageContainerSC,
  TextSC,
  ContentContainerCS,
});
