import { HEADER_ROUND_LENGTH } from "lib/mock/headerData";
import { Size } from "theme/styles/Size";
import {
  FromToStyle,
  UseHeaderItemProps,
} from "components/Header/HeaderItem/types";
import { useSpring } from "@react-spring/web";
import { ZIndex } from "theme/styles/zIndex";

const HEADER_ITEM_HEIGHT = Size.HEADER_ITEM;

export const useHeaderItemDesktop = (props: UseHeaderItemProps) => {
  const { id, activeId, isVisible, isActive } = props;

  const offset = id - activeId;
  const isDisplay = id >= activeId && id < activeId + HEADER_ROUND_LENGTH;
  const isPrevActive = id < activeId;

  const isPointerEvent = isDisplay && (isVisible || isActive);

  //? styles
  const getTranslate = (): FromToStyle => {
    if (isActive || isPrevActive) {
      return { from: 0, to: HEADER_ITEM_HEIGHT * offset };
    }
    return {
      from: 0,
      to: HEADER_ITEM_HEIGHT * offset + 52,
    };
  };

  const getOpacity = (): FromToStyle => {
    if (isDisplay && isVisible) {
      return { from: 1, to: 1 };
    }
    if (isActive) {
      return { from: 0, to: 1 };
    }
    return { from: 0, to: 0 };
  };

  const getFontSize = (): FromToStyle => {
    if (isActive) {
      return { from: 20, to: 64 };
    }
    return { from: 20, to: 20 };
  };

  const getZIndex = (): FromToStyle => {
    if (!isVisible && !isActive) {
      return { from: ZIndex.HIDDEN, to: ZIndex.HIDDEN };
    }
    return { from: ZIndex.HEADER, to: ZIndex.HEADER };
  };

  //*** STYLING
  const translateY = getTranslate();
  const opacity = getOpacity();
  const fontSize = getFontSize();
  const zIndex = getZIndex();

  const springs = useSpring({
    config: { duration: 50 },
    from: {
      top: translateY.from,
      opacity: opacity.from,
      fontSize: fontSize.from,
      zIndex: zIndex.from,
    },
    to: {
      top: translateY.to,
      opacity: opacity.to,
      fontSize: fontSize.to,
      zIndex: zIndex.to,
    },
  });

  return {
    isPointerEvent,
    springs,
  };
};
