import React from "react";
import styled from "styled-components";
import { HTMLAProps } from "types/types";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { ColorScheme } from "theme/styles/colorScheme";
import { Breakpoints } from "theme/styles/breakpoints";

const SocialItem = ({ children, ...otherProps }: HTMLAProps) => {
  const { changeMouseProps } = changeCursorActive();

  return (
    <ItemSC {...changeMouseProps}>
      <a
        target="_blank"
        rel="noreferrer"
        {...otherProps}
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {children}
      </a>
    </ItemSC>
  );
};

const ItemSC = styled("li")`
  background-color: ${ColorScheme.BLACK};

  width: 50px;
  height: 50px;
  border-radius: 50%;

  /* margin-right: 15px; */

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.2s all linear;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    transform: rotate(180deg);
  }

  & > a {
    width: 30px;
    height: 30px;
  }
  &:hover {
    background-color: ${ColorScheme.GRAY_TWO};
  }
`;

export default React.memo(SocialItem);
