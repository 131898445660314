export enum ColorScheme {
  TRANSPARENT = "transparent",
  GRAY = "#808080",
  GRAY_TWO = "#232323",
  GRAY_THREE = "rgba(255, 255, 255, 0.5)",
  WHITE = "#FFFFFF",
  BLACK = "#000000",
  BLACK_TWO = "#101017",
  RED_ERROR = "#e76259",
}
