import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";

const DescriptionSC = styled.div<{ isAdded?: boolean }>`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  width: 140px;

  z-index: ${ZIndex.ELEMENT};

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: all 0.2s 0.5s linear;

  position: absolute;
  bottom: 40px;
  opacity: ${({ isAdded }) => (isAdded ? 1 : 0)};
`;

const PlusSC = styled.p`
  text-align: center;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  padding: 5px 0 9px;
  text-transform: uppercase;
`;

const PriceItemSC = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
`;

const PriceTimeSC = styled.p``;

export const useAnimatedDescStyles = () => ({
  DescriptionSC,
  PlusSC,
  PriceItemSC,
  PriceTimeSC,
});
