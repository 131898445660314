import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IPopupStoreState } from "store/popupStore/types";

export const popupStore = create<IPopupStoreState>()(
  devtools((set) => ({
    isView: false,
    changeToggleViewPopup: () => {
      set((state) => ({
        isView: !state.isView,
      }));
    },
    handleViewPopup: () => {
      set(() => ({
        isView: true,
      }));
    },
  })),
);
