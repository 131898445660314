import React from "react";
import { useCPMCheckoboxStyles } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMCheckbox/CPMCheckbox.styles";
import { ValueItem } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";

interface CpmCheckboxI {
  item: ValueItem;
  isChecked: boolean;
  onCheckboxChange: (item: ValueItem) => void;
}

const CpmCheckbox = ({ item, onCheckboxChange, isChecked }: CpmCheckboxI) => {
  return (
    <li>
      <LabelSC>
        <CheckboxSC
          checked={isChecked}
          onChange={() => onCheckboxChange(item)}
        />
        <TextSC>{item.text}</TextSC>
      </LabelSC>
    </li>
  );
};

const { TextSC, CheckboxSC, LabelSC } = useCPMCheckoboxStyles();

export default React.memo(CpmCheckbox);
