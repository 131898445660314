import React from "react";
import { styles } from "routes/pages/GiftPage/GiftPageMobile/GPMPopupCondition/GPMPopupCondition.styles";

interface GPMPopupConditionProps {
  cost: number;
}

const GPMPopupCondition = ({ cost }: GPMPopupConditionProps) => (
  <WrapperSC>
    <SubTitle>* Условия использования сертификата:</SubTitle>
    <SubTitle>
      Сертификат предоставляет скидку в размере 10% от итоговой стоимости, но не
      более {cost} ₽;
    </SubTitle>
    <SubTitle>
      Каждый человек может получить только один сертификат, который может быть
      использован лишь однократно.
    </SubTitle>
    <SubTitle>Сертификаты не подлежат обмену на денежный эквивалент;</SubTitle>
    <SubTitle>
      Сертификат не может быть суммирован с другими сертификатами;
    </SubTitle>
    <SubTitle>
      Сертификат действителен в течение 1 Месяца. По истечении указанного срока
      неиспользованный сертификат аннулируется
    </SubTitle>
  </WrapperSC>
);

const { WrapperSC, SubTitle } = styles;

export default React.memo(GPMPopupCondition);
