import React from "react";
import Lottie from "lottie-react";
import ContactsNav from "../ContactsNav/ContactsNav";
import RadarAnimation from "assets/animation/radar.json";
import { useContactsPageStyles } from "../ContactsPage.styles";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const GeolocationPage = () => {
  return (
    <ContainerSC>
      <PageContainerSC>
        <TextSC>
          <address>Молодогвардейцев 60в, офис 505</address>
          <address>Челябинск, Россия, 454136</address>
        </TextSC>
        <ContentContainerCS>
          <Lottie animationData={RadarAnimation} />
        </ContentContainerCS>
        <ContactsNav />
      </PageContainerSC>
      <DiscusProject />
    </ContainerSC>
  );
};

const { ContainerSC, PageContainerSC, TextSC, ContentContainerCS } =
  useContactsPageStyles();

export default React.memo(GeolocationPage);
