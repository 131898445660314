import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PricePageDesktop from "routes/pages/PricePage/PricePageDesktop/PricePageDesktop";
import { priceData } from "lib/mock/priceData";
import { usePricePageStyles } from "routes/pages/PricePage/PricePage.styles";
import { useBigTablet } from "hooks/useBreakpoints";
import { PriceNamespace } from "lib/constants/enums/PagesNamespace";

const PricePage = () => {
  const isMobile = useBigTablet();
  const navigate = useNavigate();

  // если мобилка, то пропускаем крутилку, и сразу попадаем на страницу калькуляции
  useEffect(() => {
    if (isMobile) {
      navigate(PriceNamespace.PRICE_MOBILE_APP);
    }
  }, []);

  return (
    <ContainerSC>
      {!isMobile && <PricePageDesktop data={priceData.data} />}
    </ContainerSC>
  );
};

const { ContainerSC } = usePricePageStyles();

export default React.memo(PricePage);
