import { PartnersModel, PartnersNames } from "lib/models/PartnersModel";
import { ReactComponent as CHELGUIcon } from "assets/icon/CHELGUIcon.svg";
import { ReactComponent as MidisIcon } from "assets/icon/MidisIcon.svg";
import { ReactComponent as YURGUIcon } from "assets/icon/YURGUIcon.svg";
import { ReactComponent as TopIcon } from "assets/icon/TopIcon.svg";

export const partners: { [key in PartnersNames]: PartnersModel } = {
  TOP: {
    id: 1,
    title: "TOP",
    Icon: TopIcon,
  },
  YURGU: {
    id: 2,
    title: "ЮУрГУ",
    Icon: YURGUIcon,
  },
  MIDIS: {
    id: 3,
    title: "МИДИС",
    Icon: MidisIcon,
  },
  CHELGU: {
    id: 4,
    title: "ЧелГУ",
    Icon: CHELGUIcon,
  },
};

export const partnersArray: PartnersModel[] = [
  partners.TOP,
  partners.YURGU,
  partners.MIDIS,
  partners.CHELGU,
];
