import { useEffect, useState } from "react";

export const useMainLayout = () => {
  const [isTouch, setIsTouch] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  // ? Если экран поддерживает сенсорные нажатия, то отключаем мышку
  useEffect(() => {
    const checkTouchDevice = !("ontouchstart" in window);
    const checkTouchDeviceTwo = !navigator.maxTouchPoints;
    const isTouchDevice = checkTouchDevice || checkTouchDeviceTwo;

    if (isTouchDevice) {
      setIsTouch(true);
    }

    function handleOrientationChange() {
      if (window.orientation === 90 || window.orientation === -90) {
        if (window.innerWidth < 768) {
          setIsLandscape(true);
        }
      } else {
        setIsLandscape(false);
      }
    }
    handleOrientationChange();

    window.addEventListener("orientationchange", handleOrientationChange);
  }, []);

  return { isTouch, isLandscape };
};
