import React from "react";
import { indexDataMobile } from "lib/mock/indexData";
import { useDealTextMobileStyles } from "./DealTextMobile.styles";
import { FilesNamespace } from "lib/constants/enums/FilesNamespace";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";
import { popupStore } from "store/popupStore/popupStore";

const DealTextMobile = ({ isClicked }: { isClicked: boolean }) => {
  const handleTogglePopup = popupStore((state) => state.changeToggleViewPopup);

  return (
    <ContainerSC isClicked={isClicked}>
      <DealTextSC>
        <DealTextTitle>{indexDataMobile.hello}</DealTextTitle>
        <DealTextSubTitle>{indexDataMobile.index_studio}</DealTextSubTitle>
        <DealTextParagraphSC>
          Мы создаем сайты, интернет-магазины и мобильные приложения для вашего
          бизнеса. Независимо от сферы и местоположения вашего бизнеса, наши
          специалисты помогут вам выбрать и реализовать{" "}
          <ButtonSC onClick={handleTogglePopup}>лучшие IT-решения</ButtonSC>,
          чтобы улучшить показатели и достичь ваших целей. Мы{" "}
          <LinkSC href={FilesNamespace.POLICY} target={"_blank"}>
            гарантируем
          </LinkSC>{" "}
          качественную работу в оговоренные сроки. Доверьте свои идеи нам, и мы
          поможем вам воплотить их в жизнь! Наша команда создаст не только
          функциональные, но и привлекательные{" "}
          <LinkSC href={PagesNamespace.CASES}>цифровые продукты</LinkSC>,
          которые будут успешно представлять ваш бизнес на мировом рынке.
        </DealTextParagraphSC>
      </DealTextSC>
    </ContainerSC>
  );
};

const {
  DealTextSC,
  DealTextSubTitle,
  DealTextTitle,
  DealTextParagraphSC,
  ContainerSC,
  LinkSC,
  ButtonSC,
} = useDealTextMobileStyles();

export default React.memo(DealTextMobile);
