import { useCPUserStyles } from "routes/pages/CertificatePage/CPUser/CPUDesktop/CPUser.styles";
import React, { useState } from "react";
import { CPUserProps } from "routes/pages/CertificatePage/CPUser/CPUser";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import CPConditionText from "routes/pages/CertificatePage/CPConditionText/CPConditionText";

export interface CPUDesktopProps extends CPUserProps {
  cost: string;
}

const CPUDesktop = ({ userData, cost }: CPUDesktopProps) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const { changeMouseProps } = changeCursorActive();

  const handleTogglePopup = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  return (
    <>
      <HelloSC>Привет,</HelloSC>
      <NameSC>{userData.name}</NameSC>
      <DescriptionSC>
        Ура, ты {userData.sex === "Ж" ? "победила" : "победил"} в турнире{" "}
        <TournamentSC>{userData.tournament}</TournamentSC>!
      </DescriptionSC>
      <TextSC>
        Мы дарим тебе{" "}
        <ButtonSC {...changeMouseProps} onClick={handleTogglePopup}>
          сертификат*
        </ButtonSC>{" "}
        на
      </TextSC>
      <CostSC>{cost} ₽</CostSC>
      <SubtitleSC>Используй его скорей!</SubtitleSC>

      {isOpenPopup && <CPConditionText handleTogglePopup={handleTogglePopup} />}
    </>
  );
};

const {
  HelloSC,
  TournamentSC,
  TextSC,
  NameSC,
  CostSC,
  SubtitleSC,
  ButtonSC,
  DescriptionSC,
} = useCPUserStyles();

export default React.memo(CPUDesktop);
