import React, { FC } from "react";

import { useCasesPageMobileItemsStyles } from "./CasesPageMobileItemsStyles";

type CasesPageMobileItemsProps = {
  item: {
    title: string;
    Icon: FC;
  };
  index: number;
  activeIndex: number | null;
  handleChangeActiveIndexMobile: (index: number) => void;

  setisOpenExtand: (isOpen: boolean) => void;
};

const CasesPageMobileItems = ({
  item,
  index,
  activeIndex,
  handleChangeActiveIndexMobile,
  setisOpenExtand,
}: CasesPageMobileItemsProps) => {
  const OnClickButton = () => {
    handleChangeActiveIndexMobile(index);
    setisOpenExtand(false);
  };

  return (
    <SmallImageSC $isActive={index === activeIndex}>
      <ButtonSC
        $isActive={index === activeIndex}
        {...item}
        onClick={OnClickButton}>
        <item.Icon />
      </ButtonSC>
    </SmallImageSC>
  );
};

const { ButtonSC, SmallImageSC } = useCasesPageMobileItemsStyles();

export default React.memo(CasesPageMobileItems);
