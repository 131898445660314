import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";
import { animated } from "@react-spring/web";

const WrapperSC = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListSC = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  gap: 8px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    transform: scale(0.9);
  }
`;

const ListItemSC = styled.li`
  height: 44px;
  border: 1px solid #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ParagraphVP = styled(animated.p)`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  display: flex;
  flex-direction: column;
  justify-content: end;
  flex-grow: 1;
`;

const ContainerItems = styled.div`
  flex-basis: 220px;

  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 912px;
  padding-bottom: 30px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    max-width: none;

    flex-basis: 150px;
  }
`;

export const useCalculatedPageStyles = () => ({
  WrapperSC,
  ListSC,
  ListItemSC,
  ParagraphVP,
  ContainerItems,
});
