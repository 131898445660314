import React from "react";
import AnimatedTextUI from "UI/AnimatedTextUI/AnimatedText";
import { IndexData } from "lib/mock/indexData";
import { useDealTextStyles } from "./DealTextDesktop.styles";

const DealTextDesktop = ({ isHover }: { isHover: boolean }) => {
  return (
    <DealTextSC isHover={!isHover}>
      <AnimatedTextUI variant={"hello"} text={IndexData.hello} />
      <AnimatedTextUI variant={"partner"} text={IndexData.index_studio} />
      <AnimatedTextUI variant={"text"} text={IndexData.paragraph} />
    </DealTextSC>
  );
};

const { DealTextSC } = useDealTextStyles();

export default React.memo(DealTextDesktop);
