import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";
import { animated } from "@react-spring/web";

const ContainerSC = styled("section")`
  height: 100%;
  max-width: 1344px;
  padding: 0 28px;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    padding: 0 15px;
  }
`;

const ListSC = styled(animated.ul)`
  display: flex;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    gap: 71px;
    flex-wrap: wrap;

    justify-content: center;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    flex-direction: column;
    gap: 71px;
    margin: 0 auto;
  }
`;

export const usePartnersStyle = () => ({
  ContainerSC,
  ListSC,
});
