import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { CircularProgressbarWrapperProps } from "react-circular-progressbar/dist/types";
import { BuildStylesArgType } from "types/lib/react-circular-progress/BuildStylesArgType";
import { Animations } from "theme/styles/Animations";
import { ColorScheme } from "theme/styles/colorScheme";

type CircularProgressbarUIProps = Omit<
  CircularProgressbarWrapperProps,
  "styles"
> & {
  styles?: BuildStylesArgType;
};

const CircularProgressbarUI = (props: CircularProgressbarUIProps) => {
  const { strokeWidth = 1 } = props;

  return (
    <CircularProgressbar
      {...props}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        pathColor: ColorScheme.WHITE,
        trailColor: ColorScheme.GRAY,
        strokeLinecap: "butt",
        pathTransition: Animations.CIRCULAR_ANIMATION,
        ...props.styles,
      })}
    />
  );
};

export default React.memo(CircularProgressbarUI);
