import { useEffect, useState } from "react";
import Swiper from "swiper";
import { casesData } from "lib/mock/casesData";
import { HandleChangeAutoplayType } from "UI/FlipUI/types";

export const useCasesPageDesktop = () => {
  const [swiper, setSwiper] = useState<Swiper>();
  const [hoverItem, setHoverItem] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const activeData =
    activeIndex === null ? null : casesData[activeIndex as number];

  const handleChangeActiveIndex = (index: number) => {
    return () => {
      if (index === activeIndex) {
        swiper?.autoplay.start();
        setActiveIndex(null);
        return;
      }
      swiper?.autoplay.stop();
      setActiveIndex(index);
    };
  };

  const handleChangeHover = (desc: string) => {
    setHoverItem(desc);
  };

  const handleChangeAutoplay: HandleChangeAutoplayType = (type) => {
    return () => {
      if (type === "start" && !activeData) {
        swiper?.autoplay.start();
      }
      if (type === "stop") {
        swiper?.autoplay.stop();
      }
    };
  };

  const handleChangeSwiper = (swiperInit: Swiper) => {
    setSwiper(swiperInit);
  };

  const handleNextSlide = () => {
    setActiveIndex(null);
    swiper?.slideNext();
  };

  const handlePrevSlide = () => {
    setActiveIndex(null);
    swiper?.slidePrev();
  };

  const onKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowRight":
        handleNextSlide();
        break;
      case "ArrowLeft":
        handlePrevSlide();
        break;
    }
  };

  useEffect(() => {
    if (!swiper) return;

    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [swiper]);

  return {
    hoverItem,
    activeData,
    activeIndex,

    handleNextSlide,
    handlePrevSlide,
    handleChangeHover,
    handleChangeSwiper,
    handleChangeAutoplay,
    handleChangeActiveIndex,
  };
};
