import React from "react";
import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { createPortal } from "react-dom";
import { ReactComponent as OpenInfoIcon } from "assets/icon/InfoIcon.svg";
import { ReactComponent as ClosedInfoIcon } from "assets/icon/InfoFilledIcon.svg";

interface PopupToggleButtonProps {
  handleClickInfo: () => void;
  isClicked: boolean;
}

const GPMToggleButton = ({
  isClicked,
  handleClickInfo,
}: PopupToggleButtonProps) => {
  return createPortal(
    <ButtonSC onClick={handleClickInfo}>
      {isClicked ? <ClosedInfoIcon /> : <OpenInfoIcon />}
    </ButtonSC>,
    document.querySelector("#root") as HTMLDivElement,
  );
};

const ButtonSC = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: ${ZIndex.UPPER};
`;

export default React.memo(GPMToggleButton);
