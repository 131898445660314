import styled from "styled-components";

const DealTextSC = styled.div<{ isHover: boolean }>`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 852px;
  height: 100%;
  margin: 0 auto;
  opacity: ${({ isHover }) => (isHover ? 0 : 1)};

  transition: all 0.5s linear;
  transition-delay: 0.3s;
`;

export const useDealTextStyles = () => ({
  DealTextSC,
});
