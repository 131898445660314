import { useState, useEffect } from "react";
import {
  stylesPatterns,
  StylesPatterns,
} from "lib/mock/patterns/AboutPageMobilePatterns";
import { StylePattern } from "../types";
import { Timing } from "theme/styles/timing";
import { useHeightMobile } from "hooks/useBreakpoints";
import { aboutData } from "lib/mock/aboutData";

interface AboutPageMobileHook {
  isClicked: boolean;
  currentPattern: StylePattern[];
  handleClickInfo: () => void;
  data: string[];
}

export const useAboutPageMobile = (): AboutPageMobileHook => {
  const [isClicked, setIsClicked] = useState(false);
  const [currentPattern, setCurrentPattern] = useState(
    stylesPatterns.firstPattern,
  );

  const isSmallMobile = useHeightMobile();
  const data = aboutData.mobile.slice();

  if (isSmallMobile) {
    data.splice(-3);
  }

  const handleClickInfo = () => {
    setIsClicked(!isClicked);
  };

  const stylesRandom = (data: StylesPatterns, index = 0): void => {
    const patterns = Object.keys(data);

    setCurrentPattern(data[patterns[index]]);

    setTimeout(() => {
      if (index === patterns.length - 1) {
        stylesRandom(data, 0);
      } else {
        stylesRandom(data, index + 1);
      }
    }, Timing.ABOUT_DELAY);
  };

  useEffect(() => {
    stylesRandom(stylesPatterns);
  }, []);

  return { isClicked, currentPattern, data, handleClickInfo };
};
