import React from "react";
import { useCPMTotalStyles } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMTotal/CPMTotal.styles";
import { ValueItem } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";
import { UseCpmTotal } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMTotal/useCPMTotal";
import TextTransitionUI, { present } from "UI/TextTransitionUI";
import { popupStore } from "store/popupStore/popupStore";

export interface CPMTotal {
  price: string | undefined;
  otherValues: ValueItem[];
}

const CPMTotal = ({ price, otherValues }: CPMTotal) => {
  const { currentIndex } = UseCpmTotal({
    price,
    otherValues,
  });

  const handleShowForm = popupStore((state) => state.changeToggleViewPopup);

  return (
    <>
      <WrapperSC>
        <ButtonSC onClick={handleShowForm}>заказать проект</ButtonSC>
        <TotalPriceSC>{price} ₽</TotalPriceSC>
        {otherValues && otherValues.length > 0 && (
          <>
            <PlusSC>+</PlusSC>
            <TextTransitionUI springConfig={present.wobbly} direction={"up"}>
              <OtherValuesContainerSC>
                <SubtitleSC>
                  {otherValues[currentIndex % otherValues.length].text}&nbsp;
                  {otherValues[currentIndex % otherValues.length].price} ₽
                  {otherValues[currentIndex % otherValues.length].time}
                </SubtitleSC>
              </OtherValuesContainerSC>
            </TextTransitionUI>
          </>
        )}
      </WrapperSC>
      {/*<DiscusProject*/}
      {/*  mobileConfig={{ pos: "relative", top: "auto", right: "auto" }}*/}
      {/*/>*/}
    </>
  );
};

const {
  OtherValuesContainerSC,
  ButtonSC,
  TotalPriceSC,
  PlusSC,
  SubtitleSC,
  WrapperSC,
} = useCPMTotalStyles();

export default React.memo(CPMTotal);
