import React, { MouseEvent } from "react";
import { useFlipImageUIStyles } from "UI/FlipUI/FlipUI.styles";
import FlipFront from "UI/FlipUI/FlipFront/FlipFront";
import FlipBack from "UI/FlipUI/FlipBack/FlipBack";
import { IFlipUIProps } from "UI/FlipUI/types";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

const FlipUI = ({
  isActive,
  onClick,
  handleChangeAutoplay,
  handleChangeHover,
  ...otherProps
}: IFlipUIProps) => {
  const { title, subtitle, desc, imageUrl, Icon } = otherProps;
  const { onChangeActive } = changeCursorActive();

  const handleMouseEnter = (mouse: "enter" | "leave") => {
    return (e: MouseEvent<HTMLButtonElement>) => {
      const isEnter = mouse === "enter";

      onChangeActive(isEnter)(e);
      handleChangeHover(isEnter ? desc || "" : "");
      handleChangeAutoplay(isEnter ? "stop" : "start")();
    };
  };

  return (
    <ContainerSC
      onClick={onClick}
      onMouseEnter={handleMouseEnter("enter")}
      onMouseLeave={handleMouseEnter("leave")}>
      <WrapperSC isActive={isActive}>
        <FlipFront Icon={Icon} subtitle={subtitle} />
        <FlipBack title={title} imageUrl={imageUrl} />
      </WrapperSC>
    </ContainerSC>
  );
};

const { ContainerSC, WrapperSC } = useFlipImageUIStyles();

export default React.memo(FlipUI);
