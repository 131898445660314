import React from "react";
import { UserData } from "routes/pages/CertificatePage/types";
import { useTablet } from "hooks/useBreakpoints";
import CPUDesktop from "routes/pages/CertificatePage/CPUser/CPUDesktop/CPUDesktop";
import CPUMobile from "routes/pages/CertificatePage/CPUser/CPUMobile/CPUserMobile";

export interface CPUserProps {
  userData: UserData;
  selectedIcon?: string;
}

const CPUser = ({ userData, selectedIcon }: CPUserProps) => {
  const isMobile = useTablet();
  const REG_EXP_ALL_EXCEPT_NUMBER = /[^0-9]/g;

  const getFormatNumberToPrice = (price: string) => {
    const numberPrice = price.replace(REG_EXP_ALL_EXCEPT_NUMBER, "");

    if (numberPrice && !isNaN(+numberPrice)) {
      return new Intl.NumberFormat("ru-RU").format(+numberPrice);
    }
    return numberPrice;
  };

  const cost = getFormatNumberToPrice(userData.cost.toString());

  return (
    <>
      {!isMobile && <CPUDesktop userData={userData} cost={cost} />}
      {isMobile && (
        <CPUMobile
          userData={userData}
          selectedIcon={selectedIcon}
          cost={cost}
        />
      )}
    </>
  );
};

export default React.memo(CPUser);
