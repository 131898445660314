import { FC } from "react";

import { ReactComponent as CallIcon } from "assets/icon/CallIcon.svg";
import { ReactComponent as EmailIcon } from "assets/icon/EmailIcon.svg";
import { ReactComponent as GeoIcon } from "assets/icon/GeoIcon.svg";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";

export type ContactsDataProps = {
  link: string;
  Icon: FC;
  isFill?: boolean;
};

export const contactsData: ContactsDataProps[] = [
  // что бы вернуть как было раскоментируй код ниже, а это удали
  {
    link: PagesNamespace.CONTACTS,
    Icon: CallIcon,
  },
  {
    link: PagesNamespace.CONTACTS_EMAIL,
    Icon: EmailIcon,
  },
  {
    link: PagesNamespace.CONTACTS_ADDRESS,
    Icon: GeoIcon,
  },
];
