import { useTablet } from "hooks/useBreakpoints";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchGetGift } from "lib/api/fetchGift/fetchGift";

export const useGiftPage = () => {
  const isMobile = useTablet();

  const [costData, setCostData] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [isError, setIsError] = useState(false);
  const { id } = useParams();

  const handleFetch = async (id: string | undefined) => {
    try {
      const response = await fetchGetGift(id);
      const data = await response.json();
      setCostData(data.cost);
      setIsLoading(false);
    } catch (err) {
      // setIsError(true);
    }
  };

  useEffect(() => {
    handleFetch(id);
  }, []);

  return { isMobile, costData, isLoading };
};
