import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { useEffect } from "react";

export const useResetCursorUnMount = () => {
  const { onChangeActive, changeMouseProps } = changeCursorActive();

  useEffect(() => {
    return () => {
      onChangeActive(false)();
    };
  }, []);

  return {
    onChangeActive,
    changeMouseProps,
  };
};
