import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { popupStore } from "store/popupStore/popupStore";
import React, { useEffect, useState } from "react";
import { fetchPostDiscount } from "lib/api/fetchDiscount/fetchDicsount";
import {
  InputValuesType,
  RespDataType,
} from "components/DiscussProjectForm/types";
import { useLocation } from "react-router-dom";
import { QueryNameSpace } from "lib/constants/enums/QueryNameSpace";
import { useFormAndValidation } from "hooks/useFormValidations";

export const useDiscussProjectForm = () => {
  const location = useLocation();
  const { errors, values, resetForm, isValid, checkValidity, handleChange } =
    useFormAndValidation();
  const hasFeedback = location.hash === QueryNameSpace.FEEDBACK;

  const { changeMouseProps } = changeCursorActive();

  const handleToggleViewPopup = popupStore(
    (state) => state.changeToggleViewPopup,
  );
  const openPopup = popupStore((state) => state.handleViewPopup);
  const isView = popupStore((state) => state.isView);

  const [respData, setRespData] = useState<RespDataType>({
    qr: null,
    url: null,
    cost: null,
  });

  const handleSubmitReq = (value: InputValuesType) => {
    fetchPostDiscount(value)
      .then((res) => {
        resetForm();

        return res.json();
      })
      .then((res) => setRespData({ qr: res.qr, url: res.url, cost: res.cost }));
  };

  const handleWrapperClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const data: InputValuesType = {
      name: values.name,
      phone: values.phone,
      message: values.message || "",
    };

    checkValidity(e as React.ChangeEvent<HTMLInputElement>);

    if (isValid) {
      handleSubmitReq(data);
    }
  };

  const removeHash = () => {
    if (window.location.hash === "#feedback") {
      history.replaceState(
        null,
        document.title,
        window.location.pathname + window.location.search,
      );
    }
  };

  const onClickExit = () => {
    handleToggleViewPopup();

    removeHash();
    resetForm({});
  };

  useEffect(() => {
    if (hasFeedback) {
      openPopup();
    }
  }, []);

  return {
    changeMouseProps,
    isView,
    respData,
    values,
    errors,

    onClickExit,

    handleChange,
    handleWrapperClick,
    handleSubmit,
  };
};
