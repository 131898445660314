import { useEffect, useState } from "react";
import {
  getHeaderData,
  getHeaderInfinity,
  HEADER_ROUND_LENGTH,
  HeaderDataProps,
} from "lib/mock/headerData";
import {
  HeaderHandleChangeStatusState,
  HeaderHandleClickItem,
  HeaderHandleScrollApp,
  HeaderStatusStateType,
} from "components/Header/type";
import { Timing } from "theme/styles/timing";
import { changeCursorLoading } from "store/cursorStore/useCursorStoreActions";
import { useLocation, useNavigate } from "react-router-dom";
import { getHeaderInitialRouteIndex } from "lib/services/services";
import { useBigTablet } from "../../hooks/useBreakpoints";

//! ОСНОВНАЯ ЛОГИКА ЗАКЛЮЧАЕТСЯ В ФОРМИРОВАНИИ 3-х spread массивов [до, текущий, после]
//! Элементы массива (0-6) индексы "До" нужен для корректной анимации (вверх) и изменения состояния
//! Элементы массива (6-12) индексы "Текущий" нужен для отображения пользователю
//! Элементы массива (12-18) индексы "После" нужен для корректной анимации (вниз) и изменения состояния

const initialActiveId = HEADER_ROUND_LENGTH + 1 + getHeaderInitialRouteIndex();

export const useHeaderDefault = () => {
  //? change store
  const { handleChangeCursorLoading } = changeCursorLoading();

  //? push
  const navigate = useNavigate();
  //? pathname
  const location = useLocation();

  const isMobile = useBigTablet();

  //? header data
  //! Менять запрещено :) (если только логика не поменялась)
  const [activeId, setActiveId] = useState<number>(initialActiveId);
  const [headerData, setHeaderData] = useState<HeaderDataProps[]>(
    getHeaderInfinity(),
  );

  //? статусы
  const [status, setStatus] = useState<HeaderStatusStateType>({
    //? Видно ли элементы header.
    isVisible: false,
    //? Прокручивает ли пользователь страницу
    isScroll: true,
    //? Наведен пользователь на ul
    isHover: false,
    //? trigger touch event
    isTouch: false,
  });
  const { isVisible, isTouch } = status;

  //? change status state
  const handleChangeStatusState: HeaderHandleChangeStatusState = (state) => {
    setStatus((prevState) => ({ ...prevState, ...state }));
  };

  //? click item
  const handleClick: HeaderHandleClickItem = (id) => {
    return () => {
      //? check click active item
      if (id === activeId) {
        return;
      }

      setActiveId(() => {
        setHeaderData((headerData) => {
          //? ищем index элемента по id (на который был клик)
          const activeIndex = headerData.findIndex((item) => item.id === id);

          const isLastArr =
            activeIndex >= headerData.length - HEADER_ROUND_LENGTH;

          if (isLastArr) {
            const newData = headerData.slice(HEADER_ROUND_LENGTH);
            //? текущий + после
            //! обратить внимание на id у элементов
            return [
              ...newData,
              ...getHeaderData(newData[newData.length - 1].id),
            ];
          }

          return headerData;
        });

        //? изменяем id

        handleChangeStatusState({
          isHover: false,
          isVisible: false,
          isTouch: false,
          isScroll: false,
        });

        return id;
      });
    };
  };

  //? scroll top
  const handleScrollTop = () => {
    setActiveId((activeId) => {
      setHeaderData((headerData) => {
        //? ищем index элемента по id (который сейчас isActive)
        const activeIndex = headerData.findIndex(
          (item) => item.id === activeId,
        );

        //? Проверяем на последний элемент в массиве "Текущий"
        const isLastArr = activeIndex <= HEADER_ROUND_LENGTH;

        if (isLastArr) {
          const newData = headerData.slice(0, HEADER_ROUND_LENGTH * 2);

          //? предыдущий + текущий
          //! обратить внимание на id у элементов
          return [
            ...getHeaderData(newData[0].id - HEADER_ROUND_LENGTH - 1, 1),
            ...newData,
          ];
        }

        return headerData;
      });

      //? изменяем id
      return activeId - 1;
    });
  };

  //? scroll bottom
  const handleScrollBottom = () => {
    setActiveId((activeId) => {
      setHeaderData((headerData) => {
        //? ищем index элемента по id (который сейчас isActive)
        const activeIndex = headerData.findIndex(
          (item) => item.id === activeId,
        );

        //? Проверяем на последний элемент в массиве "Текущий"
        const isLastArr =
          activeIndex >= headerData.length - 1 - HEADER_ROUND_LENGTH;

        if (isLastArr) {
          const newData = headerData.slice(HEADER_ROUND_LENGTH);
          //? текущий + после
          //! обратить внимание на id у элементов
          return [...newData, ...getHeaderData(newData[newData.length - 1].id)];
        }

        return headerData;
      });

      //? изменяем id
      return activeId + 1;
    });
  };

  const handleScrollApp: HeaderHandleScrollApp = () => {
    // eslint-disable-next-line
    let timeout: any = null;
    let isBlockAnimation = false;

    return (direction, isMobile, isTouch = true) => {
      /*** END ***/
      //? очистка, чтобы сработал при завершении прокрутки
      clearTimeout(timeout);

      //? срабатывает когда мы перестаем прокручивать и callback queue выполняет все отложенные задачи, очищаем web api от таймаутов
      timeout = setTimeout(
        () => {
          isBlockAnimation = false;
          setStatus((prevState) => ({
            ...prevState,
            isHover: prevState.isHover,
            isVisible: isMobile ? isTouch : prevState.isHover,
            isTouch: prevState.isTouch,
          }));
        },
        isMobile ? Timing.HEADER_CLOSE_MOBILE : Timing.HEADER_CLOSE,
      );
      /*** END ***/

      /*** START AND INTERVAL ***/
      //? стартует и интервально срабатывает, чтобы переключать секции в header
      if (!isBlockAnimation) {
        handleChangeStatusState({
          isVisible: true,
          isScroll: true,
        });

        if (direction === "bottom") {
          //? SCROLL BOTTOM
          handleScrollBottom();
        }

        if (direction === "top") {
          handleScrollTop();
        }

        const blockTiming = isMobile
          ? Timing.HEADER_SCROLL_MOBILE
          : Timing.HEADER_SCROLL;

        setTimeout(() => {
          isBlockAnimation = false;
        }, blockTiming);
      }

      isBlockAnimation = true;
      /*** START AND INTERVAL ***/
    };
  };

  //? change route
  useEffect(() => {
    //? проверка, что мы прокрутили header и он сам через время скрылся
    if (isVisible) {
      return;
    }

    const indexActiveItem = headerData.findIndex(
      (item) => item.id === activeId,
    );
    const activeItem = headerData[indexActiveItem];
    const pageUrl = activeItem.href;

    //? предотвращение пуша на текущую страницу
    if (pageUrl === location.pathname) {
      handleChangeStatusState({
        isScroll: false,
        isVisible: false,
        isTouch: false,
        isHover: false,
      });
      return;
    }

    handleChangeCursorLoading(true);

    const timeout = setTimeout(
      () => {
        if (!isMobile) {
          handleChangeCursorLoading(false);
          navigate(pageUrl);
          handleChangeStatusState({
            isVisible: false,
            isScroll: false,
            isHover: false,
            isTouch: false,
          });
        }

        if (isMobile && !isTouch) {
          navigate(pageUrl);
          handleChangeStatusState({
            isVisible: false,
            isScroll: false,
            isHover: false,
            isTouch: false,
          });
        }
      },
      isMobile ? Timing.HEADER_NAVIGATE_MOBILE : Timing.HEADER_NAVIGATE,
    );

    return () => {
      clearTimeout(timeout);
      handleChangeCursorLoading(false);
    };
  }, [activeId, isVisible, isTouch]);

  return {
    status,
    activeId,
    headerData,

    handleClick,
    handleScrollApp,
    handleChangeStatusState,
  };
};
