import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const PartnersElemSC = styled("li")`
  width: 336px;
  height: 202px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    width: 268px;
    height: 173px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    transform: scale(0.8);
  }
`;

const PartnersTextSC = styled("p")`
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
`;

export const usePartnersItemStyle = () => ({
  PartnersElemSC,
  PartnersTextSC,
});
