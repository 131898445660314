import { CircularContentBottomProps } from "UI/CircularContentUI/types";
import { useSpring } from "@react-spring/web";
import { Timing } from "theme/styles/timing";
import { useResetCursorUnMount } from "../../../hooks/useResetCursorUnMount";

export const useCircularContentBottom = ({
  isVisible,
  isVacansiesPage,
}: CircularContentBottomProps) => {
  const { changeMouseProps } = useResetCursorUnMount();

  const containerAnim = useSpring({
    delay: isVisible ? 0 : Timing.DURATION,
    config: { duration: Timing.DURATION_FAST },

    from: {
      width: isVisible ? "0px" : "90px",
    },
    to: {
      width: isVisible ? (isVacansiesPage ? "124px" : "90px") : "0px",
    },
  });

  const textAnim = useSpring({
    delay: isVisible ? Timing.DURATION : 0,
    from: {
      opacity: isVisible ? 0 : 1,
    },
    to: {
      opacity: isVisible ? 1 : 0,
    },
  });

  return {
    textAnim,
    containerAnim,
    changeMouseProps,
  };
};
