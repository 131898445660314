export enum MainPageNamespace {
  INDEX = "/",
  CASES = "/cases",
  ABOUT = "/about",
  PARTNERS = "/partners",
  VACANCIES = "/vacancies",
}

export enum ContactsNamespace {
  CONTACTS = "/contacts",
  // что бы вернуть как было раскоментируй код ниже
  // CONTACTS_PHONE = "/contacts/phone",
  CONTACTS_EMAIL = "/contacts/email",
  CONTACTS_ADDRESS = "/contacts/address",
}

export enum PriceNamespace {
  PRICE = "/price",
  PRICE_ID = "/price/:id",
  PRICE_MOBILE_APP = "mobile-app",
  PRICE_PERSON_SITE = "person-site",
  PRICE_CORPORATE_SITE = "corporate-site",
  PRICE_ONLINE_SHOP = "online-shop",
  PRICE_DESIGN = "design",
}

export enum CertificateNamespace {
  CERTIFICATE = "/certificate",
  CERTIFICATE_ID = "/certificate/:id",
}

export enum GiftNamespace {
  GIFT = "/gift",
  GIFT_ID = "/gift/:id",
}

export type PagesNamespaceType = {
  [key in
    | keyof typeof MainPageNamespace
    | keyof typeof ContactsNamespace
    | keyof typeof PriceNamespace
    | keyof typeof CertificateNamespace
    | keyof typeof GiftNamespace]: string;
};

export const PagesNamespace: PagesNamespaceType = {
  ...MainPageNamespace,
  ...ContactsNamespace,
  ...PriceNamespace,
  ...CertificateNamespace,
  ...GiftNamespace,
};

export const PagesNamespaceRU: PagesNamespaceType = {
  INDEX: "Главная",
  PARTNERS: "Партнеры",
  ABOUT: "О нас",
  CASES: "Кейсы",
  CONTACTS: "Контакты",
  // CONTACTS_PHONE: "Контакты",
  CONTACTS_EMAIL: "Контакты",
  CONTACTS_ADDRESS: "Контакты",
  VACANCIES: "Вакансии",
  PRICE: "Прайс",
  PRICE_ID: "Прайс",
  PRICE_MOBILE_APP: "Прайс",
  PRICE_PERSON_SITE: "Прайс",
  PRICE_CORPORATE_SITE: "Прайс",
  PRICE_ONLINE_SHOP: "Прайс",
  PRICE_DESIGN: "Прайс",
  CERTIFICATE: "Сертификат",
  CERTIFICATE_ID: "Сертификат",
  GIFT: "Скидка",
  GIFT_ID: "Скидка",
};
