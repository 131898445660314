import React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ZIndex } from "theme/styles/zIndex";

const FullScreenModal: React.FC<{ isLandscape: boolean }> = ({
  isLandscape,
}) => {
  return createPortal(
    <ContainerSC isLandscape={isLandscape}>
      <TextSC>
        сайт не предусматривает горизонтального отображения на данном
        устройстве. пожалуйста, переверните устройство.
      </TextSC>
    </ContainerSC>,
    document.querySelector("#root") as HTMLDivElement,
  );
};

const ContainerSC = styled.div<{ isLandscape: boolean }>`
  height: 100%;
  display: flex;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background: black;
  opacity: ${({ isLandscape }) => (isLandscape ? 1 : 0)};
  z-index: ${({ isLandscape }) =>
    isLandscape ? ZIndex.FULLSCREEN : ZIndex.HIDDEN};

  align-items: center;
  justify-content: center;

  transition: all 0.6s linear;
`;

const TextSC = styled.p`
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
`;

export default FullScreenModal;
