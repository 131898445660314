import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const WrapperSwiperSC = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleSC = styled.h6`
  color: #fff;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 18px;
    max-width: 200px;
    margin: 0 auto;
  }
`;

const ArrowButtonSC = styled("button")`
  border: 0;
  background-color: transparent;
  min-width: 28px;
  min-height: 28px;
`;

export const useCPMCategoriesSwipeStyles = () => ({
  WrapperSwiperSC,
  TitleSC,
  ArrowButtonSC,
});
