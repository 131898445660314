import React from "react";
import { useCircularTextUIStyles } from "UI/CircularTextUI/CircularTextUI.styles";
import { CircularTextProps } from "UI/CircularTextUI/types";
import { useCircularTextUI } from "UI/CircularTextUI/useCircularTextUI";

const CircularTextUI = ({ children, ...otherProps }: CircularTextProps) => {
  const { ref } = useCircularTextUI();

  return (
    <ParagraphSC ref={ref} {...otherProps}>
      {children}
    </ParagraphSC>
  );
};

const { ParagraphSC } = useCircularTextUIStyles();

export default React.memo(CircularTextUI);
