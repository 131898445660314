import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

const WrapperSC = styled("div")`
  padding: 0 10px;
  & > .swiper > .swiper-wrapper {
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const SwiperSlideSC = styled(SwiperSlide)`
  width: 232px;
  margin-right: 40px;
  padding: 40px 0 30px;
`;

export const useCasesPageDesktopStyles = () => {
  return {
    WrapperSC,
    SwiperSlideSC,
  };
};
