import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const ParagraphSC = styled.p`
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;

  max-width: 281px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 13px;
    line-height: 15px;

    margin-bottom: 4px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 13px;
    line-height: 15px;

    margin-bottom: 4px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    text-align: center;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const TimeWrapperSC = styled.p`
  text-align: right;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    text-align: center;
  }
`;

export const useCPTimerStyles = () => ({
  TimeWrapperSC,
  ParagraphSC,
});
