import React from "react";
import { ThemeProvider } from "styled-components";
import { RouterProvider } from "react-router-dom";
import { theme } from "theme/theme";
import { browserRouter } from "./routes/browserRouter";
import { GlobalStyles } from "theme/GlobalStyles";
import "normalize.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={browserRouter} />
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default App;
