import React from "react";
import { ReactComponent as CloseIcon } from "assets/icon/CloseIcon.svg";
import { certificateCondData } from "lib/mock/certificateCondData";
import { styles } from "routes/pages/CertificatePage/CPConditionText/CPConditionText.styles";

interface CPConditionTextProps {
  handleTogglePopup: () => void;
}

const CPConditionText = ({ handleTogglePopup }: CPConditionTextProps) => (
  <WrapperSC onClick={handleTogglePopup}>
    <ContainerSC onClick={(e) => e.stopPropagation()}>
      <TopContainerSC>
        <TitleSC>Условия использования сертификата:</TitleSC>
        <ButtonSC onClick={handleTogglePopup}>
          <CloseIcon />
        </ButtonSC>
      </TopContainerSC>
      <ListSC>
        {certificateCondData.map((item) => (
          <LiSC key={item}>
            <SubtitleSC>{item}</SubtitleSC>
          </LiSC>
        ))}
      </ListSC>
    </ContainerSC>
  </WrapperSC>
);

const {
  WrapperSC,
  ContainerSC,
  TopContainerSC,
  TitleSC,
  SubtitleSC,
  ListSC,
  LiSC,
  ButtonSC,
} = styles;

export default React.memo(CPConditionText);
