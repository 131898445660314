import React from "react";
import { createPortal } from "react-dom";
import { usePopupFormStyles } from "components/DiscussProjectForm/DiscussProjectForm.styles";
import { ReactComponent as CloseIcon } from "assets/icon/CloseIcon.svg";
import { useDiscussProjectForm } from "components/DiscussProjectForm/useDiscussProjectForm";
import SuccessForm from "components/DiscussProjectForm/SuccesForm/SuccessForm";
import { lengthInputConstraints } from "lib/constants/enums/InputLength";
import { formFeedbackMock } from "lib/mock/formFeedbackMock";

const DFFormDesktop = () => {
  const {
    respData,
    isView,
    changeMouseProps,
    values,
    errors,

    handleChange,
    onClickExit,
    handleWrapperClick,
    handleSubmit,
  } = useDiscussProjectForm();

  return createPortal(
    <ContainerSC isView={isView} onClick={onClickExit}>
      <WrapperSC onClick={handleWrapperClick}>
        {!respData.url && (
          <>
            <TopContainerSC>
              <TitleSC>{formFeedbackMock.title}</TitleSC>
              <ButtonSC type={"button"} onClick={onClickExit}>
                <CloseIcon />
              </ButtonSC>
            </TopContainerSC>
            <SubtitleSC>{formFeedbackMock.subtitle}</SubtitleSC>
            <FormSC onSubmit={handleSubmit} noValidate={true}>
              <div>
                <InputContainerSC>
                  <InputSC
                    onChange={handleChange}
                    value={values.name || ""}
                    name={"name"}
                    autoComplete={"off"}
                    minLength={lengthInputConstraints.name}
                    placeholder={"Ваше имя *"}
                    required
                  />
                  <FocusSpanSC />
                </InputContainerSC>
                <ErrorMessageSC>{errors.name}</ErrorMessageSC>
              </div>

              <div>
                <InputContainerSC>
                  <InputSC
                    onChange={handleChange}
                    value={values.phone || ""}
                    type={"tel"}
                    name={"phone"}
                    autoComplete={"off"}
                    minLength={lengthInputConstraints.phone}
                    placeholder={"Телефон *"}
                    required
                  />
                  <FocusSpanSC />
                </InputContainerSC>
                <ErrorMessageSC>{errors.phone}</ErrorMessageSC>
              </div>

              <InputContainerSC>
                <InputSC
                  onChange={handleChange}
                  value={values.message || ""}
                  autoComplete={"off"}
                  name={"message"}
                  placeholder={"Расскажите об идее"}
                />
                <FocusSpanSC />
              </InputContainerSC>

              <SubmitButtonSC {...changeMouseProps} type={"submit"}>
                отправить заявку
              </SubmitButtonSC>
            </FormSC>
          </>
        )}
        {respData.url && (
          <SuccessForm respData={respData} onClickExit={onClickExit} />
        )}
      </WrapperSC>
      <TextSC>
        * Условия использования сертификата: Сертификат предоставляет скидку в
        размере 10% от итоговой стоимости, но не более 30000р; Каждый человек
        может получить только один сертификат, который может быть использован
        лишь однократно. Сертификаты не подлежат обмену на денежный эквивалент;
        Сертификат не может быть суммирован с другими сертификатами; Сертификат
        действителен в течение 1 месяца. По истечении указанного срока
        неиспользованный сертификат аннулируется.
      </TextSC>
    </ContainerSC>,
    document.querySelector("#root") as HTMLDivElement,
  );
};

const {
  ContainerSC,
  WrapperSC,
  FormSC,
  SubtitleSC,
  TitleSC,
  ButtonSC,
  TopContainerSC,
  InputContainerSC,
  InputSC,
  SubmitButtonSC,
  FocusSpanSC,
  TextSC,
  ErrorMessageSC,
} = usePopupFormStyles();

export default React.memo(DFFormDesktop);
