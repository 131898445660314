import React from "react";
import CountUp from "react-countup";

interface AnimatedNumberI {
  price: string;
}

const AnimatedNumberComponent = ({ price }: AnimatedNumberI) => (
  <CountUp
    duration={2}
    end={Number(price)}
    separator=" "
    suffix=" ₽"
    preserveValue={true}
  />
);

export default React.memo(AnimatedNumberComponent);
