import React from "react";
import styled from "styled-components";
import { ReactComponent as BottomArrowIcon } from "assets/icon/BottomArrowIcon.svg";
import { Breakpoints } from "theme/styles/breakpoints";

const HeaderScrollDown = () => {
  return (
    <ContainerSC>
      <TextSC>SCROLL DOWN</TextSC>
      <BottomArrowIcon />
    </ContainerSC>
  );
};

const ContainerSC = styled("div")`
  display: none;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    display: inline-flex;
    align-items: center;
    flex-direction: column;

    position: absolute;
    left: 15px;
  }
`;

const TextSC = styled("p")`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 12px;
`;

export default React.memo(HeaderScrollDown);
