import styled from "styled-components";
import checkedImage from "assets/icon/CheckMark.svg";

const TextSC = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  text-align: left;
`;

const LabelSC = styled.label`
  display: flex;
  align-items: center;
  cursor: none;
`;

const CheckboxSC = styled.input.attrs({ type: "checkbox" })`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 4px;

  position: relative;

  &:checked {
    content: "";
    display: inline-block;
    background-image: url(${checkedImage});
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const useCPMCheckoboxStyles = () => ({
  CheckboxSC,
  TextSC,
  LabelSC,
});
