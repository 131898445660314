import {
  HEADER_ROUND_BOTTOM_LENGTH,
  HEADER_ROUND_LENGTH,
  HEADER_ROUND_TOP_LENGTH,
} from "lib/mock/headerData";
import {
  FromToStyle,
  UseHeaderItemProps,
} from "components/Header/HeaderItem/types";
import { ZIndex } from "theme/styles/zIndex";
import { useSpring } from "@react-spring/web";
import { Size } from "theme/styles/Size";

const HEADER_ITEM_HEIGHT = Size.HEADER_ITEM;

export const useHeaderItemMobile = (props: UseHeaderItemProps) => {
  const { id, activeId, isActive, isVisible } = props;

  const offset = id - activeId + 2;
  const isDisplay =
    id >= activeId - HEADER_ROUND_TOP_LENGTH &&
    id < activeId + HEADER_ROUND_LENGTH - HEADER_ROUND_BOTTOM_LENGTH;

  const isOnePrevActive = id === activeId - 1;
  // const isTwoPrevActive = id === activeId - 2;

  const isOneNextActive = id === activeId + 1;
  const isTwoNextActive = id === activeId + 2;

  const isPointerEvent = false;

  const getTranslate = (): FromToStyle => {
    if (isOneNextActive) {
      return {
        from: 0,
        to: HEADER_ITEM_HEIGHT * offset + 18,
      };
    }
    if (isTwoNextActive) {
      return {
        from: 0,
        to: HEADER_ITEM_HEIGHT * offset + 23,
      };
    }
    return {
      from: 0,
      to: HEADER_ITEM_HEIGHT * offset,
    };
  };

  const getOpacity = (): FromToStyle => {
    if (isDisplay && isVisible) {
      return { from: 1, to: 1 };
    }
    return { from: 0, to: 0 };
  };

  const getFontSize = (): FromToStyle => {
    if (isOnePrevActive || isOneNextActive) {
      return { from: 22, to: 22 };
    }
    if (isActive) {
      return { from: 22, to: 38 };
    }
    return { from: 15, to: 15 };
  };

  const getZIndex = (): FromToStyle => {
    if (!isVisible) {
      return { from: ZIndex.HIDDEN, to: ZIndex.HIDDEN };
    }
    return { from: ZIndex.HEADER, to: ZIndex.HEADER };
  };

  //*** STYLING
  const translateY = getTranslate();
  const opacity = getOpacity();
  const fontSize = getFontSize();
  const zIndex = getZIndex();

  const springs = useSpring({
    config: { duration: 100 },
    from: {
      top: translateY.from,
      opacity: opacity.from,
      fontSize: fontSize.from,
      zIndex: zIndex.from,
    },
    to: {
      top: translateY.to,
      opacity: opacity.to,
      fontSize: fontSize.to,
      zIndex: zIndex.to,
    },
  });

  return {
    isPointerEvent,
    springs,
  };
};
