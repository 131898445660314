import React from "react";
import { useAnimatedDescStyles } from "UI/CircularContentUI/AnimatedDescription/AnimatedDescription.styles";
import { ValueItem } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";
import TextTransitionUI, { present } from "UI/TextTransitionUI";

interface AnimatedDescriptionProps {
  isAdded: boolean;
  otherValues: ValueItem[];
  currentIndex: number;
}
const AnimatedDescription = ({
  isAdded,
  otherValues,
  currentIndex,
}: AnimatedDescriptionProps) => (
  <DescriptionSC isAdded={isAdded}>
    {otherValues && otherValues.length > 0 && (
      <>
        <PlusSC>+</PlusSC>
        <PriceItemSC>
          <TextTransitionUI springConfig={present.wobbly} direction={"down"}>
            {otherValues[currentIndex % otherValues.length].text}
            <PriceTimeSC>
              {otherValues[currentIndex % otherValues.length].price} ₽
              {otherValues[currentIndex % otherValues.length].time}
            </PriceTimeSC>
          </TextTransitionUI>
        </PriceItemSC>
      </>
    )}
  </DescriptionSC>
);

const { PlusSC, PriceTimeSC, PriceItemSC, DescriptionSC } =
  useAnimatedDescStyles();
export default React.memo(AnimatedDescription);
