import React, { useState } from "react";
import styled from "styled-components";
import { ValueItem } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";

interface CPButton {
  item: {
    price: number;
    text: string;
  };
  handleToggleItem: (arg: ValueItem) => void;
}
const CPButton = ({ item, handleToggleItem }: CPButton) => {
  const [isAdded, setIsAdded] = useState(false);

  const toggle = () => {
    setIsAdded(!isAdded);
  };

  return (
    <ButtonSC
      isAdded={isAdded}
      onClick={() => {
        handleToggleItem(item);
        toggle();
      }}>
      # {item.text}
    </ButtonSC>
  );
};

const ButtonSC = styled.button<{ isAdded: boolean }>`
  padding: 12px;
  color: ${({ isAdded }) => (isAdded ? "#000" : "#fff")};
  background: ${({ isAdded }) => (isAdded ? "#fff" : "none")};
  font-size: 16px;
  font-weight: 500;

  text-transform: uppercase;

  &:hover {
    background: ${({ isAdded }) =>
      isAdded ? "#fff" : "rgba(255, 255, 255, 0.10)"}
`;

export default React.memo(CPButton);
