import { useEffect, useRef, useState } from "react";
import Swiper from "swiper";
import { useNavigate, useParams } from "react-router-dom";
import { PriceNamespace } from "lib/constants/enums/PagesNamespace";
import { priceValues, priceData } from "lib/mock/priceData";
import { ValueItem } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";

export const useCalculatedPageMobile = () => {
  const REG_EXP_ALL_EXCEPT_NUMBER = /[^0-9]/g;
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<Swiper>();
  // ref на контейнер, что бы измерять его высоту и проверять сколько элементов влезет
  const listRef = useRef<HTMLUListElement>(null);
  // получаем id страницы, исходя от этого получаем мок данных на отрисовку
  const { id } = useParams<{ id?: PriceNamespace }>();
  // выбранныые элементы
  const [selectedPrices, setSelectedPrices] = useState<ValueItem[]>([]);
  // выбранныые элементы которые считаются по времени
  const [otherValues, setOtherValues] = useState<ValueItem[]>([]);
  // итоговая стоимость
  const [totalPrice, setTotalPrice] = useState<string>();
  // текущая страница(если элементы не умещается в контейнер(listRef), то дробим массив на не сколько частей(страниц)
  const [currentPage, setCurrentPage] = useState<number>(1);
  // состояние когда все элементы отрисованы(рисуем страницу итоговой стоимости)
  const [allItemsRendered, setAllItemsRendered] = useState<boolean>(false);

  // получаем весь мок данных исходя от id страницы
  const prices = id ? priceValues[id] || [] : [];

  // макс. количество элементов которые влезут в контейнер
  const itemsPerPage = listRef?.current?.clientHeight
    ? listRef.current.clientHeight / 45
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Получаем текущую порцию элементов для отображения
  let currentPrices = prices.slice(startIndex, endIndex);

  // перелистываем страницу, если все элементы отрисовали, то показываем итоговую страницу
  const handleNextPage = () => {
    if (endIndex >= prices.length) {
      setAllItemsRendered(true);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  //возвращаемся на предыдущую страницу
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleReset = () => {
    setCurrentPage(1);
    setAllItemsRendered(false);
    setSelectedPrices([]);
    currentPrices = prices;
  };

  // калькулируем стоимость
  const calculateTotalPrice = () => {
    let total = 0;
    selectedPrices
      .filter((item) => !item.isOther)
      .forEach((item) => {
        total += item.price;
      });
    const formatPrice = getFormatNumberToPrice(total.toString());
    setTotalPrice(formatPrice);
  };

  // форматируем стоимость
  const getFormatNumberToPrice = (price: string) => {
    const numberPrice = price.replace(REG_EXP_ALL_EXCEPT_NUMBER, "");

    if (numberPrice && !isNaN(+numberPrice)) {
      return new Intl.NumberFormat("ru-RU").format(+numberPrice);
    }
    return numberPrice;
  };

  // меняем состояние чекбокса и сохраняем выбранные элементы
  const handleCheckboxChange = (textItem: ValueItem) => {
    setSelectedPrices((prevPrices) => {
      const index = prevPrices.findIndex((item) => item.text === textItem.text);
      if (index !== -1) {
        return prevPrices.filter((_, i) => i !== index);
      } else {
        return [...prevPrices, textItem];
      }
    });
  };

  const handleChangeSwiper = (swiperInit: Swiper) => {
    setSwiper(swiperInit);
  };

  // перелистываем слайд, категорий
  const handleNextSlide = () => {
    if (swiper?.animating) {
      return;
    }

    swiper?.slideNext();

    const currentIndex = priceData.data.findIndex((item) => item.url === id);
    const nextIndex = (currentIndex + 1) % priceData.data.length;
    const nextUrl = `${PriceNamespace.PRICE}/${priceData.data[nextIndex].url}`;

    navigate(nextUrl);

    setSelectedPrices([]);
    setCurrentPage(1);
    setAllItemsRendered(false);
  };

  // перелистываем слайд, категорий
  const handlePrevSlide = () => {
    if (swiper?.animating) {
      return;
    }

    swiper?.slidePrev();

    const currentIndex = priceData.data.findIndex((item) => item.url === id);
    const prevIndex =
      (currentIndex - 1 + priceData.data.length) % priceData.data.length;
    const prevUrl = `${PriceNamespace.PRICE}/${priceData.data[prevIndex].url}`;

    navigate(prevUrl);
    setSelectedPrices([]);
    setCurrentPage(1);
    setAllItemsRendered(false);
  };

  // Фильтруем элементы которые считаются по времени
  useEffect(() => {
    const otherItems = selectedPrices.filter((item) => item.isOther);
    setOtherValues(otherItems);
  }, [selectedPrices]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedPrices]);

  return {
    selectedPrices,
    totalPrice,
    currentPrices,
    allItemsRendered,
    listRef,
    currentPage,
    otherValues,

    handleReset,
    handlePrevPage,
    handleNextPage,
    handleCheckboxChange,
    handleNextSlide,
    handlePrevSlide,
    handleChangeSwiper,
  };
};
