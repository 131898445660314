import React from "react";
import DFFormDesktop from "components/DiscussProjectForm/DiscuesProjectFormDesktop";
import { useTablet } from "hooks/useBreakpoints";
import DFFormMobile from "components/DiscussProjectForm/DiscuesProjectFormMobile";

const DiscussProjectForm = () => {
  const isMobile = useTablet();

  return (
    <>
      {!isMobile && <DFFormDesktop />}
      {isMobile && <DFFormMobile />}
    </>
  );
};

export default React.memo(DiscussProjectForm);
