import { Size } from "theme/styles/Size";

import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const IndexContainerSC = styled("div")`
  height: 100%;
  width: 100%;

  @media (max-width: ${Breakpoints.TABLE}px) {
    padding: 0 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const IndexWrapperSC = styled("div")`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const IndexListSC = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  //position: absolute;
  //position: relative;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    //position: relative;
    //width: 100%;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    flex-direction: column;

    gap: 44px;
  }
`;

const IndexLogoSC = styled.li<{ isClicked: boolean }>`
  transition: all 0.5s 0.5s linear;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    opacity: ${({ isClicked }) => (isClicked ? 0 : 1)};
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    margin-top: 100px;
  }
`;

const IndexTextSC = styled.li`
  @media (min-width: ${Breakpoints.BIG_TABLET}px) {
    position: relative;
  }
`;

const IndexBlockLoaderSC = styled("div")`
  margin-right: 80px;

  position: relative;

  border-radius: 50%;
  background: url(/assets/images/logoGif.gif),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 42.03%,
      rgba(0, 0, 0, 0.58) 85.94%,
      #000 100%
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (max-width: ${Breakpoints.FULL_HD}px) {
    width: 11.5vw;
    height: 11.5vw;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    //padding-top: 90px;
    width: ${Size.CIRCULAR_MOBILE_SIZE}px;
    height: ${Size.CIRCULAR_MOBILE_SIZE}px;
    margin-right: 0;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    width: 45vw;
    height: 45vw;
  }
`;

const CircularContainerSC = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IndexBlockTextSC = styled.div<{ isHover: boolean; isClicked: boolean }>`
  opacity: ${({ isHover }) => (isHover ? 0 : 1)};

  transition: all 0.5s 0.5s linear;

  font-weight: 600;
  font-size: 250px;
  user-select: none;

  max-width: 844px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: ${Breakpoints.FULL_HD}px) {
    letter-spacing: 2px;
    font-size: 13vw;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    opacity: ${({ isClicked }) => (isClicked ? 0 : 1)};
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    //margin-top: calc(${Size.CIRCULAR_MOBILE_SIZE}px - 54px);/
  }
  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 17.8vw;
  }
`;

const IndexSpan = styled.span`
  height: 100%;
  padding: 0 2% 0 2.5%;

  svg {
    width: auto;
    display: inline-block;

    height: 0.71em;

    g {
      path {
        @media (max-width: ${Breakpoints.BIG_TABLET}px) {
          stroke-width: 1;
        }
        @media (max-width: ${Breakpoints.TABLE}px) {
          stroke-width: 2;
        }
      }
    }
  }
`;

export const useIndexStyles = () => ({
  IndexContainerSC,
  IndexBlockLoaderSC,
  CircularContainerSC,
  IndexBlockTextSC,
  IndexWrapperSC,
  IndexSpan,
  IndexListSC,
  IndexLogoSC,
  IndexTextSC,
});
