import React from "react";
import { CPDNavbarProps } from "routes/pages/CertificatePage/CPDNavbar/CPDNavbar";
import { ReactComponent as EmailIcon } from "assets/icon/EmailIcon.svg";
import { ReactComponent as GeoIcon } from "assets/icon/GeoIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/icon/PhoneIcon.svg";
import { useGPDNMobileStyles } from "routes/pages/GiftPage/GiftPageMobile/GPDNMobile/index.styles";

const iconData = [
  { icon: PhoneIcon, name: "Phone" },
  { icon: EmailIcon, name: "Email" },
  { icon: GeoIcon, name: "Geo" },
];

const GPDNMobile = ({ handleIconClick, selectedIcon }: CPDNavbarProps) => {
  return (
    <ListSC>
      {iconData.map(({ icon: IconComponent, name }) => (
        <LiSC
          key={name}
          onClick={() => handleIconClick && handleIconClick(name)}>
          <IconContainerSC isActive={selectedIcon === name}>
            <IconComponent />
          </IconContainerSC>
        </LiSC>
      ))}
    </ListSC>
  );
};

const { IconContainerSC, ListSC, LiSC } = useGPDNMobileStyles();

export default React.memo(GPDNMobile);
