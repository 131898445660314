import { Timing } from "theme/styles/timing";

export enum Animations {
  LINEAR_01 = "0.1s all linear",
  LINEAR_02 = "0.2s all linear",
  CIRCULAR_ANIMATION = "",
  CIRCULAR_ANIMATION_STROKE = "stroke-dashoffset",
  CIRCULAR_ANIMATION_TIMEOUT = "0s",
  CIRCULAR_ANIMATION_TYPE = "linear",
}

Animations[
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  "CIRCULAR_ANIMATION"
] = `${Animations.CIRCULAR_ANIMATION_STROKE} ${
  Timing.CIRCULAR_PROGRESS_TIMING / 1000
}s ${Animations.CIRCULAR_ANIMATION_TYPE} ${
  Animations.CIRCULAR_ANIMATION_TIMEOUT
}`;
