import { useEffect, useState } from "react";

export const useIndexPage = () => {
  const [progress, setProgress] = useState<number>(0);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleHoverEnter = () => {
    setIsHover(true);
  };

  const handleHoverLeave = () => {
    setIsHover(false);
  };

  // обработчик для мобилок
  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    setProgress(100);
  }, []);
  return {
    isHover,
    progress,
    isClicked,

    handleHoverEnter,
    handleHoverLeave,
    handleClick,
  };
};
