import styled, { css } from "styled-components";
import { animated } from "@react-spring/web";
import { ZIndex } from "theme/styles/zIndex";
import { ColorScheme } from "theme/styles/colorScheme";
import { Breakpoints } from "theme/styles/breakpoints";

const HEADER_SIZES = {
  PADDING: 14,
  BLANK_WIDTH: 20,
};

const NotActiveHoverCSS = css`
  &:hover {
    padding-left: ${HEADER_SIZES.BLANK_WIDTH + 5}px;
    &::before {
      width: ${HEADER_SIZES.BLANK_WIDTH}px;
    }
  }
  &::before {
    content: "";
    width: 0;
    height: 3px;
    background-color: ${ColorScheme.WHITE};
    position: absolute;
    left: 0;
    top: 50%;
    z-index: ${ZIndex.HEADER};
    transform: translateY(-50%);
    transition: 0.2s all linear;
  }
`;

const ActiveCSS = css`
  font-weight: 700;
`;

const NotActiveCSS = css`
  font-weight: 600;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-weight: 500;
  }
  ${NotActiveHoverCSS}
`;

const LiSC = styled(animated.li)<{
  isActive: boolean;
  isPointerEvent: boolean;
}>`
  position: absolute;
  min-width: 250px;

  text-transform: uppercase;
  user-select: none;

  transition: 0.2s all linear;
  pointer-events: ${({ isPointerEvent }) => (isPointerEvent ? "auto" : "none")};

  ${({ isActive }) => (isActive ? ActiveCSS : NotActiveCSS)};
`;

export const useHeaderItemStyles = () => ({
  LiSC,
});
