import styled from "styled-components";

const PlusSC = styled.p`
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  line-height: 38px;
`;

const WrapperSC = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TotalPriceSC = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
`;

const OtherValuesContainerSC = styled.div`
  display: flex;
`;

const SubtitleSC = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`;

const ButtonSC = styled.button`
  color: inherit;
  background: none;
  border: none;

  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;

  margin-bottom: 22px;
`;

export const useCPMTotalStyles = () => ({
  SubtitleSC,
  OtherValuesContainerSC,
  TotalPriceSC,
  ButtonSC,
  WrapperSC,
  PlusSC,
});
