import React from "react";
import { useTablet } from "hooks/useBreakpoints";
import CPDNDesktop from "routes/pages/CertificatePage/CPDNavbar/CPDNDesktop/CPDNDesktop";
import CPDNMobile from "routes/pages/CertificatePage/CPDNavbar/CPDNMobile/CPDNMobile";

export interface CPDNavbarProps {
  handleIconClick?: (arg: string) => void;
  selectedIcon?: string;
}

const CPDNavbar = ({ handleIconClick, selectedIcon }: CPDNavbarProps) => {
  const isMobile = useTablet();

  return (
    <>
      {!isMobile && <CPDNDesktop />}
      {isMobile && (
        <CPDNMobile
          handleIconClick={handleIconClick}
          selectedIcon={selectedIcon}
        />
      )}
    </>
  );
};

export default React.memo(CPDNavbar);
