import React from "react";
import { useCircularContentStyles } from "UI/CircularContentUI/CircularContent.styles";
import { CircularContentUIProps } from "UI/CircularContentUI/types";
import CircularContentBottom from "UI/CircularContentUI/CircularContentBottom/CircularContentBottom";
import { useCircularContentUI } from "UI/CircularContentUI/useCircularContentUI";
import CircularProgressbarUI from "UI/CircularProgressbarUI/CircularProgressbarUI";

import AnimatedNumberComponent from "UI/CircularContentUI/AnimatedNumber/AnimatedNumber";
import AnimatedDescription from "UI/CircularContentUI/AnimatedDescription/AnimatedDescription";

const CircularContentUI = (props: CircularContentUIProps) => {
  const {
    style,
    text,
    bottom,
    isCalculatePage,
    otherValues,
    isLoading,
    styleCont,
    onClick,
  } = props;
  const {
    value,
    isDisabled,
    currentIndex,
    changeCursorProps,
    isAdded,

    handleClick,
  } = useCircularContentUI(props);

  return (
    <>
      {!isLoading && (
        <CircularContainerSC style={styleCont} onClick={onClick}>
          <ParagraphSC
            style={style}
            isDisabled={isDisabled}
            isMore={otherValues ? otherValues.length > 0 : false}
            onClick={handleClick}
            {...changeCursorProps}>
            {!isCalculatePage && text}
            {isCalculatePage && <AnimatedNumberComponent price={text} />}
          </ParagraphSC>
          <AnimatedDescription
            isAdded={isAdded}
            otherValues={otherValues || []}
            currentIndex={currentIndex}
          />
          {bottom && <CircularContentBottom {...bottom} />}
        </CircularContainerSC>
      )}
      {isLoading && (
        <CircularLoadingContainerSC>
          <CircularProgressbarUI value={value} />
        </CircularLoadingContainerSC>
      )}
    </>
  );
};

const { CircularContainerSC, CircularLoadingContainerSC, ParagraphSC } =
  useCircularContentStyles();

export default React.memo(CircularContentUI);
