import React from "react";
import { useBigTablet } from "hooks/useBreakpoints";
import DiscusProjectMobile from "routes/pages/PricePage/CalculatedPage/DisscusProject/DisscusProjectMobile/DiscusProjectMobile";
import DiscusProjectDesktop from "routes/pages/PricePage/CalculatedPage/DisscusProject/DisscusProjectDesktop/DiscusProjectDesktop";

interface DiscusProjectProject {
  mobileConfig?: {
    pos: string;
    top: string;
    right: string;
  };
  desktopConfig?: { position: string };
}

const DiscusProject = ({
  mobileConfig,
}: // desktopConfig,
DiscusProjectProject) => {
  const isMobile = useBigTablet();

  return (
    <>
      {isMobile && <DiscusProjectMobile config={mobileConfig} />}
      {!isMobile && <DiscusProjectDesktop />}
    </>
  );
};

export default React.memo(DiscusProject);
