import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const ListSC = styled.ul`
  display: flex;
  max-width: 868px;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
`;

const LinkSC = styled.a`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 13px;
    line-height: 15px;
  }
`;

const IconContainerSC = styled.div`
  width: 28px;
  height: 28px;

  svg {
    path {
      width: 28px;
      height: 28px;
      stroke: #fff;
    }
  }
`;

const LiSC = styled.li`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;

  max-width: 360px;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const useCPDNDesktopStyles = () => ({
  LiSC,
  LinkSC,
  IconContainerSC,
  ListSC,
});
