import React from "react";

import { usePricePageDesktopStyles } from "routes/pages/PricePage/PricePageDesktop/PricePageDesktop.styles";
import { ReactComponent as UpArrowIcon } from "assets/icon/UpArrowIcon.svg";
import { ReactComponent as DownArrowIcon } from "assets/icon/DownArrowIcon.svg";
import { Link } from "react-router-dom";
import { PricePageI } from "lib/mock/priceData";
import { usePricePageDesktop } from "routes/pages/PricePage/PricePageDesktop/usePricePageDesktop";

// TODO Компонент с запутанной логикой, дай бог здоровья тому кто полезет сюда
const PricePageDesktop = ({ data }: PricePageI) => {
  const { cardsRef, galleryRef, prevButtonRef, nextButtonRef, dragProxyRef } =
    usePricePageDesktop();
  return (
    <WrapperSC className="gallery" ref={galleryRef}>
      <ContainersButton className="actions">
        <ButtonSC className="prev" ref={prevButtonRef}>
          <UpArrowIcon />
        </ButtonSC>
        <SwiperSlideSC className="cards" ref={cardsRef}>
          {data.map((item, i) => (
            <SlideSC key={i} style={{ cursor: "none" }}>
              <Link to={item.url}>{item.content}</Link>
            </SlideSC>
          ))}
        </SwiperSlideSC>
        <ButtonSC className="next" ref={nextButtonRef}>
          <DownArrowIcon />
        </ButtonSC>
        <div
          className="drag-proxy"
          ref={dragProxyRef}
          style={{ display: "none" }}></div>
      </ContainersButton>
    </WrapperSC>
  );
};

const { WrapperSC, SlideSC, ButtonSC, ContainersButton, SwiperSlideSC } =
  usePricePageDesktopStyles();

export default PricePageDesktop;
