import React from "react";

import { useCasesPageMobileStyles } from "./CasesPagesMobileStyle";
import CasesLargeImg from "./CasesLargeImg/CasesLargeImg";

import { casesData } from "lib/mock/casesData";

import CasesPageMobileItems from "./CasesPageMobileItem/CasesPageMobileItems";
import { useCasesPageMobile } from "./useCasesPageMobile";

const CasesPageMobile = () => {
  const {
    activeData,
    activeIndex,
    handleChangeActiveIndexMobile,
    handleToggleExtand,
    setisOpenExtand,
    isOpenExtand,
  } = useCasesPageMobile();

  if (!activeData) {
    return null;
  }

  return (
    <ContainerSC>
      <TitleSC>{activeData.title}</TitleSC>
      <GridSC>
        <CasesLargeImg
          {...activeData}
          isOpen={isOpenExtand}
          handleToggleExtand={handleToggleExtand}
        />
        {casesData.map((item, index) => (
          <CasesPageMobileItems
            key={item.title}
            item={item}
            index={index}
            activeIndex={activeIndex}
            handleChangeActiveIndexMobile={handleChangeActiveIndexMobile}
            setisOpenExtand={setisOpenExtand}
          />
        ))}
      </GridSC>
      <DescriptionSC>{activeData.desc}</DescriptionSC>
    </ContainerSC>
  );
};

const { GridSC, DescriptionSC, TitleSC, ContainerSC } =
  useCasesPageMobileStyles();

export default CasesPageMobile;
