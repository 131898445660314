import styled from "styled-components";
import { Size } from "theme/styles/Size";
import { ZIndex } from "theme/styles/zIndex";
import { ColorScheme } from "theme/styles/colorScheme";
import { Breakpoints } from "theme/styles/breakpoints";

const CircularContainerSC = styled("div")`
  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;
  position: relative;

  border-radius: 50%;
  border: 2.5px solid ${ColorScheme.WHITE};

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: ${ZIndex.UPPER_TWO};

  &::after {
    content: "";

    width: 100%;
    height: 100%;
    border-radius: 50%;

    position: absolute;
    left: 0;
    top: 0;

    backdrop-filter: blur(2.5px);
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    width: ${Size.CIRCULAR_MOBILE_SIZE}px;
    height: ${Size.CIRCULAR_MOBILE_SIZE}px;
  }
`;

const CircularLoadingContainerSC = styled("div")`
  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;
`;

const ParagraphSC = styled.p<{ isDisabled: boolean; isMore: boolean }>`
  color: ${({ isDisabled }) =>
    isDisabled ? ColorScheme.GRAY : ColorScheme.WHITE};

  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;

  z-index: ${ZIndex.UPPER_THREE};

  user-select: none;

  transition: all 0.5s linear;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: ${({ isMore }) => (isMore ? "80px" : "0")};

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 23px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.69px;
  }
`;

export const useCircularContentStyles = () => ({
  CircularContainerSC,
  CircularLoadingContainerSC,
  ParagraphSC,
});
