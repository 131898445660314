import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import React from "react";
import { IChildren } from "types/types";
import CursorUI from "UI/CursorUI/CursorUI";
import FullScreenModal from "UI/FullScreenModal/FullScreenModal";

import { useMainLayout } from "layout/MainLayout/useMainLayout";
import ErorrBoundary from "components/Wrappers/ErrorBoundary/ErorrBoundary";
import PopupForm from "components/DiscussProjectForm/DiscussProjectForm";
export interface ManLayoutProps extends IChildren {
  isHeader: boolean;
  isFooter: boolean;
  isCursor: boolean;
}
const MainLayout = ({
  children,
  isHeader,
  isFooter,
  isCursor,
}: ManLayoutProps) => {
  const { isTouch, isLandscape } = useMainLayout();

  return (
    <ErorrBoundary>
      {isHeader && <Header />}
      <main>{children}</main>
      {isFooter && <Footer />}
      <FullScreenModal isLandscape={isLandscape} />
      <PopupForm />
      {isTouch && isCursor && <CursorUI />}
    </ErorrBoundary>
  );
};

export default React.memo(MainLayout);
