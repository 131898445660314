import { RouteObject } from "react-router-dom";
import React, { MemoExoticComponent } from "react";
import RouteElement from "./RouteElement";
import CasesPage from "./pages/CasesPage/CasesPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import IndexPage from "./pages/IndexPage/IndexPage";
import PartnersPage from "./pages/PartnersPage/PartnersPage";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";

import VacanciesPage from "./pages/VacanciesPage/VacanciesPage";
// import InteractivePage from "./pages/ContactsPage/InteractivePage/InteractivePage";
import PhonePage from "./pages/ContactsPage/PhonePage/PhonePage";
import EmailPage from "./pages/ContactsPage/EmailPage/EmailPage";
import GeolocationPage from "./pages/ContactsPage/GeolocationPage/GeolocationPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

import PricePage from "./pages/PricePage/PricePage";
import CalculatedPage from "routes/pages/PricePage/CalculatedPage/CalculatedPage";
import CertificatePage from "routes/pages/CertificatePage/CertificatePage";
import { ManLayoutProps } from "layout/MainLayout/MainLayout";
import GiftPage from "routes/pages/GiftPage/GiftPage";

type MainArguments = Omit<ManLayoutProps, "children">;

const SetElement = (
  Component: MemoExoticComponent<() => JSX.Element>,
  mainProps?: Partial<MainArguments>,
) => {
  const props: MainArguments = {
    isHeader: true,
    isCursor: true,
    isFooter: true,
    ...mainProps,
  };
  return (
    <RouteElement {...props}>
      <Component />
    </RouteElement>
  );
};

export const routes: RouteObject[] = [
  {
    path: PagesNamespace.INDEX,
    element: SetElement(IndexPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.CASES,
    element: SetElement(CasesPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.ABOUT,
    element: SetElement(AboutPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.PARTNERS,
    element: SetElement(PartnersPage),
    errorElement: SetElement(ErrorPage),
  },
  // что бы вернуть как было, раскоментируй код ниже
  {
    path: PagesNamespace.CONTACTS,
    element: SetElement(PhonePage),
    errorElement: SetElement(ErrorPage),
  },
  // {
  //   path: PagesNamespace.CONTACTS,
  //   element: SetElement(InteractivePage),
  //   errorElement: SetElement(ErrorPage),
  // },
  // {
  //   path: PagesNamespace.CONTACTS_PHONE,
  //   element: SetElement(PhonePage),
  //   errorElement: SetElement(ErrorPage),
  // },
  {
    path: PagesNamespace.CONTACTS_EMAIL,
    element: SetElement(EmailPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.CONTACTS_ADDRESS,
    element: SetElement(GeolocationPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.VACANCIES,
    element: SetElement(VacanciesPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.PRICE,
    element: SetElement(PricePage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.PRICE_ID,
    element: SetElement(CalculatedPage),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.CERTIFICATE_ID,
    element: SetElement(CertificatePage, { isHeader: false, isFooter: false }),
    errorElement: SetElement(ErrorPage),
  },
  {
    path: PagesNamespace.GIFT_ID,
    element: SetElement(GiftPage, { isHeader: false, isFooter: false }),
    errorElement: SetElement(ErrorPage),
  },
];
