import React from "react";
import { useCPTimer } from "routes/pages/CertificatePage/CPTimer/useCPTimer";
import { useCPTimerStyles } from "routes/pages/CertificatePage/CPTimer/CPTimer.styles";

type CPTimerProps = {
  time: string;
};

const CPTimer = ({ time }: CPTimerProps) => {
  const { remainingTime } = useCPTimer({ time });

  return (
    <div>
      <ParagraphSC>
        до окончания срока действия сертификата осталось:
      </ParagraphSC>
      <TimeWrapperSC>
        {remainingTime.days || "000"} д. {remainingTime.hours || "00"} ч.{" "}
        {remainingTime.minutes || "00"} м.
      </TimeWrapperSC>
    </div>
  );
};

const { ParagraphSC, TimeWrapperSC } = useCPTimerStyles();

export default React.memo(CPTimer);
