import React from "react";
import { usePricePageStyles } from "routes/pages/PricePage/PricePage.styles";
import CircularContentUI from "UI/CircularContentUI/CircularContentUI";
import { useCalculatedPageStyles } from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/CalculatedPage.styles";
import CPButton from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/CPButton/CPButton";
import useCalculatedPage from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/useCalculatedPage";

const CalculatedPageDesktop = () => {
  const {
    prices,
    changeMouseProps,
    totalPrice,
    otherValues,
    isLoading,
    // showForm,

    toggleOpenForm,
    // handleShowForm,
    goBack,
    handleToggleItem,
  } = useCalculatedPage();

  return (
    <ContainerSC>
      {/*<DiscusProject />*/}
      <>
        <ParagraphVP onClick={toggleOpenForm}>заказать проект</ParagraphVP>
        <WrapperSC>
          <CircularContentUI
            text={totalPrice.toString()}
            style={{}}
            otherValues={otherValues}
            isCalculatePage={true}
            isLoading={isLoading}
            bottom={{
              isVisible: true,
              text: "Назад",
              onClick: () => goBack(),
            }}
          />
        </WrapperSC>
        <ContainerItems>
          <ListSC>
            {prices.map((item: { price: number; text: string }, i: number) => (
              <ListItemSC {...changeMouseProps} key={i}>
                <CPButton item={item} handleToggleItem={handleToggleItem} />
              </ListItemSC>
            ))}
          </ListSC>
        </ContainerItems>
      </>
    </ContainerSC>
  );
};

const { ContainerSC } = usePricePageStyles();
const { WrapperSC, ContainerItems, ListSC, ParagraphVP, ListItemSC } =
  useCalculatedPageStyles();

export default React.memo(CalculatedPageDesktop);
