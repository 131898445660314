import { API_URL } from "lib/constants/constants";

const feedback = `${API_URL}/index/feedback`;

export const ApiNameSpace = {
  VACANCIES: `${API_URL}/upload`,
  CERTIFICATE: `${API_URL}/index/get`,
  CONTACTS: feedback,
  QRCODE: `${feedback}/create`,
  GIFT: `${API_URL}/index/get_gift`,
};
