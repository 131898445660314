import React from "react";
import "react-circular-progressbar/dist/styles.css";
import { useIndexStyles } from "./IndexPage.styles";
import CircularProgressbarUI from "UI/CircularProgressbarUI/CircularProgressbarUI";
import { ReactComponent as EWord } from "assets/icon/EWord.svg";
import { useIndexPage } from "routes/pages/IndexPage/useIndexPage";
import DealText from "routes/pages/IndexPage/DealTextItems/DealText";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const IndexPage = () => {
  const {
    progress,
    isHover,
    isClicked,

    handleClick,
    handleHoverLeave,
    handleHoverEnter,
  } = useIndexPage();

  return (
    <IndexContainerSC className="mobile__container">
      <IndexWrapperSC>
        <IndexListSC
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverLeave}
          onClick={handleClick}>
          <IndexLogoSC isClicked={isClicked}>
            <IndexBlockLoaderSC>
              <CircularContainerSC>
                <CircularProgressbarUI value={progress} />
              </CircularContainerSC>
            </IndexBlockLoaderSC>
          </IndexLogoSC>
          <IndexTextSC>
            <IndexBlockTextSC isHover={isHover} isClicked={isClicked}>
              IND
              <IndexSpan>
                <EWord />
              </IndexSpan>
              X
            </IndexBlockTextSC>
            <DealText isHover={isHover} isClicked={isClicked} />
          </IndexTextSC>
        </IndexListSC>
      </IndexWrapperSC>
      <DiscusProject />
    </IndexContainerSC>
  );
};

const {
  IndexBlockLoaderSC,
  CircularContainerSC,
  IndexBlockTextSC,
  IndexContainerSC,
  IndexWrapperSC,
  IndexSpan,
  IndexListSC,
  IndexTextSC,
  IndexLogoSC,
} = useIndexStyles();

export default React.memo(IndexPage);
