import React from "react";
import { useHeaderItemStyles } from "components/Header/HeaderItem/HeaderItem.styles";
import { HeaderItemProps } from "components/Header/type";
import { useHeaderItem } from "components/Header/HeaderItem/useHeaderItem";

const HeaderItem = (props: HeaderItemProps) => {
  const { title } = props;

  const {
    isActive,
    isPointerEvent,
    springs,
    onClick,
    onMouseLeave,
    handleMouseEnter,
  } = useHeaderItem(props);

  return (
    <LiSC
      style={springs}
      isActive={isActive}
      isPointerEvent={isPointerEvent}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}>
      {title}
    </LiSC>
  );
};

const { LiSC } = useHeaderItemStyles();

export default React.memo(HeaderItem);
