import { useSpring } from "@react-spring/web";
import { Timing } from "theme/styles/timing";
import { useEffect } from "react";
import { handleLogVacancies } from "lib/mock/vacanciesLog";

export const useVacanciesPage = () => {
  const opacity = useSpring({
    delay: Timing.CIRCULAR_PROGRESS_TIMING_2,
    config: { duration: 1000 },
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  useEffect(() => {
    handleLogVacancies();
  });
  return {
    opacity,
  };
};
