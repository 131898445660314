import { useState } from "react";
import { useSpring } from "@react-spring/web";
import { useResetCursorUnMount } from "../../../../../hooks/useResetCursorUnMount";

export const useVacanciesSuccessSend = () => {
  const { onChangeActive } = useResetCursorUnMount();

  const [isText, setIsText] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const mountedAnim = useSpring({
    from: {
      opacity: isText ? 0 : 0,
    },
    to: {
      opacity: isText ? 1 : 0,
    },
  });

  const handleVisibleText = () => {
    setIsText(true);
  };

  const handleChangeHover = (state: boolean) => {
    return () => {
      onChangeActive(state)();
      setIsHover(state);
    };
  };

  return {
    isText,
    isHover,
    mountedAnim,

    handleChangeHover,
    handleVisibleText,
  };
};
