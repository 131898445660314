import styled from "styled-components";
import { ColorScheme } from "theme/styles/colorScheme";

const BackSC = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  color: white;
  transform: rotateY(180deg);
  background-color: ${ColorScheme.BLACK_TWO};
`;

const ImageSC = styled("img")`
  width: inherit;
  height: inherit;

  background-color: ${ColorScheme.BLACK};
`;

export const useFlipBackStyles = () => ({
  BackSC,
  ImageSC,
});
