export enum ZIndex {
  HIDDEN = -1,
  DEFAULT = 0,
  ELEMENT = 1,
  MODAL = 3,
  POPUP = 4,
  UPPER = 10,
  UPPER_TWO = 11,
  UPPER_THREE = 11,

  BACKDOOR = 997,
  HEADER = 998,

  POPUP_FORM = 999,
  FULLSCREEN = 1000,
  CURSOR = 1001,
}
