import React from "react";
import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { HeaderBackdoorProps } from "components/Header/HeaderBackdoor/types";

const HBContent = ({ isVisible }: HeaderBackdoorProps) => {
  return <ContainerSC isVisible={isVisible} />;
};

const ContainerSC = styled("div")<HeaderBackdoorProps>`
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);

  position: absolute;
  transition: 0.2s all linear;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: ${({ isVisible }) => (isVisible ? ZIndex.BACKDOOR : ZIndex.HIDDEN)};
`;

export default React.memo(HBContent);
