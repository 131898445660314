import { useEffect, useRef, useState } from "react";
import { CPMTotal } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMTotal/CPMTotal";

export const UseCpmTotal = ({ otherValues }: CPMTotal) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const loopPriceValue = (currentIndex: number) => {
    if (otherValues && otherValues.length > 1) {
      setCurrentIndex(currentIndex);
      setTimeout(() => {
        loopPriceValue((currentIndex + 1) % otherValues.length);
      }, 3000);
    }
  };

  useEffect(() => {
    loopPriceValue(currentIndex);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [currentIndex, otherValues]);

  return { showForm, currentIndex, handleShowForm };
};
