import React from "react";
import { CasesModelType } from "lib/models/CasesModelType";
import { useFlipFrontStyles } from "UI/FlipUI/FlipFront/FlipFront.styles";

const FlipFront = ({
  Icon,
  subtitle,
}: Pick<CasesModelType, "subtitle" | "Icon">) => {
  return (
    <ContainerSC>
      <IconContainerSC>
        <Icon />
      </IconContainerSC>
      <DescSC>{subtitle}</DescSC>
      <HoverSC />
    </ContainerSC>
  );
};

const { ContainerSC, IconContainerSC, DescSC, HoverSC } = useFlipFrontStyles();
export default React.memo(FlipFront);
