import { FilesNamespace } from "lib/constants/enums/FilesNamespace";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";

export const IndexData = {
  hello: [{ text: "Привет!" }],
  index_studio: [
    {
      text: "Мы INDEX-STUDIO — ваш надежный партнер в цифровых продуктах!",
    },
  ],
  paragraph: [
    {
      text: "Мы создаем сайты, интернет-магазины и мобильные приложения для вашего бизнеса. Независимо от сферы и местоположения вашего бизнеса, наши специалисты помогут вам выбрать и реализовать",
    },
    { text: "лучшие IT-решения", isPopupTrigger: true },
    { text: " ,чтобы улучшить показатели и достичь ваших целей. Мы " },
    { text: "гарантируем", href: FilesNamespace.POLICY },
    {
      text: " качественную работу в оговоренные сроки. Доверьте свои идеи нам, и мы поможем вам воплотить их в жизнь! Наша команда создаст не только функциональные, но и привлекательные",
    },
    {
      text: "цифровые продукты",
      isLink: true,
      href: "/cases",
    },
    {
      text: " ,которые будут успешно представлять ваш бизнес на мировом рынке.\n",
    },
  ],
};

export const indexDataMobile = {
  hello: "Привет!",
  index_studio: "Мы INDEX-STUDIO — ваш надежный партнер в цифровых продуктах!",
  paragraph: [
    {
      text: "Мы создаем сайты, интернет-магазины и мобильные приложения для вашего бизнеса. Независимо от сферы и местоположения вашего бизнеса, наши специалисты помогут вам выбрать и реализовать",
    },
    { text: "лучшие IT-решения", href: "#" },
    { text: " ,чтобы улучшить показатели и достичь ваших целей. Мы " },
    { text: "гарантируем", href: FilesNamespace.POLICY },
    {
      text: " качественную работу в оговоренные сроки. Доверьте свои идеи нам, и мы поможем вам воплотить их в жизнь! Наша команда создаст не только функциональные, но и привлекательные",
    },
    {
      text: "цифровые продукты ",
      href: PagesNamespace.CASES,
    },
    {
      text: " ,которые будут успешно представлять ваш бизнес на мировом рынке.\n",
    },
  ],
};
