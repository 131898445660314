import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GridSC = styled("div")`
  flex: 1;

  box-sizing: border-box;
  margin: 0 auto;

  justify-content: center;
  align-items: center;

  display: grid;
  grid-gap: 17.5px;

  grid-template-columns: repeat(5, 55px);
  grid-template-rows: repeat(4, 55px);
  margin: 0 auto;

  transform: scale(1.1);

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    transform: scale(1);
  }
  @media (max-width: ${Breakpoints.MOBILE}px) {
    grid-template-columns: repeat(4, 55px);
    grid-template-rows: repeat(5, 55px);
    margin: 0 auto;
    grid-gap: 14.5px;
  }

  & > .swiper > .swiper-wrapper {
    & > *:last-child {
      margin-right: 0;
    }
  }
`;

const DescriptionSC = styled.p`
  margin: 97px auto 0;
  /* margin: 0 auto; */
  max-width: 260px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 90px;
  @media (max-width: ${Breakpoints.MOBILE}px) {
    margin-top: 10px;
    max-width: 255px;
    font-size: 13px;
    line-height: 18px;
    height: 69px;
  }
`;

const TitleSC = styled.h6`
  margin: 0 auto 32px;
  max-width: 340px;

  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;

  height: 45px;

  /* @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    margin-bottom: 98px;
  } */

  @media (max-width: ${Breakpoints.MOBILE}px) {
    margin-bottom: 0;

    height: 40px;
  }
`;

export const useCasesPageMobileStyles = () => {
  return {
    GridSC,
    DescriptionSC,
    TitleSC,
    ContainerSC,
  };
};
