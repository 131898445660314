import styled, { css } from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { Breakpoints } from "theme/styles/breakpoints";

const baseMix = css`
  padding: 0 3px;
  background: #d9d9d9;

  font-size: 16px;
  color: #000;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 14px;
  }
`;

const ContainerSC = styled.div<{ isClicked: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${ZIndex.POPUP};

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: ${({ isClicked }) => (isClicked ? 1 : 0)};
  visibility: ${({ isClicked }) => (isClicked ? "visible" : "hidden")};
  transition: all 0.3s 0.5s linear;
`;

const DealTextSC = styled.div`
  padding: 0 24px;

  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.TABLE}px) {
    padding: 64px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    padding: 14px;
  }
`;

const DealTextTitle = styled.p`
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 24px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 22px;
  }
`;

const DealTextSubTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 18px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 16px;
  }
`;

const DealTextParagraphSC = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 14px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 12px;
  }
`;

const LinkSC = styled.a`
  ${baseMix};
`;

const ButtonSC = styled.button`
  ${baseMix};
  text-transform: uppercase;
  font-weight: 500;
`;

export const useDealTextMobileStyles = () => ({
  DealTextSC,
  DealTextParagraphSC,
  ContainerSC,
  DealTextSubTitle,
  DealTextTitle,
  LinkSC,
  ButtonSC,
});
