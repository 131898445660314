import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";
import { ColorScheme } from "theme/styles/colorScheme";

const HelloSC = styled.h5`
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 30px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 24px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 20px;
  }
`;

const NameSC = styled.p`
  font-size: 64px;
  font-weight: 700;
  text-transform: uppercase;

  margin-bottom: 48px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    margin-bottom: 18px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 51px;
    text-align: center;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    margin-bottom: 28px;
    font-size: 38px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 30px;
  }
`;

const DescriptionSC = styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  max-width: 687px;

  //margin-bottom: 32px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    margin-bottom: 0px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 15px;
    font-weight: 600;
  }
`;

const TextSC = styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  max-width: 687px;

  margin-bottom: 32px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    margin-bottom: 0px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 15px;
    font-weight: 600;
    margin-top: 55px;
  }
`;

const CostSC = styled.h6`
  text-align: center;
  font-size: 176px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: normal;

  margin-bottom: 32px;

  @media (max-width: ${Breakpoints.LAPTOP}px) {
    margin-bottom: 0px;
    font-size: 130px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 98px;
    margin-top: 32px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 57.298px;
  }
`;

const SubtitleSC = styled.p`
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 15px;
  }
`;

const WrapperSC = styled.div`
  //height: 30%;
  min-height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TournamentSC = styled.span`
  text-transform: uppercase;
`;

const ButtonSC = styled.button`
  display: inline;
  color: ${ColorScheme.WHITE};
  font-weight: 600;

  text-decoration: underline;
`;

export const useCPUserStyles = () => ({
  HelloSC,
  NameSC,
  DescriptionSC,
  CostSC,
  SubtitleSC,
  TextSC,
  WrapperSC,
  TournamentSC,
  ButtonSC,
});
