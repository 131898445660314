import styled from "styled-components";
import { Size } from "theme/styles/Size";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled("section")`
  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    overflow: hidden;
  }

  // @media (max-width: ${Breakpoints.MOBILE}px) {
  //   //overflow: hidden;
  //
  // }
`;

const CircularContainerSC = styled("div")`
  width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    width: ${Size.CIRCULAR_MOBILE_SIZE}px;
    height: ${Size.CIRCULAR_MOBILE_SIZE}px;
  }
  @media (max-width: ${Breakpoints.MOBILE}px) {
    //width: 190px;
    //height: 190px;
  }
`;

export const useLoadingWrapperStyles = () => ({
  ContainerSC,
  CircularContainerSC,
});
