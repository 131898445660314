import styled from "styled-components";
import { Link } from "react-router-dom";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TopContainerSC = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;

  color: #fff;

  @media (max-width: ${Breakpoints.TABLE}px) {
    display: none;
  }
`;

const ImageContainerSC = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    gap: 36px;

    img {
      &:first-child {
        width: 82px;
      }

      &:nth-child(2) {
        width: 162px;
      }
    }
  }
`;

const MainContainer = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 0 24px;

  flex-grow: 2;

  transition: all 0.4s linear;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

  @media (max-width: ${Breakpoints.TABLE}px) {
    justify-content: flex-start;
    margin-top: 10vh;
  }
`;

const LinkSC = styled(Link)`
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-transform: uppercase;

  text-align: center;
  //flex-grow: 1;
  flex-basis: 60px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 15px;
  }
`;

const NonActivateSC = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 8;

  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
`;

export const useCertificatePageStyles = () => ({
  TopContainerSC,
  ImageContainerSC,
  MainContainer,
  LinkSC,
  ContainerSC,
  NonActivateSC,
});
