import React from "react";
import { useBigTablet } from "hooks/useBreakpoints";

import { useCasesPageStyles } from "./CasesPageStyles";

import CasesPageMobile from "./CasesPageMobile/CasesPagesMobile";
import CasesPageDesktop from "./CasesPageDesktop/CasesPageDesktop";

import "swiper/css";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const CasesPage = () => {
  const isTablet = useBigTablet();

  return (
    <PageContainerSC className="mobile__container">
      <ContainerSC>
        {isTablet && <CasesPageMobile />}
        {!isTablet && <CasesPageDesktop />}
      </ContainerSC>
      <DiscusProject />
    </PageContainerSC>
  );
};

const { PageContainerSC, ContainerSC } = useCasesPageStyles();

export default React.memo(CasesPage);
