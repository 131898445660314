import styled from "styled-components";
import { ContactsDataProps } from "lib/mock/contactsData";
import { ColorScheme } from "theme/styles/colorScheme";

const ItemSC = styled("li")<
  Pick<ContactsDataProps, "isFill"> & { isActive: boolean }
>`
  width: 28px;
  height: 28px;
  margin-right: 32px;

  & > svg {
    fill: ${({ isFill, isActive }) =>
      isFill && isActive ? ColorScheme.WHITE : ""};
    & > path {
      fill: ${({ isFill, isActive }) =>
        isFill && isActive ? ColorScheme.WHITE : ""};
      stroke: ${({ isActive }) => (isActive ? ColorScheme.WHITE : undefined)};
    }
  }
`;

export const useContactsNavItemStyles = () => ({
  ItemSC,
});
