import React, { useState, useRef, useEffect } from "react";
import type { PropsWithChildren } from "react";

import { useSpring, useTransition, animated, config } from "@react-spring/web";
import { TextTransitionProps } from "UI/TextTransitionUI/types";

const TextTransitionUI = (props: PropsWithChildren<TextTransitionProps>) => {
  const {
    direction = "up",
    inline = false,
    springConfig = config.default,
    delay = 0,
    className,
    style,
    translateValue: tv = "100%",
    children,
  } = props;

  const initialRun = useRef(true);
  const fromTransform = direction === "down" ? `-${tv}` : tv;
  const leaveTransform = direction === "down" ? tv : `-${tv}`;

  const transitions = useTransition([children], {
    enter: { opacity: 1, transform: "translateY(0%)" },
    from: { opacity: 0, transform: `translateY(${fromTransform})` },
    leave: {
      opacity: 0,
      transform: `translateY(${leaveTransform})`,
      position: "absolute",
    },
    config: springConfig,
    immediate: initialRun.current,
    delay: !initialRun.current ? delay : undefined,
  });

  const [width, setWidth] = useState<number>(0);
  const currentRef = useRef<HTMLDivElement>(null);
  const heightRef = useRef<number | string>("auto");

  useEffect(() => {
    initialRun.current = false;
    const element = currentRef.current;

    // If element doesn't exist, then do nothing
    if (!element) return;

    const { width, height } = element.getBoundingClientRect();

    setWidth(width);
    heightRef.current = height;
  }, [children, setWidth, currentRef]);

  const widthTransition = useSpring({
    to: { width },
    config: springConfig,
    immediate: initialRun.current,
    delay: !initialRun.current ? delay : undefined,
  });

  return (
    <animated.div
      className={`text-transition ${className}`}
      style={{
        ...(inline && !initialRun.current ? widthTransition : undefined),
        ...style,
        whiteSpace: inline ? "nowrap" : "normal",
        display: inline ? "inline-flex" : "flex",
        height: heightRef.current,
      }}>
      {transitions((styles, item) => (
        <animated.div
          style={{ ...styles }}
          ref={item === children ? currentRef : undefined}>
          {item}
        </animated.div>
      ))}
    </animated.div>
  );
};

export const present = {
  default: {
    tension: 170,
    friction: 26,
  },
  gentle: {
    tension: 120,
    friction: 14,
  },
  wobbly: {
    tension: 180,
    friction: 12,
  },
  stiff: {
    tension: 210,
    friction: 20,
  },
  slow: {
    tension: 280,
    friction: 60,
  },
  molasses: {
    tension: 280,
    friction: 120,
  },
};

export default React.memo(TextTransitionUI);
