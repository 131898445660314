import { ApiNameSpace } from "lib/constants/enums/ApiNameSpace";

export const fetchCertificate = (key: string | undefined) => {
  return fetch(`${ApiNameSpace.CERTIFICATE}/${key}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
