import React from "react";
import CertificatePageDesktop from "routes/pages/CertificatePage/CPDesktop/CPDesktop";
import CertificatePageMobile from "routes/pages/CertificatePage/CPMobile/CPMobile";
import { UseCertificatePage } from "routes/pages/CertificatePage/useCertificatePage";

const CertificatePage = () => {
  const { isMobile, userData, isError, isLoading, isActivate } =
    UseCertificatePage();

  return (
    <>
      {!isMobile && (
        <CertificatePageDesktop
          userData={userData}
          isError={isError}
          isLoading={isLoading}
          isActivate={isActivate}
        />
      )}
      {isMobile && (
        <CertificatePageMobile
          userData={userData}
          isError={isError}
          isLoading={isLoading}
          isActivate={isActivate}
        />
      )}
    </>
  );
};

export default React.memo(CertificatePage);
