export const giftData = {
  desktop: {
    hello: "Привет",
    gift: "Мы дарим тебе сертификат на:",
  },

  mobile: {
    hello: "Привет",
    gift: "Мы дарим тебе сертификат на",
  },
};
