import styled, { css } from "styled-components";
import { Size } from "theme/styles/Size";
import { Breakpoints } from "theme/styles/breakpoints";

const CircleCSS = css`
  /* width: ${Size.CIRCULAR_SIZE}px;
  height: ${Size.CIRCULAR_SIZE}px; */
`;

// ? Родион, я не понял тут по поводу position. ты хотел POSITON НАПИСАТЬ ИЛИ ВСЁ ТАКИ POSITION
const ContainerSC = styled("div")`
  box-sizing: border-box;

  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  user-select: none;

  height: 100%;
  width: 100%;
  /* height: 80%; */

  ${CircleCSS}

  /* margin-bottom: 147px; */
  /* margin-top: 50px; */

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    /* margin-bottom: 136px; */
  }
`;

const SvgContainerCS = styled("div")`
  position: absolute;

  width: 324px;
  height: 324px;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    //width: 264px;
    //height: 264px;
    width: 255px;
    height: 255px;
  }
`;

const ParagraphSC = styled("p")`
  margin-top: 30px;
  text-align: center;
  max-width: 106px;
  font-weight: 600;
  font-style: normal;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    width: 80px;
    margin: 6px auto 18px;

    font-size: 13px;
    line-height: 17px;
  }
`;

const ContainerCloudSC = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    transform: scale(0.8);
  }
`;

const ParagraphVP = styled("p")`
  /* margin-top: 30px; */
  text-align: center;
  max-width: 277px;
  text-transform: uppercase;
`;

export const useVacanciesFigureStyles = () => ({
  ContainerSC,
  ParagraphSC,
  SvgContainerCS,
  ParagraphVP,
  ContainerCloudSC,
});
