import styled from "styled-components";

const ListSC = styled.ul`
  display: flex;
  max-width: 130px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const IconContainerSC = styled.div<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    path {
      stroke: ${({ isActive }) => (isActive ? "#fff" : "#3d3d3d")};
    }
  }
`;

const LiSC = styled.li`
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;

  max-width: 320px;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const useGPDNMobileStyles = () => ({
  LiSC,
  IconContainerSC,
  ListSC,
});
