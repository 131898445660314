import React from "react";
import PartnersItem from "./PartnersItem/PartnersItem";
import { usePartnersStyle } from "./Partners.styles";
import { usePartnersPage } from "./usePartnersPage";
import { useSpring } from "@react-spring/web";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const PartnersPage = () => {
  const { partnerData } = usePartnersPage();

  const lis = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 800,
    },
  });

  return (
    <ContainerSC className="mobile__container">
      <ListSC style={lis}>
        {partnerData.map((partnersItem) => (
          <PartnersItem key={partnersItem.title} {...partnersItem} />
        ))}
      </ListSC>
      <DiscusProject />
    </ContainerSC>
  );
};

const { ContainerSC, ListSC } = usePartnersStyle();

export default React.memo(PartnersPage);
