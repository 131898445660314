import styled, { css } from "styled-components";
import { ZIndex } from "theme/styles/zIndex";
import { ColorScheme } from "theme/styles/colorScheme";
import ForceIcon from "assets/icon/ForceIcon.svg";
import { Breakpoints } from "theme/styles/breakpoints";

const ContainerSC = styled.div<{ isView: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  opacity: ${({ isView }) => (isView ? 1 : 0)};
  z-index: ${({ isView }) => (isView ? ZIndex.FULLSCREEN : ZIndex.HIDDEN)};

  align-items: center;
  justify-content: center;

  color: ${ColorScheme.WHITE};
  font-family: Montserrat, sans-serif;

  transition: all 0.3s ease-in;
`;

const WrapperSC = styled.div`
  width: 100%;
  max-width: 472px;

  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    padding: 0 16px;
  }
`;

const TitleSC = styled.h6`
  font-size: 30px;
  font-weight: 600;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 24px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 22px;
  }
`;

const TopContainerSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
`;

const ButtonSC = styled.button`
  @media (max-width: ${Breakpoints.TABLE}px) {
    position: absolute;
    top: 22px;
    right: 22px;
  }
`;

const SubtitleSC = styled.p`
  font-size: 16px;
  font-weight: 500;

  margin-bottom: 24px;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 14px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 13px;
  }
`;

const FormSC = styled.form`
  display: flex;
  flex-direction: column;

  gap: 24px;
`;

const InputContainerSC = styled.div`
  position: relative;
  border-bottom: 1px solid ${ColorScheme.GRAY};
`;

const FocusSpanSC = styled.div`
  width: 0;
  height: 0;

  position: absolute;
  bottom: -1px;
  left: 0;

  background: ${ColorScheme.WHITE};
  transition: width 0.5s;
`;

const InputSC = styled.input`
  width: 100%;

  background: none !important;
  border: none;
  outline: none;

  padding-bottom: 4px;

  font-size: 16px;
  color: ${ColorScheme.WHITE};

  &::placeholder {
    color: ${ColorScheme.GRAY_THREE};
    font-size: 15px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 14px;
  }

  &:focus + ${FocusSpanSC} {
    width: 100%;
    height: 2px;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 14px;
  }
`;

const SubmitButtonSC = styled.button`
  width: fit-content;
  align-self: end;

  position: relative;

  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  &:after {
    margin-left: 10px;
    height: 12px;
    width: 12px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    content: "";

    background: url(${ForceIcon});
    transform: translate(0, -50%);
  }

  &:disabled {
    opacity: 0.5;
  }

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: 15px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    font-size: 14px;
  }
`;

const ImgSC = styled.img`
  margin: 0 auto 6px;

  width: 172px;
  height: 172px;
`;

const SuccessSubtitle = styled.p`
  color: ${ColorScheme.WHITE};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;

const SpanSC = styled.span`
  text-decoration: underline;
`;

const TextSC = styled.p`
  padding-bottom: 30px;

  max-width: 60%;

  flex-basis: 100px;

  color: ${ColorScheme.GRAY_THREE};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
`;

const ErrorMessageSC = styled.span`
  height: 14px;

  display: block;

  color: ${ColorScheme.RED_ERROR};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

const LinkSC = styled.a`
  margin-bottom: 16px;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
`;

const InfoButtonSC = styled.button`
  width: 100%;

  position: absolute;
  right: 50%;
  bottom: 40px;

  transform: translate(50%);

  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
`;

const InfoButtonTopSC = styled.button<{ isRight?: boolean; isLeft?: boolean }>`
  position: absolute;
  top: 22px;

  ${(props) =>
    (props.isRight || props.isLeft) &&
    css`
      ${props.isRight &&
      css`
        right: 22px;
      `}

      ${props.isLeft &&
      css`
        left: 22px;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 24px;
        }
      `}
    `}
`;

export const usePopupFormStyles = () => ({
  ContainerSC,
  TitleSC,
  ButtonSC,
  TopContainerSC,
  FormSC,
  WrapperSC,
  SubtitleSC,
  InputContainerSC,
  InputSC,
  SubmitButtonSC,
  ImgSC,
  SuccessSubtitle,
  SpanSC,
  TextSC,
  LinkSC,
  FocusSpanSC,
  ErrorMessageSC,
  InfoButtonSC,
  InfoButtonTopSC,
});
