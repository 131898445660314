import React from "react";
import { useGPMToggleInfo } from "routes/pages/GiftPage/GiftPageMobile/GPMToggleInfo/GPMToggleInfo.styles";
import { formatNumber } from "lib/services/services";
import { giftData } from "lib/mock/giftData";
import { infoData } from "lib/mock/infoData";

interface GpmToggleInfoProps {
  cost: number;
  selectedIcon: string;
}

const GPMToggleInfo = ({ cost, selectedIcon }: GpmToggleInfoProps) => (
  <WrapperSC>
    {selectedIcon === "Cost" && (
      <>
        <HelloSC>{giftData.mobile.hello}</HelloSC>
        <SubtitleSC>{giftData.mobile.gift}</SubtitleSC>
        <CostSC>{formatNumber(cost)} ₽</CostSC>
      </>
    )}
    {selectedIcon === "Phone" && (
      <ContainerNumbersSC>
        <LinkSC href={infoData.tel.first.num}>
          {infoData.tel.first.display}
        </LinkSC>
        <LinkSC href={infoData.tel.second.num}>
          {infoData.tel.second.display}
        </LinkSC>
      </ContainerNumbersSC>
    )}
    {selectedIcon === "Email" && (
      <LinkSC href={infoData.email.mail_to}>{infoData.email.display}</LinkSC>
    )}
    {selectedIcon === "Geo" && (
      <LinkSC>
        {infoData.geo.display.office}
        <br />
        {infoData.geo.display.city}
      </LinkSC>
    )}
  </WrapperSC>
);

const { WrapperSC, SubtitleSC, LinkSC, CostSC, HelloSC, ContainerNumbersSC } =
  useGPMToggleInfo();

export default React.memo(GPMToggleInfo);
