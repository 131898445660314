import React from "react";
import { usePricePageStyles } from "routes/pages/PricePage/PricePage.styles";
import { useBigTablet } from "hooks/useBreakpoints";
import PricePageMobile from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CalculatedPageMobile";
import CalculatedPageDesktop from "routes/pages/PricePage/CalculatedPage/CalculatedPageDesktop/CalculatedPageDesktop";

const CalculatedPage = () => {
  const isMobile = useBigTablet();

  return (
    <ContainerSC>
      {!isMobile && <CalculatedPageDesktop />}
      {isMobile && <PricePageMobile />}
    </ContainerSC>
  );
};

const { ContainerSC } = usePricePageStyles();

export default React.memo(CalculatedPage);
