import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const FootersSC = styled("footer")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px 28px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    flex-direction: column-reverse;

    padding: 0 15px 15px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    flex-direction: column-reverse;

    padding: 0 15px 5px;
    transform: scale(0.8);
  }
`;

const LeftContainerSC = styled("p")`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    margin-top: 25px;
  }

  @media (max-width: ${Breakpoints.MOBILE}px) {
    margin-top: 14px;
  }
`;

const IconsContainerSC = styled("nav")``;

const ListSC = styled("ul")`
  display: flex;
  align-items: center;

  & > *:last-child {
    padding-right: 21px;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    & > *:last-child {
      padding-right: 0;
    }
  }
`;

export const useFooterStyles = () => ({
  FootersSC,
  LeftContainerSC,
  IconsContainerSC,
  ListSC,
});
