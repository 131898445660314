import React from "react";
import { usePartnersItemStyle } from "./PartnersItem.styles";
import { PartnersModel } from "lib/models/PartnersModel";

const PartnersItem = ({ title, Icon }: PartnersModel) => {
  return (
    <PartnersElemSC>
      <Icon />
      <PartnersTextSC>{title}</PartnersTextSC>
    </PartnersElemSC>
  );
};

const { PartnersElemSC, PartnersTextSC } = usePartnersItemStyle();

export default PartnersItem;
