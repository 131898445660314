export enum Size {
  NULL = 0,
  CURSOR = 50,
  CURSOR_ACTIVE = 61,
  CIRCULAR_SIZE = 221,
  CIRCULAR_MOBILE_SIZE = 176,
  POSITION = 55,
  POSITION_TABLET = 100,

  HEADER_ITEM = 38,
  HEADER_ITEM_ACTIVE = 80,

  EMAIL_LENGTH = 256,
}
