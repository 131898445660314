import React from "react";
import { useDPMtStyles } from "routes/pages/PricePage/CalculatedPage/DisscusProject/DisscusProjectMobile/DPMStyles.styles";
import { popupStore } from "store/popupStore/popupStore";
import { useBigMobile } from "hooks/useBreakpoints";
import { ReactComponent as EIcon } from "assets/icon/EChatIcon.svg";

export interface DiscusProjectMobile {
  config?: {
    pos: string;
    top: string;
    right: string;
  };
}

const DiscusProjectMobile = ({ config }: DiscusProjectMobile) => {
  const onClick = popupStore((state) => state.changeToggleViewPopup);
  const isMobile = useBigMobile();

  return (
    <ContainerSC config={config} isMobile={isMobile}>
      <ButtonSC isMobile={isMobile} onClick={onClick}>
        {!isMobile && <>Заказать проект</>}
        {isMobile && (
          <>
            <EIcon />
          </>
        )}
      </ButtonSC>
    </ContainerSC>
  );
};

const { ContainerSC, ButtonSC } = useDPMtStyles();

export default React.memo(DiscusProjectMobile);
