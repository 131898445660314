import React, { useEffect } from "react";
import { ErrorPageStyles } from "./ErrorPage.style";
import { useNavigate } from "react-router-dom";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";

const IndexPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(PagesNamespace.INDEX);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ContainerSC className="mobile__container">
      <TextSC>Ой... что-то пошло не так</TextSC>
    </ContainerSC>
  );
};

const { ContainerSC, TextSC } = ErrorPageStyles();

export default React.memo(IndexPage);
