import React from "react";
import { useVacanciesPageStyles } from "./VacanciesPage.styles";
import LoadingWrapper from "components/Wrappers/LoadingWrapper/LoadingWrapper";
import { useVacanciesPage } from "./useVacanciesPage";
import VacanciesFigure from "./VacanciesFigure/VacanciesFigure";
import { useBigMobile } from "hooks/useBreakpoints";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const VacanciesPage = () => {
  const { opacity } = useVacanciesPage();

  const isMobile = useBigMobile();

  return (
    <LoadingWrapper>
      <ContainerSC style={{ ...opacity }}>
        <WrapperSC>
          {!isMobile && (
            <ParagraphSC>
              Если Вас заинтересовала наша компания и Вы бы хотели работать с
              нами, отправьте нам свое резюме и мы непременно рассмотрим его
            </ParagraphSC>
          )}
          {isMobile && (
            <ParagraphSC>
              отправьте нам свое резюме и мы непременно его рассмотрим
            </ParagraphSC>
          )}
          <VacanciesFigure />
        </WrapperSC>
      </ContainerSC>
      <DiscusProject />
    </LoadingWrapper>
  );
};

const { ContainerSC, ParagraphSC, WrapperSC } = useVacanciesPageStyles();

export default React.memo(VacanciesPage);
