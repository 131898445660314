import React from "react";
import { useGiftPageStyles } from "routes/pages/GiftPage/GitfPageDesktop/useGPD.styles";
import { ReactComponent as LogoIndexImg } from "assets/icon/logoIndexCertificate.svg";
import CPDNDesktop from "routes/pages/CertificatePage/CPDNavbar/CPDNDesktop/CPDNDesktop";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";
import { formatNumber } from "lib/services/services";
import { giftData } from "lib/mock/giftData";

interface GiftPageDesktopProps {
  costData: number;
  isLoading: boolean;
}

const GiftPageDesktop = ({ costData, isLoading }: GiftPageDesktopProps) => (
  <>
    <ContainerSC isLoading={isLoading}>
      <TopContainerSC>
        <ImageContainerSC>
          <LogoIndexImg />
        </ImageContainerSC>
      </TopContainerSC>
      <MainContainer>
        <TextContainerSC>
          <HelloSC>{giftData.desktop.hello}</HelloSC>
          <SubtitleSC>{giftData.desktop.gift}</SubtitleSC>
          <PriceSC>{formatNumber(costData)} ₽</PriceSC>
          <TextSC>
            * Условия использования сертификата: Сертификат предоставляет скидку
            в размере 10% от итоговой стоимости, но не более {costData} ₽;
            Каждый человек может получить только один сертификат, который может
            быть использован лишь однократно. Сертификаты не подлежат обмену на
            денежный эквивалент; Сертификат не может быть суммирован с другими
            сертификатами; Сертификат действителен в течение 1 месяца. По
            истечении указанного срока неиспользованный сертификат аннулируется.
            Предложение не является публичной офертой.
          </TextSC>
        </TextContainerSC>
        <CPDNDesktop />
      </MainContainer>
      <LinkSC to={PagesNamespace.INDEX}>на главную</LinkSC>
    </ContainerSC>
  </>
);

const {
  TopContainerSC,
  ContainerSC,
  ImageContainerSC,
  LinkSC,
  MainContainer,
  HelloSC,
  SubtitleSC,
  PriceSC,
  TextSC,
  TextContainerSC,
} = useGiftPageStyles();

export default React.memo(GiftPageDesktop);
