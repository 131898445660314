export const handleLogVacancies = () => {
  // eslint-disable-next-line no-console
  console.log(
    "\n" +
      "██╗███╗   ██╗██████╗ ███████╗██╗  ██╗\n" +
      "██║████╗  ██║██╔══██╗██╔════╝╚██╗██╔╝\n" +
      "██║██╔██╗ ██║██║  ██║█████╗   ╚███╔╝ \n" +
      "██║██║╚██╗██║██║  ██║██╔══╝   ██╔██╗ \n" +
      "██║██║ ╚████║██████╔╝███████╗██╔╝ ██╗\n" +
      "╚═╝╚═╝  ╚═══╝╚═════╝ ╚══════╝╚═╝  ╚═╝\n" +
      "                                     \n",
    "Мы нанимаем!",
  );
};
