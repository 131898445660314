import { useEffect, useState } from "react";
import { Timing } from "theme/styles/timing";

export const useLoadingWrapper = () => {
  const [progress, setProgress] = useState<number>(0);
  const [isChildren, setIsChildren] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setProgress(100);
    });
    setTimeout(() => {
      setIsChildren(true);
    }, Timing.CIRCULAR_PROGRESS_TIMING_2);
    return () => {
      setProgress(0);
      setIsChildren(false);
    };
  }, []);

  return {
    progress,
    isChildren,
  };
};
