import { useState } from "react";

export const useGiftPageMobile = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<string>("Cost");

  const handleIconClick = (iconName: string) => {
    if (selectedIcon === iconName) {
      setSelectedIcon("Cost");
    } else {
      setSelectedIcon(iconName);
    }
  };

  const handleTogglePopup = () => {
    setIsOpenPopup(!isOpenPopup);
  };

  return { selectedIcon, isOpenPopup, handleIconClick, handleTogglePopup };
};
