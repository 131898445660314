import React from "react";
import { ContactsDataProps } from "lib/mock/contactsData";
import { useLocation, useNavigate } from "react-router-dom";
import { useContactsNavItemStyles } from "./ContactsNavItem.styles";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

const ContactsNavItem = ({ Icon, link, isFill }: ContactsDataProps) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const { changeMouseProps } = changeCursorActive();

  return (
    <ItemSC
      isFill={isFill}
      isActive={location === link}
      onClick={() => navigate(link)}
      {...changeMouseProps}>
      <Icon />
    </ItemSC>
  );
};

const { ItemSC } = useContactsNavItemStyles();

export default React.memo(ContactsNavItem);
