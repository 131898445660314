import React from "react";
import { IChildren } from "types/types";
import { useLoadingWrapper } from "components/Wrappers/LoadingWrapper/useLoadingWrapper";
import { useLoadingWrapperStyles } from "components/Wrappers/LoadingWrapper/LoadingWrapper.styles";
import CircularProgressbarUI from "UI/CircularProgressbarUI/CircularProgressbarUI";

const LoadingWrapper = ({ children }: IChildren) => {
  const { progress, isChildren } = useLoadingWrapper();

  return (
    <ContainerSC className="mobile__container">
      {!isChildren && (
        <CircularContainerSC>
          <CircularProgressbarUI value={progress} />
        </CircularContainerSC>
      )}
      {isChildren && children}
    </ContainerSC>
  );
};

const { ContainerSC, CircularContainerSC } = useLoadingWrapperStyles();

export default React.memo(LoadingWrapper);
