import styled from "styled-components";
import { animated } from "@react-spring/web";
import { ZIndex } from "theme/styles/zIndex";
import { ColorScheme } from "theme/styles/colorScheme";

const BottomContainerSC = styled(animated.button)`
  height: 50px;
  border-radius: 50%;
  background-color: ${ColorScheme.BLACK};

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  bottom: -25px;
  transform: translateX(-50%);
  z-index: ${ZIndex.UPPER_THREE};
`;

const BottomTextSC = styled(animated.p)`
  opacity: 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  user-select: none;

  color: ${ColorScheme.WHITE};
`;

export const useCircularContentBottomStyles = () => ({
  BottomContainerSC,
  BottomTextSC,
});
