import React, { useState } from "react";
import { createPortal } from "react-dom";
import { usePopupFormStyles } from "components/DiscussProjectForm/DiscussProjectForm.styles";
import { ReactComponent as CloseIcon } from "assets/icon/CloseIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icon/SwiperArrowLeft.svg";
import { useDiscussProjectForm } from "components/DiscussProjectForm/useDiscussProjectForm";
import SuccessForm from "components/DiscussProjectForm/SuccesForm/SuccessForm";
import { lengthInputConstraints } from "lib/constants/enums/InputLength";
import styled from "styled-components";
import { certificateCondData } from "lib/mock/certificateCondData";
import { ColorScheme } from "theme/styles/colorScheme";
import { formFeedbackMock } from "lib/mock/formFeedbackMock";

const DFFormMobile = () => {
  const {
    respData,
    isView,
    changeMouseProps,
    values,
    errors,

    handleChange,
    onClickExit,
    handleWrapperClick,
    handleSubmit,
  } = useDiscussProjectForm();

  const [isVisibleInfo, setIsVisibleInfo] = useState(false);

  return createPortal(
    <ContainerSC isView={isView} onClick={onClickExit}>
      <WrapperSC onClick={handleWrapperClick}>
        {!respData.url && (
          <>
            {!isVisibleInfo && (
              <>
                <TopContainerSC>
                  <TitleSC>{formFeedbackMock.title}</TitleSC>
                  <ButtonSC type={"button"} onClick={onClickExit}>
                    <CloseIcon />
                  </ButtonSC>
                </TopContainerSC>
                <SubtitleSC>{formFeedbackMock.subtitle}</SubtitleSC>
                <FormSC onSubmit={handleSubmit} noValidate={true}>
                  <div>
                    <InputContainerSC>
                      <InputSC
                        onChange={handleChange}
                        value={values.name || ""}
                        name={"name"}
                        autoComplete={"off"}
                        minLength={lengthInputConstraints.name}
                        placeholder={"Ваше имя *"}
                        required
                      />
                      <FocusSpanSC />
                    </InputContainerSC>
                    <ErrorMessageSC>{errors.name}</ErrorMessageSC>
                  </div>

                  <div>
                    <InputContainerSC>
                      <InputSC
                        onChange={handleChange}
                        value={values.phone || ""}
                        type={"tel"}
                        name={"phone"}
                        autoComplete={"off"}
                        minLength={lengthInputConstraints.phone}
                        placeholder={"Телефон *"}
                        required
                      />
                      <FocusSpanSC />
                    </InputContainerSC>
                    <ErrorMessageSC>{errors.phone}</ErrorMessageSC>
                  </div>

                  <InputContainerSC>
                    <InputSC
                      onChange={handleChange}
                      value={values.message || ""}
                      autoComplete={"off"}
                      name={"message"}
                      placeholder={"Расскажите об идее"}
                    />
                    <FocusSpanSC />
                  </InputContainerSC>

                  <SubmitButtonSC {...changeMouseProps} type={"submit"}>
                    {formFeedbackMock.send}
                  </SubmitButtonSC>
                </FormSC>
                <InfoButtonSC onClick={() => setIsVisibleInfo(true)}>
                  {formFeedbackMock.info}
                </InfoButtonSC>
              </>
            )}
            {isVisibleInfo && (
              <>
                <InfoButtonTopSC isLeft onClick={() => setIsVisibleInfo(false)}>
                  <ArrowLeftIcon />
                </InfoButtonTopSC>
                <InfoButtonTopSC
                  isRight
                  onClick={() => {
                    onClickExit();
                    setTimeout(() => {
                      setIsVisibleInfo(false);
                    }, 300);
                  }}>
                  <CloseIcon />
                </InfoButtonTopSC>
                <ListSC>
                  {certificateCondData.map((item, index) => (
                    <li key={index}>
                      <SubtitleSC
                        style={{
                          textAlign: "center",
                          color: ColorScheme.GRAY_THREE,
                        }}>
                        {item}
                      </SubtitleSC>
                    </li>
                  ))}
                </ListSC>
              </>
            )}
          </>
        )}
        {respData.url && (
          <SuccessForm respData={respData} onClickExit={onClickExit} />
        )}
      </WrapperSC>
    </ContainerSC>,
    document.querySelector("#root") as HTMLDivElement,
  );
};

const ListSC = styled.ul`
  display: flex;
  flex-direction: column;
`;

const {
  ContainerSC,
  WrapperSC,
  FormSC,
  SubtitleSC,
  TitleSC,
  ButtonSC,
  TopContainerSC,
  InputContainerSC,
  InputSC,
  SubmitButtonSC,
  FocusSpanSC,
  ErrorMessageSC,
  InfoButtonSC,
  InfoButtonTopSC,
} = usePopupFormStyles();

export default React.memo(DFFormMobile);
