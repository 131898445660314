import React from "react";

import AboutPageMobile from "./AboutPageMobile/AboutPageMobile";
import styled from "styled-components";
import AboutDesktop from "./AboutDesktop/AboutDesktop";
import { useBigTablet } from "../../../hooks/useBreakpoints";

const AboutPage = () => {
  const isTablet = useBigTablet();

  return (
    <ContainerSC className="mobile__container">
      {!isTablet && <AboutDesktop />}
      {isTablet && <AboutPageMobile />}
    </ContainerSC>
  );
};

const ContainerSC = styled("section")`
  height: 100%;
  width: 100%;
  //max-width: 1920px;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default React.memo(AboutPage);
