import React from "react";
import MarqueeUI from "UI/MarqueeUI/MarqueeUI";
import { aboutData } from "lib/mock/aboutData";
import styled from "styled-components";
import AnimatedTextUI from "UI/AnimatedTextUI/AnimatedText";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";
const AboutDesktop = () => {
  return (
    <ContainerSC>
      <MarqueeUI data={aboutData.top} direction="right" />
      <DescSC>
        <AnimatedTextUI variant={"text"} text={aboutData.paragraph} />
      </DescSC>
      <MarqueeUI data={aboutData.bottom} />
      <DiscusProject />
    </ContainerSC>
  );
};

const DescSC = styled("div")`
  max-width: 804px;
  margin: 50px auto;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;

  white-space: pre-wrap;
`;

const ContainerSC = styled.div`
  max-width: 2000px;
`;

export default React.memo(AboutDesktop);
