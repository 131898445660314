import React from "react";
import { FooterItemType } from "lib/mock/footerData";
import styled from "styled-components";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { Breakpoints } from "theme/styles/breakpoints";

const FooterItem = ({ Icon, link }: FooterItemType) => {
  const { changeMouseProps } = changeCursorActive();

  return (
    <LiSC {...changeMouseProps}>
      <LinkSC href={link} target="_blank" rel="noopener noreferrer">
        <Icon />
      </LinkSC>
    </LiSC>
  );
};

const LiSC = styled.li`
  padding-right: 24px;

  &:last-child {
    padding-right: 0;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    &:last-child {
      padding-right: 0;
    }
  }
`;

const LinkSC = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;

  // ? зачем высота?
  /* height: 50px; */

  @media (max-width: ${Breakpoints.TABLE}px) {
    svg {
      width: 35px;
      height: 35px;
    }
  }
`;

export default React.memo(FooterItem);
