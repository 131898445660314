import styled from "styled-components";
import { ReactComponent as CursorIcon } from "assets/icon/CursorIcon.svg";
import { ZIndex } from "theme/styles/zIndex";
import { motion } from "framer-motion";
import { Size } from "theme/styles/Size";
import { ICursorStoreState } from "store/cursorStore/types";

const ContainerSC = styled(motion.div)<Pick<ICursorStoreState, "isActive">>`
  width: ${({ isActive }) => (isActive ? Size.CURSOR_ACTIVE : Size.CURSOR)}px;
  height: ${({ isActive }) => (isActive ? Size.CURSOR_ACTIVE : Size.CURSOR)}px;

  position: absolute;
  z-index: ${ZIndex.CURSOR};

  pointer-events: none;
  user-select: none;
  transition: 0.2s width linear, 0.2s height linear;

  will-change: contents, transition, width, height;
`;

const IconContainerSC = styled("div")`
  width: 15px;
  height: 15px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  will-change: contents, transition;
`;

const CursorIconSC = styled(CursorIcon)`
  width: inherit;
  height: inherit;
  position: absolute;
`;

export const useCursorStyles = () => ({
  ContainerSC,
  CursorIconSC,
  IconContainerSC,
});
