import { useEffect, useState, useRef } from "react";
import { PartnersModel, PartnersNamesArr } from "lib/models/PartnersModel";
import { partners, partnersArray } from "lib/mock/partnersData";
import { Timing } from "theme/styles/timing";
import { useTablet } from "hooks/useBreakpoints";

type ObjectMixPatterns = {
  [key in string]: PartnersNamesArr;
};

const desktopMixPartners: Readonly<ObjectMixPatterns> = {
  firstPattern: ["MIDIS", "YURGU", "TOP", "CHELGU"],
  secondPattern: ["CHELGU", "YURGU", "TOP", "MIDIS"],
  threePattern: ["CHELGU", "MIDIS", "TOP", "YURGU"],
  fourPattern: ["TOP", "MIDIS", "CHELGU", "YURGU"],
};

const mobileMixPartners: Readonly<ObjectMixPatterns> = {
  firstPattern: ["YURGU", "CHELGU"],
  secondPattern: ["TOP", "YURGU"],
  threePattern: ["MIDIS", "TOP"],
  fourPattern: ["CHELGU", "MIDIS"],
  fivePattern: ["TOP", "CHELGU"],
};

export const usePartnersPage = () => {
  const [partnerData, setPartnerData] =
    useState<PartnersModel[]>(partnersArray);
  const isTablet = useTablet();
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  // ? рекурсивно проходим по значениям партнёров которые передали
  const partnersMix = (patterns: PartnersNamesArr[], index = 0) => {
    const currentPattern = patterns[index];
    setPartnerData(currentPattern.map((item) => partners[item]));

    timeoutRef.current = setTimeout(() => {
      if (index === patterns.length - 1) {
        partnersMix(patterns, 0);
      } else {
        partnersMix(patterns, index + 1);
      }
    }, Timing.PARTNER_CHANGE);
  };

  // ? запускаем функцию стартер
  useEffect(() => {
    const mixPatterns = isTablet ? mobileMixPartners : desktopMixPartners;
    clearTimeout(timeoutRef.current);
    partnersMix(Object.values(mixPatterns));

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isTablet]);

  return {
    partnerData,
  };
};
