import React from "react";
import { useCertificatePageStyles } from "routes/pages/CertificatePage/CertificatePage.styles";
import CPTimer from "routes/pages/CertificatePage/CPTimer/CPTimer";
import CPUser from "routes/pages/CertificatePage/CPUser/CPUser";
import CPDNavbar from "routes/pages/CertificatePage/CPDNavbar/CPDNavbar";
import ErrorPage from "routes/pages/ErrorPage/ErrorPage";
import { UserData } from "routes/pages/CertificatePage/types";
import { ReactComponent as PartnerImg } from "assets/icon/PartnerCertificate.svg";
import { ReactComponent as LogoIndexImg } from "assets/icon/logoIndexCertificate.svg";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";

interface CertificatePageDesktopProps {
  userData: UserData;
  isError: boolean;
  isLoading: boolean;
  isActivate: boolean;
}

const CertificatePageDesktop = ({
  userData,
  isError,
  isLoading,
  isActivate,
}: CertificatePageDesktopProps) => {
  return (
    <>
      {!isError && isActivate && (
        <ContainerSC>
          <TopContainerSC>
            <ImageContainerSC>
              <PartnerImg />
              <LogoIndexImg />
            </ImageContainerSC>
            <CPTimer time={userData.activate_at} />
          </TopContainerSC>
          <MainContainer isLoading={isLoading}>
            <CPUser userData={userData} />
            <CPDNavbar />
          </MainContainer>
          <LinkSC to={"/"}>на главную</LinkSC>
        </ContainerSC>
      )}
      {isError && <ErrorPage />}
      {!isActivate && !isError && (
        <MainContainer style={{ height: "100%" }}>
          <NonActivateSC>Сертификат пока не активирован</NonActivateSC>
          <LinkSC to={PagesNamespace.INDEX}>на главную</LinkSC>
        </MainContainer>
      )}
    </>
  );
};

const {
  TopContainerSC,
  NonActivateSC,
  ContainerSC,
  LinkSC,
  MainContainer,
  ImageContainerSC,
} = useCertificatePageStyles();

export default React.memo(CertificatePageDesktop);
