import { Swiper } from "swiper/react";
import { Autoplay } from "swiper";

import FlipUI from "UI/FlipUI/FlipUI";
import { Timing } from "theme/styles/timing";

import { useCasesPageDesktopStyles } from "./CasesPageDesktopStyles";
import CasesSwiperNav from "./CasesSwiperNav/CasesSwiperNav";

import { casesData } from "lib/mock/casesData";

import { useCasesPageDesktop } from "./useCasesPageDesktop";

const CasesPageDesktop = () => {
  const {
    activeData,
    activeIndex,
    handleChangeActiveIndex,
    handleChangeAutoplay,
    handleChangeHover,
    handleChangeSwiper,
    handleNextSlide,
    handlePrevSlide,
    hoverItem,
  } = useCasesPageDesktop();

  return (
    <>
      <WrapperSC>
        <Swiper
          loop={true}
          slidesPerView="auto"
          onSwiper={handleChangeSwiper}
          autoplay={{
            delay: Timing.SWIPER_AUTOPLAY,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}>
          {casesData.map((casesItem, index) => (
            <SwiperSlideSC key={casesItem.title}>
              <FlipUI
                isActive={index === activeIndex}
                {...casesItem}
                onClick={handleChangeActiveIndex(index)}
                handleChangeAutoplay={handleChangeAutoplay}
                handleChangeHover={handleChangeHover}
              />
            </SwiperSlideSC>
          ))}
        </Swiper>
      </WrapperSC>
      <CasesSwiperNav
        hoverItem={hoverItem}
        activeData={activeData}
        handleNextSlide={handleNextSlide}
        handlePrevSLide={handlePrevSlide}
      />
    </>
  );
};

const { SwiperSlideSC, WrapperSC } = useCasesPageDesktopStyles();

export default CasesPageDesktop;
