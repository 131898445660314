import { v4 as uuidv4 } from "uuid";
import { getHeaderInfinity } from "lib/mock/headerData";

export const getEnv = (key: string) => process.env[`REACT_APP_${key}`] || "";

export const generateUUID = uuidv4;

export const getCopyObj = <T>(obj: object) =>
  JSON.parse(JSON.stringify(obj)) as T;

export const telephoneMask = (state: string) => {
  let inputNumbersValue = state.replace(/[\D]+/g, "");

  let formattedInputValue = "";

  /*! Если пустая строка */
  if (!inputNumbersValue) {
    return "";
  }
  if (inputNumbersValue[0].match(/[1-6, 9]/))
    inputNumbersValue = "7" + inputNumbersValue;
  formattedInputValue = "+7 ";
  if (inputNumbersValue.length > 1) {
    formattedInputValue += "" + inputNumbersValue.substring(1, 4);
  }
  if (inputNumbersValue.length >= 5) {
    formattedInputValue += " " + inputNumbersValue.substring(4, 7);
  }
  if (inputNumbersValue.length >= 8) {
    formattedInputValue += " " + inputNumbersValue.substring(7, 9);
  }
  if (inputNumbersValue.length >= 10) {
    formattedInputValue += " " + inputNumbersValue.substring(9, 11);
  }

  if (state.length >= 0 && state.length <= 18) {
    return formattedInputValue;
  }
  return formattedInputValue;
};

export const getHeaderInitialRouteIndex = () => {
  const pathArr = window.location.pathname.split("/");
  const pathLast = pathArr.length > 2 ? 2 : 1;

  let path = `/${pathArr[pathArr.length - pathLast]}`;

  if (!path) {
    path = "/";
  }

  const index = getHeaderInfinity().findIndex((item) => item.href === path);

  return index >= 0 ? index : 0;
};

export const formatNumber = (number: number): string => {
  return number.toLocaleString("ru-RU");
};
