import React from "react";
import { useContactsNavStyles } from "./ContactsNav.styles";
import { contactsData } from "lib/mock/contactsData";
import ContactsNavItem from "./ContactsNavItem/ContactsNavItem";

const ContactsNav = () => {
  return (
    <ContainerSC>
      <ListSC>
        {contactsData.map((item) => (
          <ContactsNavItem key={item.link} {...item} />
        ))}
      </ListSC>
    </ContainerSC>
  );
};

const { ContainerSC, ListSC } = useContactsNavStyles();
export default React.memo(ContactsNav);
