import CasesSwiperSocial from "../../../CasesSwiperSocial/CasesSwiperSocial";
import styled from "styled-components";
import { ZIndex } from "theme/styles/zIndex";

interface FlipBackProps {
  url?: string;
  behance?: string;
  android?: string;
  apple?: string;
  rustore?: string;
  download?: { type: "download"; url: string };
  imageUrl: string;
  title: string;
}

const FlipBackSC = ({
  url,
  behance,
  android,
  apple,
  download,
  imageUrl,
  rustore,
  title,
}: FlipBackProps) => {
  return (
    <BackContent>
      <ImageSC src={imageUrl} alt={title} />
      <ContainerSC>
        <CasesSwiperSocial
          url={url}
          behance={behance}
          android={android}
          rustore={rustore}
          apple={apple}
          download={download}
        />
      </ContainerSC>
    </BackContent>
  );
};

const BackContent = styled.div`
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);

  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: rotateY(180deg);

  background-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: ${ZIndex.ELEMENT};
`;

const ImageSC = styled.img`
  width: 100%;
  height: 100%;
`;

const ContainerSC = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.7);
`;
export default FlipBackSC;
