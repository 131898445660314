// eslint-disable-next-line
type ResolverFunction = (...args: any[]) => any;

// eslint-disable-next-line
type MemoizedFunction = ((...args: any[]) => any) & { cache: Map<any, any> };

class MapCache extends Map {
  // You can add any customizations to the MapCache class here, if needed.
}

const FUNC_ERROR_TEXT = "Invalid function or resolver argument.";

export function memoize(
  // eslint-disable-next-line
  func: (...args: any[]) => any,
  resolver?: ResolverFunction | null,
): MemoizedFunction {
  if (
    typeof func !== "function" ||
    (resolver !== undefined &&
      resolver !== null &&
      typeof resolver !== "function")
  ) {
    throw new TypeError(FUNC_ERROR_TEXT);
  }

  // eslint-disable-next-line
  const memoized: MemoizedFunction = function (this: any, ...args) {
    const key = resolver ? resolver.apply(this, args) : args[0];
    const cache = memoized.cache;

    if (cache.has(key)) {
      return cache.get(key);
    }

    const result = func.apply(this, args);
    memoized.cache = cache.set(key, result) || cache;
    return result;
  };

  memoized.cache = new (memoize.Cache || MapCache)();
  return memoized;
}

// Expose `MapCache`.
memoize.Cache = MapCache;
