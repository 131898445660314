import { ReactComponent as EmailIcon } from "assets/icon/EmailIcon.svg";
import { ReactComponent as GeoIcon } from "assets/icon/GeoIcon.svg";
import { ReactComponent as PhoneIcon } from "assets/icon/PhoneIcon.svg";
import React from "react";
import { useCPDNDesktopStyles } from "routes/pages/CertificatePage/CPDNavbar/CPDNDesktop/useCPDNDesktop.styles";
import { infoData } from "lib/mock/infoData";

const CPDNDesktop = () => {
  return (
    <ListSC>
      <LiSC>
        <IconContainerSC>
          <EmailIcon />
        </IconContainerSC>
        <LinkSC href={infoData.email.mail_to}>{infoData.email.display}</LinkSC>
      </LiSC>
      <LiSC>
        <IconContainerSC>
          <GeoIcon />
        </IconContainerSC>
        <LinkSC>
          {infoData.geo.display.office} <br /> {infoData.geo.display.city}
        </LinkSC>
      </LiSC>
      <LiSC>
        <IconContainerSC>
          <PhoneIcon />
        </IconContainerSC>
        <div>
          <LinkSC href={infoData.tel.first.num}>
            {infoData.tel.first.display}
          </LinkSC>
          <LinkSC href={infoData.tel.second.num}>
            {infoData.tel.second.display}
          </LinkSC>
        </div>
      </LiSC>
    </ListSC>
  );
};

const { IconContainerSC, LinkSC, ListSC, LiSC } = useCPDNDesktopStyles();

export default React.memo(CPDNDesktop);
