import styled from "styled-components";

const ContainerSC = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  padding: 0 20px;
`;

export const usePricePageStyles = () => ({ ContainerSC });
