import {
  PagesNamespace,
  PagesNamespaceRU,
} from "lib/constants/enums/PagesNamespace";

export type HeaderDataProps = {
  id: number;
  title: string;
  href: string;
};

export type HeaderArr = [
  HeaderDataProps,
  HeaderDataProps,
  HeaderDataProps,
  HeaderDataProps,
  HeaderDataProps,
  HeaderDataProps,
  HeaderDataProps,
];

export const HEADER_ROUND_LENGTH = 7;
export const HEADER_ROUND_TOP_LENGTH = 3;
export const HEADER_ROUND_BOTTOM_LENGTH = 4;

export const getHeaderData = (idStart = 0, step = 1): HeaderArr => {
  return [
    {
      id: idStart + step,
      title: PagesNamespaceRU.INDEX,
      href: PagesNamespace.INDEX,
    },
    {
      id: idStart + step * 2,
      title: PagesNamespaceRU.CONTACTS,
      href: PagesNamespace.CONTACTS,
    },
    {
      id: idStart + step * 3,
      title: PagesNamespaceRU.PARTNERS,
      href: PagesNamespace.PARTNERS,
    },
    {
      id: idStart + step * 4,
      title: PagesNamespaceRU.CASES,
      href: PagesNamespace.CASES,
    },
    {
      id: idStart + step * 5,
      title: PagesNamespaceRU.ABOUT,
      href: PagesNamespace.ABOUT,
    },
    {
      id: idStart + step * 6,
      title: PagesNamespaceRU.VACANCIES,
      href: PagesNamespace.VACANCIES,
    },
    {
      id: idStart + step * 7,
      title: PagesNamespaceRU.PRICE,
      href: PagesNamespace.PRICE,
    },
  ];
};

export const getHeaderInfinity = () => {
  const headerBefore = getHeaderData();
  const headerDisplay = getHeaderData(HEADER_ROUND_LENGTH);
  const headerAfter = getHeaderData(HEADER_ROUND_LENGTH * 2);

  return [...headerBefore, ...headerDisplay, ...headerAfter];
};
