import { useEffect, useState } from "react";
import {
  HeaderHandleScrollAppInner,
  UseHeaderMobile,
} from "components/Header/type";
import { popupStore } from "store/popupStore/popupStore";

const IS_HOME = window.location.pathname !== "/";

export const useHeaderMobile = ({
  isVisible,
  handleScrollApp,
}: UseHeaderMobile) => {
  const [isTriggerScroll, setIsTriggerScroll] = useState<boolean>(IS_HOME);
  const isViewPopup = popupStore((state) => state.isView);

  const onTouchTopToDirection = (
    event: globalThis.TouchEvent,
    direction: "top" | "bottom",
    registerScroll: HeaderHandleScrollAppInner,
  ) => {
    const target = event.target as HTMLDivElement;

    let lastTouchY: number | null = null;
    let changeCount = 0;

    const onTouchMove = (e: globalThis.TouchEvent) => {
      const { pageY } = e.touches[0];

      if (lastTouchY) {
        const isBottom = lastTouchY > pageY;
        const difference = Math.abs(pageY - lastTouchY);
        const sensitivity = 3;

        if (difference > sensitivity) {
          registerScroll(isBottom ? "bottom" : "top", true, true);
          changeCount += 1;
        }
      }

      lastTouchY = pageY;
    };

    const onTouchEnd = () => {
      if (changeCount > 0) {
        registerScroll("", true, false);
      }

      target.removeEventListener("touchmove", onTouchMove);
      target.removeEventListener("touchend", onTouchEnd);
      lastTouchY = null;
      changeCount = 0;
    };

    target.addEventListener("touchmove", onTouchMove);
    target.addEventListener("touchend", onTouchEnd);
  };

  useEffect(() => {
    const appContainer = document.querySelector("#root") as HTMLDivElement;
    const registerScroll = handleScrollApp();

    const touchStart = (e: globalThis.TouchEvent) =>
      onTouchTopToDirection(e, "top", registerScroll);

    if (isViewPopup) {
      appContainer.removeEventListener("touchstart", touchStart);
    } else {
      appContainer.addEventListener("touchstart", touchStart);
    }

    return () => {
      appContainer.removeEventListener("touchstart", touchStart);
    };
  }, [isViewPopup]);

  useEffect(() => {
    if (isVisible) {
      setIsTriggerScroll(true);
    }
  }, [isVisible]);

  return {
    isTriggerScroll,
  };
};
