import React, { MouseEvent } from "react";
import { useCircularContentBottomStyles } from "UI/CircularContentUI/CircularContentBottom/CircularContentBottom.styles";
import { CircularContentBottomProps } from "UI/CircularContentUI/types";
import { useCircularContentBottom } from "UI/CircularContentUI/CircularContentBottom/useCircularContentBottom";

const CircularContentBottom = (props: CircularContentBottomProps) => {
  const { onClick, text } = props;
  const { containerAnim, textAnim, changeMouseProps } =
    useCircularContentBottom(props);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <BottomContainerSC
      style={containerAnim}
      onClick={handleClick}
      {...changeMouseProps}>
      <BottomTextSC style={textAnim}>{text}</BottomTextSC>
    </BottomContainerSC>
  );
};

const { BottomContainerSC, BottomTextSC } = useCircularContentBottomStyles();

export default React.memo(CircularContentBottom);
