import { ApiNameSpace } from "lib/constants/enums/ApiNameSpace";

export const fetchPostVacansies = (file: File) => {
  const formData = new FormData();

  formData.append("file", file);

  return fetch(ApiNameSpace.VACANCIES, {
    body: formData,
    method: "POST",
  });
};
