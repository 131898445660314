import React from "react";
import { ReactComponent as SwiperArrowLeft } from "assets/icon/SwiperArrowLeft.svg";
import { ReactComponent as SwiperArrowRight } from "assets/icon/SwiperArrowRight.svg";
import { ICasesSwiperNavProps } from "../../types";
import CasesSwiperSocial from "../../CasesSwiperSocial/CasesSwiperSocial";
import { useCasesSwiperNavStyles } from "./CasesSwiperNav.styles";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

const CasesSwiperNav = ({
  hoverItem,
  activeData,
  handleNextSlide,
  handlePrevSLide,
}: ICasesSwiperNavProps) => {
  const { changeMouseProps } = changeCursorActive();

  return (
    <ContainerSC>
      <ButtonSC onClick={handlePrevSLide} {...changeMouseProps}>
        <SwiperArrowLeft />
      </ButtonSC>
      {!activeData && hoverItem && <ParagraphSC>{hoverItem}</ParagraphSC>}
      {activeData && <CasesSwiperSocial {...activeData} />}
      <ButtonSC onClick={handleNextSlide} {...changeMouseProps}>
        <SwiperArrowRight />
      </ButtonSC>
    </ContainerSC>
  );
};

const { ContainerSC, ButtonSC, ParagraphSC } = useCasesSwiperNavStyles();

export default React.memo(CasesSwiperNav);
