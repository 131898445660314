import React from "react";
import Marquee from "react-fast-marquee";
import { Timing } from "theme/styles/timing";
import { IMarqueeUIProps } from "UI/MarqueeUI/types";
import MarqueeItem from "UI/MarqueeUI/MarqueeWord/MarqueeWord";

const MarqueeUI = ({ data, ...marqueeProps }: IMarqueeUIProps) => {
  const word = data.join(" · ");

  return (
    <Marquee speed={Timing.MARQUEE} gradient={false} {...marqueeProps}>
      <MarqueeItem word={word} />
    </Marquee>
  );
};

export default React.memo(MarqueeUI);
