import styled from "styled-components";
import { IFlipUIProps } from "UI/FlipUI/types";

const WrapperSC = styled("div")<Pick<IFlipUIProps, "isActive">>`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.4s;
  transform-style: preserve-3d;
  transform: rotateY(${({ isActive }) => (isActive ? 180 : 0)}deg);
`;

const ContainerSC = styled.button`
  box-sizing: border-box;

  display: block;
  width: 232px;
  height: 232px;

  position: relative;
  background-color: transparent;

  perspective: 1000px;
`;

export const useFlipImageUIStyles = () => ({
  ContainerSC,
  WrapperSC,
});
