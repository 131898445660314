import { useHeaderItemDesktop } from "components/Header/HeaderItem/useHeaderItemDesktop";
import { useHeaderItemMobile } from "components/Header/HeaderItem/useHeaderItemMobile";
import { HeaderItemProps } from "components/Header/type";
import { useHeaderItemDefault } from "components/Header/HeaderItem/useHeaderItemDefault";

export const useHeaderItem = (props: HeaderItemProps) => {
  //? default
  const { isActive, isMobile, onClick, onMouseLeave, handleMouseEnter } =
    useHeaderItemDefault(props);

  const logicProps = { ...props, isActive };

  //? mobile or desktop logic
  const { isPointerEvent, springs } = isMobile
    ? useHeaderItemMobile(logicProps)
    : useHeaderItemDesktop(logicProps);

  return {
    isActive,
    isPointerEvent,

    springs,

    onClick,
    onMouseLeave,
    handleMouseEnter,
  };
};
