import styled, { css } from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";

const transitionMixin = ({ isClicked }: { isClicked: boolean }) => css`
  transition: all ${isClicked ? "0.1s" : "0.3s"} 0.8s ease-out;
`;

const ListSC = styled.ul<{ isClicked: boolean }>`
  width: 100%;
  height: 100%;
  padding: 0 66px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;

  ${transitionMixin}

  opacity: ${({ isClicked }) => (isClicked ? 0 : 1)};
  position: absolute;

  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    padding: 0 15px;
  }
`;

const AboutPageDescSC = styled.p<{ isClicked: boolean }>`
  text-align: center;
  max-width: 624px;

  width: 90%;
  margin: 0 auto;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;

  ${transitionMixin}

  opacity: ${({ isClicked }) => (isClicked ? 1 : 0)};
`;

export const useAboutPageMobileStyles = () => ({
  ListSC,
  AboutPageDescSC,
});
