import styled from "styled-components";

const SmallImageSC = styled.div<{ $isActive?: boolean }>`
  margin: 0 auto;
`;

const ButtonSC = styled.div<{ $isActive?: boolean }>`
  width: 55px;
  height: 55px;
  box-sizing: border-box;

  ${(props) =>
    props.$isActive ? "outline: 4px solid #fff;" : "border: 2px solid #fff;"}

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 35px;
    height: 35px;
  }
`;

export const useCasesPageMobileItemsStyles = () => {
  return {
    SmallImageSC,
    ButtonSC,
  };
};
