import React from "react";
import GiftPageDesktop from "routes/pages/GiftPage/GitfPageDesktop/GiftPageDesktop";
import { useGiftPage } from "routes/pages/GiftPage/useCertificatePage";
import GiftPageMobile from "routes/pages/GiftPage/GiftPageMobile/GiftPageMobile";

const GiftPage = () => {
  const { isMobile, costData, isLoading } = useGiftPage();

  return (
    <>
      {!isMobile && (
        <GiftPageDesktop isLoading={isLoading} costData={costData} />
      )}
      {isMobile && <GiftPageMobile isLoading={isLoading} costData={costData} />}
    </>
  );
};

export default React.memo(GiftPage);
