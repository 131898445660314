import styled, { css, FlattenSimpleInterpolation } from "styled-components";

interface VariantProps {
  variant: string;
}
interface FontSizeMixin {
  [key: string]: FlattenSimpleInterpolation;
}

const baseMix = css`
  padding: 0 3px;
  background: #d9d9d9;
  color: #000;
`;

const fontSizeMixin: FontSizeMixin = {
  hello: css`
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  `,
  text: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  `,
  partner: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  `,
  default: css`
    font-size: 18px;
  `,
};

const DivSC = styled.div<VariantProps>`
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  white-space: pre-wrap;
  ${(props) => fontSizeMixin[props.variant] || fontSizeMixin.default};
`;

const LinkSC = styled.a`
  ${baseMix};
`;

const ButtonSC = styled.button`
  ${baseMix};

  font-weight: 500;
  text-transform: uppercase;
  white-space: pre-wrap;
  font-size: 16px;
`;

export const useAnimatedText = () => ({ DivSC, LinkSC, ButtonSC });
