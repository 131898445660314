import React from "react";

import { usePricePageMobileStyles } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CalculatedPageMobile.styles";
import { useCalculatedPageMobile } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/useCalculatedPageMobile";
import CPMCheckbox from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMCheckbox/CPMCheckbox";
import CPMTotal from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMTotal/CPMTotal";
import CPMCategoriesSwipe from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMCategoriesSwipe/CPMCategoriesSwipe";

const CalculatedPageMobile = () => {
  const {
    selectedPrices,
    totalPrice,
    currentPrices,
    allItemsRendered,
    currentPage,
    listRef,
    otherValues,

    handlePrevPage,
    handleReset,
    handleNextPage,
    handleCheckboxChange,
    handleNextSlide,
    handlePrevSlide,
    handleChangeSwiper,
  } = useCalculatedPageMobile();

  const renderElements = currentPrices.map((item, i) => (
    <CPMCheckbox
      key={i}
      item={item}
      isChecked={selectedPrices.includes(item)}
      onCheckboxChange={handleCheckboxChange}
    />
  ));

  return (
    <>
      <CPMCategoriesSwipe
        allItemsRendered={allItemsRendered}
        handleReset={handleReset}
        handleNextSlide={handleNextSlide}
        handlePrevSlide={handlePrevSlide}
        handleChangeSwiper={handleChangeSwiper}
      />
      {!allItemsRendered && (
        <>
          <ListSC ref={listRef}>{renderElements}</ListSC>
          <ContainerButtonSC>
            <BackButtonSC
              isVisible={currentPage !== 1}
              onClick={handlePrevPage}>
              Назад
            </BackButtonSC>
            <ButtonSC isVisible={currentPage !== 1} onClick={handleNextPage}>
              Далее
            </ButtonSC>
          </ContainerButtonSC>
        </>
      )}
      {allItemsRendered && (
        <CPMTotal price={totalPrice} otherValues={otherValues} />
      )}
    </>
  );
};

const { ContainerButtonSC, BackButtonSC, ButtonSC, ListSC } =
  usePricePageMobileStyles();
export default React.memo(CalculatedPageMobile);
