import { useEffect, useState } from "react";

type CPTimerProps = {
  time: string;
};

type RemainingTimeState = {
  days: number;
  hours: number;
  minutes: number;
};

export const useCPTimer = ({ time }: CPTimerProps) => {
  const [remainingTime, setRemainingTime] = useState<RemainingTimeState>({
    days: 0,
    hours: 0,
    minutes: 0,
  });

  useEffect(() => {
    if (!time) {
      return;
    }
    setRemainingTime(calculateTimeLeft(time));

    const interval = setInterval(() => {
      setRemainingTime(calculateTimeLeft(time));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return { remainingTime };
};

const calculateTimeLeft = (time: string): RemainingTimeState => {
  const now = new Date();
  const target = new Date(time);

  // Добавляем один год к заданной дате
  target.setFullYear(target.getFullYear() + 1);

  const diff: number = target.getTime() - now.getTime();

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { days, hours, minutes };
};
