import React from "react";
import { createPortal } from "react-dom";
import HBContent from "components/Header/HeaderBackdoor/HBContent/HBContent";
import { HeaderBackdoorProps } from "components/Header/HeaderBackdoor/types";

const HeaderBackdoor = (props: HeaderBackdoorProps) => {
  return createPortal(
    <HBContent {...props} />,
    document.querySelector("#root") as HTMLDivElement,
  );
};

export default React.memo(HeaderBackdoor);
