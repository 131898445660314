import { useHeaderDefault } from "components/Header/useHeaderDefault";
import { useHeaderDesktop } from "components/Header/useHeaderDesktop";
import { useHeaderMobile } from "components/Header/useHeaderMobile";

export const useHeader = () => {
  const defaultLogic = useHeaderDefault();
  const { handleScrollApp, handleChangeStatusState, status } = defaultLogic;

  const { onMouseLeave, onMouseEnter } = useHeaderDesktop({
    isVisible: status.isVisible,
    handleScrollApp,
    handleChangeStatusState,
  });

  const { isTriggerScroll } = useHeaderMobile({
    isVisible: status.isVisible,
    handleScrollApp,
  });

  return { ...defaultLogic, onMouseEnter, onMouseLeave, isTriggerScroll };
};
