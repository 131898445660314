import React from "react";
import { useFooterStyles } from "./Footer.styles";
import { footerData } from "lib/mock/footerData";
import FooterItem from "components/Footer/FooterItem/FooterItem";

const Footer = () => {
  return (
    <FootersSC>
      <LeftContainerSC>Студия цифрового опыта</LeftContainerSC>
      <IconsContainerSC>
        <ListSC>
          {footerData.map((footerItem) => (
            <FooterItem key={footerItem.link} {...footerItem} />
          ))}
        </ListSC>
      </IconsContainerSC>
    </FootersSC>
  );
};

const { FootersSC, LeftContainerSC, IconsContainerSC, ListSC } =
  useFooterStyles();

export default React.memo(Footer);
