import React from "react";
import SwiperTypes from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { priceData } from "lib/mock/priceData";
import { ReactComponent as SwiperArrowLeft } from "assets/icon/SwiperArrowLeft.svg";
import { ReactComponent as SwiperArrowRight } from "assets/icon/SwiperArrowRight.svg";
import { ReactComponent as BackArrow } from "assets/icon/BackArrow.svg";
import { useCPMCategoriesSwipeStyles } from "routes/pages/PricePage/CalculatedPage/CalculatedPageMobile/CPMCategoriesSwipe/CPMCategoriesSwipe.styles";

interface CPMCategoriesSwipeI {
  handlePrevSlide: () => void;
  allItemsRendered: boolean;
  handleChangeSwiper: (arg: SwiperTypes) => void;
  handleNextSlide: () => void;
  handleReset: () => void;
}
const CPMCategoriesSwipe = ({
  handlePrevSlide,
  allItemsRendered,
  handleChangeSwiper,
  handleNextSlide,
  handleReset,
}: CPMCategoriesSwipeI) => {
  return (
    <WrapperSwiperSC>
      <ArrowButtonSC onClick={handlePrevSlide}>
        {!allItemsRendered && <SwiperArrowLeft />}
      </ArrowButtonSC>
      <Swiper
        style={{ height: "100%", textAlign: "center" }}
        loop={true}
        onSwiper={handleChangeSwiper}
        allowTouchMove={false}
        slidesPerView={1}>
        {priceData.data.map((item, i) => (
          <SwiperSlide key={i}>
            <TitleSC>{item.content}</TitleSC>
          </SwiperSlide>
        ))}
      </Swiper>
      {!allItemsRendered && (
        <ArrowButtonSC onClick={handleNextSlide}>
          <SwiperArrowRight />
        </ArrowButtonSC>
      )}
      {allItemsRendered && (
        <ArrowButtonSC onClick={handleReset}>
          <BackArrow />
        </ArrowButtonSC>
      )}
    </WrapperSwiperSC>
  );
};

const { WrapperSwiperSC, TitleSC, ArrowButtonSC } =
  useCPMCategoriesSwipeStyles();

export default React.memo(CPMCategoriesSwipe);
