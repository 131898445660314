import React from "react";
import { useDisscussProjectStyles } from "./DiscussProject.styles";
import { popupStore } from "store/popupStore/popupStore";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

const DiscusProjectDesktop = () => {
  const onClick = popupStore((state) => state.changeToggleViewPopup);
  const { changeMouseProps } = changeCursorActive();

  return (
    <ContainerSC>
      <ButtonSC {...changeMouseProps} onClick={onClick}>
        Заказать проект
      </ButtonSC>
    </ContainerSC>
  );
};

const { ContainerSC, ButtonSC } = useDisscussProjectStyles();

export default React.memo(DiscusProjectDesktop);
