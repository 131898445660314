import { useState } from "react";
import { casesData } from "lib/mock/casesData";

export const useCasesPageMobile = () => {
  const [hoverItem, setHoverItem] = useState<string>("");
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const [isOpenExtand, setisOpenExtand] = useState(false);

  const activeData =
    activeIndex === null ? null : casesData[activeIndex as number];

  const handleToggleExtand = () => {
    setisOpenExtand(!isOpenExtand);
  };

  const handleChangeActiveIndexMobile = (index: number) => {
    setActiveIndex(index);
  };

  const handleChangeHover = (desc: string) => {
    setHoverItem(desc);
  };

  return {
    hoverItem,
    activeData,
    activeIndex,
    isOpenExtand,

    setisOpenExtand,
    handleChangeHover,
    handleChangeActiveIndexMobile,
    handleToggleExtand,
  };
};
