import { useEffect, useState, MouseEvent, useRef } from "react";
import { CircularContentUIProps } from "UI/CircularContentUI/types";
import { Timing } from "theme/styles/timing";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

export const useCircularContentUI = ({
  onClick,
  isLoading = false,
  isDisabled = false,
  otherValues,
}: CircularContentUIProps) => {
  const { changeMouseProps } = changeCursorActive();

  const [value, setValue] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [isAdded, setIsAdded] = useState(false);

  const handleClick = (e: MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  const changeCursorProps = !isDisabled && onClick ? changeMouseProps : {};

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setValue(100);
      }, Timing.DURATION_FAST);
    } else {
      setValue(0);
    }
  }, [isLoading]);

  const loopPriceValue = (currentIndex: number) => {
    if (otherValues && otherValues.length > 1) {
      setCurrentIndex(currentIndex);
      timerRef.current = setTimeout(() => {
        loopPriceValue((currentIndex + 1) % otherValues.length);
      }, 4000);
    }
  };

  useEffect(() => {
    if (otherValues && otherValues.length > 0) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }

    loopPriceValue(currentIndex);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [currentIndex, otherValues]);

  return {
    value,
    isDisabled,
    changeCursorProps,
    currentIndex,
    isAdded,

    handleClick,
  };
};
