import styled from "styled-components";

const ContainerSC = styled.section`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextSC = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
`;

export const ErrorPageStyles = () => ({
  ContainerSC,
  TextSC,
});
