import React, { useEffect, useState } from "react";
import RotatingLetter from "UI/AnimatedTextUI/RotatingLetter/RotatingLetter";
import styled from "styled-components";

interface AnimatedWordProps {
  word: string;
  wordIndex: number;
  isAfter?: boolean;
}
const AnimatedWord = ({ word, wordIndex, isAfter }: AnimatedWordProps) => {
  // Состояния для хранения индексов слов, над которыми находится курсор. Для текста "после" отдельный стейт
  const [hoveredWordIndex, setHoveredWordIndex] = useState(-1);
  const [hoveredWordIndexAfter, setHoveredWordIndexAfter] = useState(-1);

  const handleWordMouseEnter = (
    wordIndex: number,
    isAfter: boolean | undefined,
  ) => {
    if (!isAfter) {
      setHoveredWordIndex(wordIndex);
    } else {
      setHoveredWordIndexAfter(wordIndex);
    }
  };

  useEffect(() => {
    const timer = setTimeout(handleWordMouseLeave, 1500);
    return () => clearTimeout(timer);
  }, [hoveredWordIndex]);

  // Сбрасываем состояния для обоих наборов слов
  const handleWordMouseLeave = () => {
    setHoveredWordIndex(-1);
    setHoveredWordIndexAfter(-1);
  };

  return (
    <SpanSC
      key={wordIndex}
      onMouseEnter={() => handleWordMouseEnter(wordIndex, isAfter)}
      onMouseLeave={handleWordMouseLeave}>
      {[...word].map((letter, letterIndex) => (
        <React.Fragment key={letterIndex}>
          <RotatingLetter
            isHovered={
              (isAfter && hoveredWordIndexAfter === wordIndex) ||
              (!isAfter && hoveredWordIndex === wordIndex)
            }>
            {letter}
          </RotatingLetter>
        </React.Fragment>
      ))}
      &nbsp;
    </SpanSC>
  );
};

const SpanSC = styled.span`
  white-space: normal;
  display: inline-block;
`;

export default React.memo(AnimatedWord);
