import React from "react";
import { useAnimatedText } from "UI/AnimatedTextUI/AnimatedText.styles";
import AnimatedWord from "UI/AnimatedTextUI/AnimatedWord/AnimatedWord";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";
import { popupStore } from "store/popupStore/popupStore";

interface AnimatedTextProps {
  text: {
    text: string;
    href?: string;
    isPopupTrigger?: boolean;
  }[];
  variant: string;
  textAfter?: string;
  textBefore?: string;
}
const AnimatedTextUI = ({ text, variant }: AnimatedTextProps) => {
  const handleTogglePopup = popupStore((state) => state.changeToggleViewPopup);
  const { changeMouseProps } = changeCursorActive();

  const words = text.map((item, index) => {
    if (item.href) {
      return (
        <LinkSC
          key={index}
          {...changeMouseProps}
          href={item.href}
          target={"_blank"}>
          {item.text}
        </LinkSC>
      );
    } else if (item.isPopupTrigger) {
      return (
        <ButtonSC onClick={handleTogglePopup} {...changeMouseProps} key={index}>
          {item.text}
        </ButtonSC>
      );
    } else {
      return item.text
        .split(" ")
        .map((word, indexWord) => (
          <AnimatedWord key={indexWord} word={word} wordIndex={indexWord} />
        ));
    }
  });

  return <DivSC variant={variant}>{words}</DivSC>;
};

export default React.memo(AnimatedTextUI);

const { DivSC, LinkSC, ButtonSC } = useAnimatedText();
