import styled from "styled-components";
import { ColorScheme } from "theme/styles/colorScheme";
import { ZIndex } from "theme/styles/zIndex";

const WrapperSC = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;

  z-index: ${ZIndex.POPUP};

  padding: 14px;

  background: black;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  text-align: center;
`;

const SubTitle = styled.p`
  color: ${ColorScheme.GRAY_THREE};
  font-size: 14px;
  font-weight: 600;
`;

export const styles = { WrapperSC, SubTitle };
