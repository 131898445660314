import { useState, useRef } from "react";
import { fetchPostVacansies } from "lib/api/fetchVacansies/FetchVacansies";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

export const useVacanciesFigure = () => {
  const refInput = useRef<HTMLInputElement>(null);
  const [onDraggable, setOnDraggable] = useState<boolean>(false);
  const [value, setValue] = useState<File | null>(null);
  const [isSucces, setIsSucces] = useState(false);
  const [isNotFormat, setIsNotFormat] = useState(false);
  const { changeMouseProps } = changeCursorActive();

  const allowedFileTypes = [
    "application/pdf",
    "text/plain",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text",
    "application/rtf",
  ];
  // ?резюме

  // ?удаляем загруженный файл
  const handleClickDelete = () => {
    setOnDraggable(false);
    setValue(null);
  };

  // ?клик по невидимому инпуту
  const handleClickContainer = () => {
    refInput?.current?.click();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setOnDraggable(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleNotFormat = () => {
    setIsNotFormat(true);

    setTimeout(() => {
      setIsNotFormat(false);
    }, 2000);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0] as File;

    // setValue(file);
    if (file && allowedFileTypes.includes(file.type)) {
      setOnDraggable(true);
      setValue(file);
    } else {
      handleNotFormat();
    }
  };

  const handleInputUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && allowedFileTypes.includes(file.type)) {
      setValue(file);
      setOnDraggable(true);
    } else {
      handleNotFormat();
    }
  };

  const handleClickRepeatSend = () => {
    setIsSucces(false);
  };

  const handleSend = () => {
    setOnDraggable(false);

    if (value) {
      fetchPostVacansies(value);
      setOnDraggable(false);
      setIsSucces(true);
    }
  };

  return {
    onDraggable,
    refInput,
    isSucces,
    changeMouseProps,
    allowedFileTypes,
    isNotFormat,

    setOnDraggable,
    handleClickDelete,
    handleClickContainer,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleInputUpload,
    handleSend,
    handleClickRepeatSend,
  };
};
