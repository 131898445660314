import React from "react";
import HeaderItem from "components/Header/HeaderItem/HeaderItem";
import { useHeaderStyles } from "components/Header/Header.styles";
import { useHeader } from "components/Header/useHeader";
import HeaderBackdoor from "components/Header/HeaderBackdoor/HeaderBackdoor";
import HeaderScrollDown from "components/Header/HeaderScrollDown/HeaderScrollDown";
import { useBigTablet } from "../../hooks/useBreakpoints";

const Header = () => {
  const {
    headerData,
    status,
    activeId,
    isTriggerScroll,
    onMouseLeave,
    onMouseEnter,
    handleClick,
  } = useHeader();

  const isBigTablet = useBigTablet();

  return (
    <>
      <HeaderSC>
        <NavSC>
          <UlSC isVisible={status.isVisible} onMouseLeave={onMouseLeave}>
            {headerData.map((headerItem) => (
              <HeaderItem
                key={headerItem.id}
                activeId={activeId}
                isMobile={isBigTablet}
                onMouseEnter={onMouseEnter}
                handleClick={handleClick}
                {...status}
                {...headerItem}
              />
            ))}
          </UlSC>
        </NavSC>
        {!isTriggerScroll && <HeaderScrollDown />}
      </HeaderSC>
      <HeaderBackdoor isVisible={status.isVisible} />
    </>
  );
};

const { HeaderSC, NavSC, UlSC } = useHeaderStyles();

export default React.memo(Header);
