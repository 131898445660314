import { useEffect, useState } from "react";
import { useTablet } from "hooks/useBreakpoints";
import { UserData } from "routes/pages/CertificatePage/types";
import { useParams } from "react-router-dom";
import { fetchCertificate } from "lib/api/FetchCertificate/FetchCertificate";

export const UseCertificatePage = () => {
  const isMobile = useTablet();

  const [userData, setUserData] = useState<UserData>({
    activate_at: "",
    cost: 0,
    expired: false,
    id: 0,
    is_active: false,
    name: "",
    sex: "",
    tournament: "",
  });
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isActivate, setIsActive] = useState(true);
  const { id } = useParams();

  const handleFetch = async (id: string | undefined) => {
    try {
      const response = await fetchCertificate(id);
      const data = await response.json();
      setUserData(data);
      if (data.is_active === false) {
        setIsActive(false);
      }
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
    }
  };

  useEffect(() => {
    handleFetch(id);
  }, []);

  return { isMobile, userData, isError, isLoading, isActivate };
};
