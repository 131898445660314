import React from "react";
import Lottie from "lottie-react";
import { ReactComponent as CloudIcon } from "assets/icon/CloudIcon.svg";
import { useVacanciesFigureStyles } from "./VacanciesFigure.styles";
import CircularContentUI from "UI/CircularContentUI/CircularContentUI";

import { useVacanciesFigure } from "./useVacanciesFigure";
import VacansieAnimation from "assets/animation/vacanciesAnimation.json";
import VacanciesSuccessSend from "./VacansiesSuccessSend/VacanciesSuccessSend";

const VacanciesFigure = () => {
  const {
    onDraggable,
    refInput,
    isSucces,
    changeMouseProps,
    allowedFileTypes,
    isNotFormat,

    handleClickDelete,
    handleClickContainer,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleInputUpload,
    handleSend,
    handleClickRepeatSend,
  } = useVacanciesFigure();

  return (
    <>
      {!isSucces && onDraggable && (
        <>
          <CircularContentUI
            onClick={handleSend}
            bottom={{
              isVisible: true,
              isVacansiesPage: true,
              text: "Удалить",
              onClick: handleClickDelete,
            }}
            style={{}}
            styleCont={{ marginBottom: "70px" }}
            text={"Отправить"}
          />
          <ParagraphVP>один шаг до работы мечты!</ParagraphVP>
        </>
      )}
      {!isSucces && !onDraggable && (
        <>
          <ContainerSC
            style={{}}
            {...changeMouseProps}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClickContainer}>
            <input
              type="file"
              name="file"
              accept={allowedFileTypes.join(", ")}
              ref={refInput}
              style={{ display: "none" }}
              onChange={handleInputUpload}
            />
            <ContainerCloudSC>
              <CloudIcon />
            </ContainerCloudSC>
            <ParagraphSC>
              {isNotFormat
                ? "Используйте другой тип файла"
                : "Поместите сюда резюме"}
            </ParagraphSC>

            <SvgContainerCS>
              <Lottie animationData={VacansieAnimation} />
            </SvgContainerCS>
          </ContainerSC>
        </>
      )}
      {isSucces && (
        <VacanciesSuccessSend handleClickRepeatSend={handleClickRepeatSend} />
      )}
    </>
  );
};

const {
  ContainerSC,
  ParagraphSC,
  SvgContainerCS,
  ParagraphVP,
  ContainerCloudSC,
} = useVacanciesFigureStyles();

export default React.memo(VacanciesFigure);
