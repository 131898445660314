import styled from "styled-components";

const PageContainerSC = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
`;

const ContainerSC = styled("div")`
  width: 100%;
  max-width: 1068px;

  /* padding: 20px 0; */
  margin: 0 auto;
`;

export const useCasesPageStyles = () => {
  return {
    PageContainerSC,
    ContainerSC,
  };
};
