import React from "react";
import { useCursorStyles } from "./style";
import { useCursorUI } from "./useCursorUI";
import CircularProgressbarUI from "UI/CircularProgressbarUI/CircularProgressbarUI";
import { motion } from "framer-motion";

// курсор
const CursorUI = () => {
  const { isActive, containerStyle, progressbarProps, animationCursor } =
    useCursorUI();

  return (
    <ContainerSC isActive={isActive} style={containerStyle}>
      <IconContainerSC>
        <motion.div animate={animationCursor}>
          <CursorIconSC />
        </motion.div>
      </IconContainerSC>
      <CircularProgressbarUI strokeWidth={3} {...progressbarProps} />
    </ContainerSC>
  );
};

const { ContainerSC, IconContainerSC, CursorIconSC } = useCursorStyles();

export default React.memo(CursorUI);
