export const aboutData = {
  top: [
    "Корпоративные сайты, промо-сайты",
    "Интернет-магазины",
    "Высоко нагруженные проекты",
    "Создание веб-сервисов",
    "Frontend и Backend-разработка ·",
  ],
  paragraph: [
    {
      text: "Привет! На связи студия IT продуктов INDEX. Мы создаем сайты \n для бизнеса, интернет-магазины, мобильные приложения и с удовольствием \n воплотим все ваши смелые идеи в мире цифровых продуктов. \n В какой бы сфере деятельности ни работала ваша организация, где бы ни \n располагались ваши офисы и производственные объекты, наши специалисты \n всегда помогут выбрать и реализовать лучшие ИТ-решения, которые способны \n обеспечить надежную поддержку существующих и перспективных задач \n бизнеса. Мы стремимся к долгосрочному партнерству и всегда гарантируем \n выполнение работы на высоком уровне и в указанные сроки.",
    },
  ],
  bottom: [
    "Управление бизнесом со смартфона",
    "Проектирование архитектуры и интерфейсов",
    "Мобильные приложения",
    "Концепция дизайна, анимации",
    "Адаптивная вёрстка ·",
  ],
  mobile: [
    "Имиджевые",
    "сайты",
    "Интернет магазины",
    "Дизайн концепции",
    "Мобильные приложения",
    "Промо сайты",
    "Frontend",
    " разработка",
    "Разработка сайтов",
    "Backend разработка",
    "Создание логотипов",
    "Формирование",
    "BrandBook",
  ],
  textMobile:
    "Мы – digital-студия INDEX – компания \n по разработке цифровых продуктов.\n Мы создаём сайты для бизнеса,\n сервисы с широким функционалом \n и мобильные приложения для\n разных целей. Мы заинтересованы в\n постоянном росте и устойчивом\n развитии нашей компании. Мы\n стремимся постоянно повышать\n качество выполняемой нами\n работыи постепенно наращивать\n обороты. Мы хотим работать над \n более сложными проектами и \n сотрудничать с более крупными \n партнёрами.",
};
