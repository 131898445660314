import { useMemo } from "react";
import { HeaderItemProps } from "components/Header/type";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

export const useHeaderItemDefault = (props: HeaderItemProps) => {
  const { id, activeId, onMouseEnter, isMobile, handleClick } = props;

  const isActive = useMemo(() => id === activeId, [id, activeId]);

  const {
    onChangeActive,
    changeMouseProps: { onMouseLeave },
  } = changeCursorActive();

  const onClick = () => {
    if (isMobile) return;
    handleClick(id)();
  };

  const handleMouseEnter = () => {
    if (isMobile) return;
    onChangeActive(true)();
    onMouseEnter();
  };

  return {
    isMobile,
    isActive,
    onClick,
    onMouseLeave,
    handleMouseEnter,
  };
};
