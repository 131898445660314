import styled from "styled-components";
import { ColorScheme } from "theme/styles/colorScheme";

interface FlipFrontTypes {
  imageUrl: string;
  title: string;
}

const FlipFrontSC = ({ imageUrl, title }: FlipFrontTypes) => (
  <FrontImage src={imageUrl} alt={title} />
);

const FrontImage = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;

  backdrop-filter: blur(25px);
  backface-visibility: hidden;

  background-color: ${ColorScheme.BLACK_TWO};

  transition: 0.2s all linear;
`;

export default FlipFrontSC;
