import { useEffect } from "react";
import { UseHeaderDesktop } from "components/Header/type";
import { popupStore } from "store/popupStore/popupStore";

export const useHeaderDesktop = ({
  isVisible,
  handleScrollApp,
  handleChangeStatusState,
}: UseHeaderDesktop) => {
  const isViewPopup = popupStore((state) => state.isView);

  const onMouseEnter = () => {
    handleChangeStatusState({
      isVisible: true,
      isHover: true,
      isScroll: false,
      isTouch: false,
    });
  };

  const onMouseLeave = () => {
    if (isVisible) {
      handleChangeStatusState({
        isVisible: false,
        isHover: false,
        isScroll: false,
        isTouch: false,
      });
    }
  };

  useEffect(() => {
    const appContainer = document.querySelector("#root") as HTMLDivElement;
    const registerScroll = handleScrollApp();
    const onWheel = (e: WheelEvent) =>
      registerScroll(e.deltaY > 0 ? "bottom" : "top", false, false);

    if (isViewPopup) {
      appContainer.removeEventListener("wheel", onWheel);
    } else {
      appContainer.addEventListener("wheel", onWheel);
    }

    return () => {
      appContainer.removeEventListener("wheel", onWheel);
    };
  }, [isViewPopup]);

  return {
    onMouseEnter,
    onMouseLeave,
  };
};
