import { useEffect, useRef } from "react";
//eslint-disable-next-line
//@ts-ignore
import CircleType from "circletype";

export const useCircularTextUI = () => {
  const ref = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    new CircleType(ref.current);
  }, []);

  return {
    ref,
  };
};
