import { useState, useEffect, MouseEvent, useMemo } from "react";
import { cursorStore } from "store/cursorStore/cursorStore";
import { Size } from "theme/styles/Size";
import { useLocation } from "react-router-dom";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

type StyleStateType = {
  opacity: number;
  top: string;
  left: string;
  movementX: number;
  movementY: number;
};

export const useCursorUI = () => {
  const location = useLocation();

  //? store state
  const isActive = cursorStore((state) => state.isActive);
  const isLoading = cursorStore((state) => state.isLoading);
  const { onChangeActive } = changeCursorActive();

  //? local state
  const [moveMouseStyle, setMoveMouseStyle] = useState<StyleStateType>({
    opacity: 0,
    top: "0px",
    left: "0px",
    movementX: 0,
    movementY: 0,
  });
  const { movementX, movementY, ...containerStyle } = moveMouseStyle;

  //? loading style
  const progressbarProps = useMemo(() => {
    return {
      value: isLoading ? 100 : 0,
      styles: {
        pathTransition: isLoading ? "stroke-dashoffset 0.5s linear 0s" : "none",
      },
    };
  }, [isLoading]);

  //? offset speed cursor
  const getOffset = (coordinates: number) => {
    const max = 5;
    const min = -max;

    const isMax = coordinates > max;
    const isMin = coordinates < min;

    if (isMax) return coordinates;
    if (isMin) return coordinates;

    return coordinates * 2;
  };

  //? animation offset cursor
  const animationCursor = useMemo(
    () => ({
      x: getOffset(movementX),
      y: getOffset(movementY),
    }),
    [movementX, movementY],
  );

  const onMouseMove = (e: unknown) => {
    const event = e as MouseEvent;

    const cursorPX = (isActive ? Size.CURSOR_ACTIVE : Size.CURSOR) / 2;

    const coordinatesX = event.pageX - cursorPX + "px";
    const coordinatesY = event.pageY - cursorPX + "px";

    setMoveMouseStyle(() => ({
      opacity: 1,
      top: coordinatesY,
      left: coordinatesX,
      movementX: event.movementX,
      movementY: event.movementY,
    }));

    // перед перезагрузкой страницы сохраянем положение курсора
    window.addEventListener("beforeunload", function () {
      sessionStorage.setItem("@mouseX", coordinatesX);
      sessionStorage.setItem("@mouseY", coordinatesY);
    });
  };

  //? монтирование курсора, учтена перезагрузка страницы
  useEffect(() => {
    const mountedY = sessionStorage.getItem("@mouseY");
    const mountedX = sessionStorage.getItem("@mouseX");

    if (mountedX && mountedY) {
      setMoveMouseStyle({
        opacity: 1,
        top: mountedY,
        left: mountedX,
        movementX: 0,
        movementY: 0,
      });
    }

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  //? очистка смещения курсора в зависимости от скорости
  useEffect(() => {
    const timeout = setTimeout(() => {
      setMoveMouseStyle((prevState) => ({
        ...prevState,
        movementX: 0,
        movementY: 0,
      }));
    }, 150);

    return () => clearTimeout(timeout);
  }, [moveMouseStyle.movementX, moveMouseStyle.movementY]);

  useEffect(() => {
    if (isActive) {
      onChangeActive(false)();
    }
  }, [location]);

  return {
    isActive,
    movementX,
    movementY,
    containerStyle,
    animationCursor,
    progressbarProps,
  };
};
