export const infoData = {
  email: {
    display: "info@index-studio.ru",
    mail_to: "mailto:info@index-studio.ru",
  },
  geo: {
    display: {
      office: "Молодогвардейцев 60в, офис 505",
      city: "Челябинск, Россия, 454136",
    },
  },
  tel: {
    first: {
      display: "+7 (919) 123-23-92",
      num: "tel:+79191232392",
    },
    second: {
      display: "+7 (919) 123-23-95 ",
      num: "tel:+79191232395 ",
    },
  },
};
