import React from "react";
import { useBigTablet } from "hooks/useBreakpoints";
import DealTextDesktop from "routes/pages/IndexPage/DealTextItems/DealTextItemsDesktop/DealTextDesktop";
import DealTextItemsMobile from "routes/pages/IndexPage/DealTextItems/DealTextItemsMobile/DealTextItemsMobile";

const DealText = ({
  isHover,
  isClicked,
}: {
  isHover: boolean;
  isClicked: boolean;
}) => {
  const isMobile = useBigTablet();

  return (
    <>
      {!isMobile && <DealTextDesktop isHover={isHover} />}
      {isMobile && <DealTextItemsMobile isClicked={isClicked} />}
    </>
  );
};

export default React.memo(DealText);
