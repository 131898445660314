import { useMediaQuery } from "./useMediaQuery";
import { Breakpoints } from "theme/styles/breakpoints";

export const useBigTablet = () =>
  useMediaQuery(`(max-width: ${Breakpoints.BIG_TABLET}px)`);

export const useTablet = () =>
  useMediaQuery(`(max-width: ${Breakpoints.TABLE}px)`);

export const useBigMobile = () =>
  useMediaQuery(`(max-width: ${Breakpoints.BIG_MOBILE}px)`);

export const useSmallMobile = () =>
  useMediaQuery(`(max-width: ${Breakpoints.MOBILE}px)`);

export const useHeightMobile = () =>
  useMediaQuery(`(max-height: ${Breakpoints.HEIGHT_MOBILE}px)`);
