import styled from "styled-components";

import FlipBackSC from "./BackSC/BackSC";
import FlipFrontSC from "./FrontSC/FrontSC";

type CasesLargeImgProps = {
  isOpen: boolean;
  handleToggleExtand: () => void;
  title?: string;
  subtitle?: string;
  desc?: string;
  Icon?: React.FC;
  imageUrl?: string;
  url?: string;
  behance?: string;
  apple?: string;
  android?: string;
  rustore?: string;
  download?: { type: string; url: string };
};

const CasesLargeImg: React.FC<CasesLargeImgProps> = ({
  isOpen,
  handleToggleExtand,

  imageUrl = "",
  title = "",
  url = "",
  behance = "",
  android = "",
  apple = "",
  rustore = "",
  download = "",
}) => {
  return (
    <LargeImg onClick={handleToggleExtand}>
      <FlipContainer $isFlipped={isOpen}>
        <FlipFrontSC imageUrl={imageUrl} title={title} />
        <FlipBackSC
          imageUrl={imageUrl}
          url={url}
          behance={behance}
          android={android}
          rustore={rustore}
          apple={apple}
          download={download as { type: "download"; url: string }}
          title={title}
        />
      </FlipContainer>
    </LargeImg>
  );
};

const LargeImg = styled.div`
  grid-column: span 3;
  grid-row: span 3;
  position: relative;

  height: 100%;
`;

const FlipContainer = styled.div<{ $isFlipped: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  transform-style: preserve-3d;
  transition: transform 0.4s;
  transform: rotateY(${({ $isFlipped }) => ($isFlipped ? "180deg" : "0")});
`;

export default CasesLargeImg;
