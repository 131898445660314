import { FC } from "react";
import { ReactComponent as VkIcon } from "assets/icon/VkIcon.svg";
import { ReactComponent as TelegramIcon } from "assets/icon/TelegramIcon.svg";
import { ReactComponent as WorkspaceIcon } from "assets/icon/WorkspaceIcon.svg";
import { ReactComponent as BehanceIcon } from "assets/icon/BehanceIcon.svg";
import { ReactComponent as VCRUIcon } from "assets/icon/VCRUIcon.svg";

export interface FooterItemType {
  link: string;
  Icon: FC;
}

export const footerData: FooterItemType[] = [
  {
    link: "https://vc.ru/u/1268124-index/entries",
    Icon: VCRUIcon,
  },
  {
    link: "https://www.behance.net/INDEX-STUDIO",
    Icon: BehanceIcon,
  },
  {
    link: "https://workspace.ru/contractors/digital-studiya-index/",
    Icon: WorkspaceIcon,
  },
  {
    link: "https://t.me/INDEX_RU",
    Icon: TelegramIcon,
  },
  {
    link: "https://vk.com/index_ds",
    Icon: VkIcon,
  },
];
