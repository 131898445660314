import React from "react";
import { useContactsPageStyles } from "../ContactsPage.styles";
import ContactsNav from "../ContactsNav/ContactsNav";
import Lottie from "lottie-react";
import PhoneAnimation from "assets/animation/phone.json";
import DiscusProject from "routes/pages/PricePage/CalculatedPage/DisscusProject/DiscusProject";

const PhonePage = () => {
  return (
    <ContainerSC>
      <PageContainerSC>
        <TextSC>
          <a href="tel:89191232395">+7 (919) 123-23-95</a>
          <a href="tel:89191232392">+7 (919) 123-23-92</a>
        </TextSC>
        <ContentContainerCS>
          <Lottie animationData={PhoneAnimation} />
        </ContentContainerCS>

        <ContactsNav />
      </PageContainerSC>
      <DiscusProject />
    </ContainerSC>
  );
};

const { ContainerSC, PageContainerSC, TextSC, ContentContainerCS } =
  useContactsPageStyles();

export default React.memo(PhonePage);
