import { css } from "styled-components";

export const DisableCursorMixin = css`
  body,
  a,
  button,
  input {
    cursor: none;
  }
`;

export const OverflowMixin = css`
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
`;
