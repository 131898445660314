import { CasesModelType } from "lib/models/CasesModelType";
import { ReactComponent as WorkDirectIcon } from "assets/icon/WorkDirectIcon.svg";
import { ReactComponent as CleexIcon } from "assets/icon/CleexIcon.svg";
import { ReactComponent as KvikIcon } from "assets/icon/KvikIcon.svg";
import { ReactComponent as OGIcon } from "assets/icon/OGIcon.svg";
import { ReactComponent as OWIcon } from "assets/icon/OWIcon.svg";
import { ReactComponent as TableIcon } from "assets/icon/TableIcon.svg";
import { ReactComponent as ServicesInPocketIcon } from "assets/icon/ServicesInPocketIcon.svg";
import { ReactComponent as LikeIcon } from "assets/icon/LikeIcon.svg";
import { ReactComponent as WindmillIcon } from "assets/icon/WindmillIcon.svg";
import { ReactComponent as FrenchFries } from "assets/icon/FrenchFries.svg";
import { ReactComponent as MkpTehnic } from "assets/icon/MkpTehnic.svg";
import { FilesNamespace } from "lib/constants/enums/FilesNamespace";

export const casesData: CasesModelType[] = [
  {
    title: "WorkDirect",
    subtitle: "Агрегатор бирж фриланса\n«WorkDirect»",
    desc: "Создадим лучшие решения для вашего бизнеса",
    Icon: WorkDirectIcon,
    imageUrl: `${FilesNamespace.IMAGES}/workDirect.jpg`,
    url: "https://workdirect.ru/",
    behance: "/144593911/Work-Direct-agregator-birzh-frilansa",
  },
  {
    title: "Сервис безналичных чаевых",
    subtitle: "Сервис\nбесконтактных\nчаевых",
    desc: "Предоставляем бессрочную гарантию на разработанные продукты",
    Icon: CleexIcon,
    imageUrl: `${FilesNamespace.IMAGES}/tips.jpg`,
  },
  {
    title: "CLEEX",
    subtitle: "Сервис бесплатных объявлений «CLEEX»",
    desc: "разрабатываем высоко нагруженные проекты и многозадачные сервисы",
    Icon: KvikIcon,
    imageUrl: `${FilesNamespace.IMAGES}/cleex.jpg`,
    url: "https://cleex.ru/",
    android: "https://play.google.com/store/apps/details?id=com.cleex",
    apple: "https://apps.apple.com/ru/app/cleex/id6473770758",
    rustore: "https://apps.rustore.ru/app/com.cleex",
  },
  {
    title: "Онлайн-таблица",
    subtitle: "Онлайн-таблица\nдля работы\nс маркетплейсами",
    desc: "предоставляем самые нестандартные решения",
    Icon: TableIcon,
    imageUrl: `${FilesNamespace.IMAGES}/table.jpg`,
  },
  {
    title: "Услуги в кармане",
    subtitle: "Мобильное\nприложение\n“Услуги в кармане”",
    desc: "обеспечиваем техническую поддержку сайтов",
    Icon: ServicesInPocketIcon,
    imageUrl: `${FilesNamespace.IMAGES}/serviceInPocket.jpg`,
    download: {
      type: "download",
      url: FilesNamespace.APK_SERVICE,
    },
  },
  {
    title: "Octo Global",
    subtitle: "Octo Global - Сервис для доставки\nтоваров из Европы",
    desc: "учитываем все ваши пожелания с вниманием и заботой",
    Icon: OGIcon,
    imageUrl: `${FilesNamespace.IMAGES}/octoGlobal.jpg`,
    url: "https://octo.global/",
  },
  {
    title: "Открытый мир",
    subtitle: "Мобильное приложение “Открытый мир”",
    desc: "Создаем уникальный дизайн бренда",
    Icon: OWIcon,
    imageUrl: `${FilesNamespace.IMAGES}/openWorld.jpg`,
    android: "https://play.google.com/store/apps/details?id=com.altaygosapp",
    apple: "https://apps.apple.com/app/id1658232544",
    behance:
      "/157108385/razrabotka-mobilnogo-prilozhenija-dlja-pomoschi-detjam",
    download: {
      type: "link",
      url: "https://1openworld.ru/media/build/file/app-release.apk",
    },
  },
  {
    title: "Office Mobile",
    subtitle: "Mобильное приложение \n" + "«ОФИС.Mobile»",
    desc: "составляем подробное техническое задание",
    Icon: LikeIcon,
    imageUrl: `${FilesNamespace.IMAGES}/officeMobile.jpg`,
    behance: "/162238625/redizajn-mobilnogo-prilozhenija-ofisMobile",
  },
  {
    title: "Сайт РРЦ",
    subtitle: "Сайт РРЦ для детей с ограниченными возможностями",
    desc: "Собираем самые лучшие кейсы",
    Icon: WindmillIcon,
    url: "https://rrc-altay.ru/",
    imageUrl: `${FilesNamespace.IMAGES}/baikal.jpg`,
    behance: "/173139157/razrabotka-cajta",
  },
  {
    title: "С.А.Ф. «Арена»",
    subtitle: "Сервис автоматизации фудкорта «Арена»",
    desc: "Воплощаем смелые идеи",
    Icon: FrenchFries,
    imageUrl: `${FilesNamespace.IMAGES}/SaafArena.jpg`,
    url: "https://xn--80aamc1apkkgugl.xn--p1ai/",
    behance: "/180253143/servis-avtomatizacii-fudkorta-arena",
  },
  {
    title: "Техник",
    subtitle: "Мобильное приложение «Техник»",
    desc: "Воплощаем смелые идеи",
    Icon: MkpTehnic,
    imageUrl: `${FilesNamespace.IMAGES}/MkpTehnic.jpg`,
    behance: "/191881613/mkp-lider",
  },
];
