import React from "react";
import { CasesModelType } from "lib/models/CasesModelType";
import styled from "styled-components";
import { BEHANCE_BASE_URL } from "lib/constants/constants";
import { ReactComponent as WebsiteIcon } from "assets/icon/WebsiteIcon.svg";
import { ReactComponent as AndroidIcon } from "assets/icon/AndroidIcon.svg";
import { ReactComponent as RustoreIcon } from "assets/icon/RustoreIcon.svg";
import { ReactComponent as AppleIcon } from "assets/icon/AppleIcon.svg";
import { ReactComponent as BehanceIconTwo } from "assets/icon/BehanceIconTwo.svg";
import { ReactComponent as DownloadIcon } from "assets/icon/DownloadIcon.svg";
import SocialItem from "./SocialItem/SocialItem";
import { Breakpoints } from "theme/styles/breakpoints";

type CasesSwiperSocialProps = Omit<
  CasesModelType,
  "title" | "desc" | "subtitle" | "imageUrl" | "Icon"
>;

const CasesSwiperSocial = ({
  url,
  android,
  apple,
  behance,
  rustore,
  download,
}: CasesSwiperSocialProps) => {
  return (
    <ContainerSC>
      {url && (
        <SocialItem href={url}>
          <WebsiteIcon />
        </SocialItem>
      )}
      {download && (
        <SocialItem href={download.url} download={download.type === "download"}>
          <DownloadIcon />
        </SocialItem>
      )}
      {android && (
        <SocialItem href={android}>
          <AndroidIcon />
        </SocialItem>
      )}
      {apple && (
        <SocialItem href={apple}>
          <AppleIcon />
        </SocialItem>
      )}
      {rustore && (
        <SocialItem href={rustore}>
          <RustoreIcon />
        </SocialItem>
      )}
      {behance && (
        <SocialItem href={`${BEHANCE_BASE_URL}${behance}`}>
          <BehanceIconTwo />
        </SocialItem>
      )}
    </ContainerSC>
  );
};

const ContainerSC = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    transform: rotate(180deg);
  }
`;

export default React.memo(CasesSwiperSocial);
