import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";
import { CircularRotateType, CircularTextProps } from "UI/CircularTextUI/types";
import { Breakpoints } from "theme/styles/breakpoints";

export const AnimRotateRight = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
`;

export const AnimRotateLeft = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
`;

const Animation = (rotate: CircularRotateType) => css`
  animation: 10s linear 0s normal none infinite running
    ${rotate === "left" ? AnimRotateLeft : AnimRotateRight};
`;

const ParagraphSC = styled(motion.div)<Omit<CircularTextProps, "children">>`
  font-size: ${({ isLarge, fontSize = 30 }) =>
    isLarge ? fontSize + 6 : fontSize}px;
  font-weight: 400;
  line-height: 28px;

  transition: 0.2s all linear;
  ${({ rotate }) => rotate && Animation(rotate)}

  user-select: none;

  & > div *:last-child {
    opacity: 0;
  }

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: ${({ isLarge, fontSize = 27 }) =>
      isLarge ? fontSize + 6 : fontSize}px;
    font-weight: 500;
    letter-spacing: 0.81px;
  }
  @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
    font-size: ${({ isLarge, fontSize = 23 }) =>
      isLarge ? fontSize + 6 : fontSize}px;
    letter-spacing: 0.69px;
  }
`;

export const useCircularTextUIStyles = () => ({ ParagraphSC });
