import { cursorStore } from "store/cursorStore/cursorStore";
import {
  ChangeActiveFuncType,
  ChangeLoadingFuncType,
} from "store/cursorStore/types";
import { MouseEvent } from "react";

export const changeCursorLoading = () => {
  const changeLoading = cursorStore((state) => state.changeLoading);

  const handleChangeCursorLoading: ChangeLoadingFuncType = (loading) => {
    changeLoading(loading);
  };

  return { handleChangeCursorLoading };
};

export type MouseEnterType<T> = MouseEvent<T>;

export const changeCursorActive = () => {
  const changeActive = cursorStore((state) => state.changeActive);

  const handleChangeCursorActive: ChangeActiveFuncType = (hover) => {
    changeActive(hover);
  };

  const onChangeActive = <T>(
    state: boolean,
    callback?: (e?: MouseEnterType<T>) => void,
  ) => {
    return (event?: MouseEnterType<T>) => {
      if (callback) callback(event);
      handleChangeCursorActive(state);
    };
  };

  const changeMouseProps = {
    onMouseEnter: onChangeActive(true),
    onMouseLeave: onChangeActive(false),
  };

  return { onChangeActive, changeMouseProps };
};
