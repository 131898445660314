import { PriceNamespace } from "lib/constants/enums/PagesNamespace";

interface PriceDataItem {
  url: string;
  content: string;
}

export interface PricePageI {
  data: PriceDataItem[];
}

export const priceValues: Partial<{
  [key in PriceNamespace]: {
    price: number;
    text: string;
    isOther?: boolean;
    time?: string;
  }[];
}> = {
  [PriceNamespace.PRICE_MOBILE_APP]: [
    { price: 50000, text: "ui/ux дизайн" },
    { price: 10000, text: "Размещение в apple store" },
    { price: 10000, text: "Размещение в google play" },
    { price: 25000, text: "Нэйминг" },
    { price: 20000, text: "визуальная концепция" },
    { price: 70000, text: "CMS" },
    { price: 10000, text: "формирование ТЗ" },
    { price: 2000, isOther: true, time: " / в час", text: "разработка на ios" },
    {
      price: 2000,
      isOther: true,
      time: " / в час",
      text: "разработка на android",
    },
  ],
  [PriceNamespace.PRICE_PERSON_SITE]: [
    { price: 50000, text: "ui/ux дизайн" },
    { price: 25000, text: "Разработка логотипа" },
    { price: 25000, text: "Нэйминг" },
    { price: 20000, text: "визуальная концепция" },
    { price: 10000, text: "формирование ТЗ" },
    { price: 100000, text: "программирование" },
    { price: 2000, isOther: true, time: " / год", text: "хостинг" },
  ],
  [PriceNamespace.PRICE_CORPORATE_SITE]: [
    { price: 50000, text: "ui/ux дизайн" },
    { price: 25000, text: "Нэйминг" },
    { price: 25000, text: "Разработка логотипа" },
    { price: 20000, text: "визуальная концепция" },
    { price: 10000, text: "формирование ТЗ" },
    { price: 200000, text: "программирование" },
    { price: 3000, text: "обратная связь" },
    { price: 1500, isOther: true, time: " / в час", text: "поддержка" },
    { price: 2000, isOther: true, time: " / год", text: "хостинг" },
  ],
  [PriceNamespace.PRICE_ONLINE_SHOP]: [
    { price: 50000, text: "ui/ux дизайн" },
    { price: 25000, text: "Нэйминг" },
    { price: 25000, text: "Разработка логотипа" },
    { price: 20000, text: "визуальная концепция" },
    { price: 10000, text: "формирование ТЗ" },
    { price: 1500, isOther: true, time: " / в час", text: "программирование" },
    { price: 36000, text: "каталог товаров" },
    { price: 24000, text: "корзина" },
    { price: 30000, text: "Личный кабинет" },
    { price: 3000, text: "обратная связь" },
    { price: 70000, text: "CMS" },
    { price: 24000, text: "Оплата" },
    { price: 60000, text: "Чат" },
    { price: 2000, isOther: true, time: " / год", text: "хостинг" },
    { price: 1500, isOther: true, time: " / в час", text: "поддержка" },
    { price: 12000, text: "Аутентификация" },
    { price: 4500, text: "карточка товара" },
  ],
  [PriceNamespace.PRICE_DESIGN]: [
    { price: 50000, text: "ui / ux дизайн" },
    { price: 25000, text: "Нэйминг" },
    { price: 25000, text: "разработка логотипа" },
    { price: 20000, text: "визуальная концепция" },
  ],
};

export const priceData: PricePageI = {
  data: [
    { url: PriceNamespace.PRICE_MOBILE_APP, content: "мобильное приложение" },
    {
      url: PriceNamespace.PRICE_CORPORATE_SITE,
      content: "Корпоративный сайт",
    },
    { url: PriceNamespace.PRICE_ONLINE_SHOP, content: "интернет магазин" },
    { url: PriceNamespace.PRICE_DESIGN, content: "Дизайн" },
    { url: PriceNamespace.PRICE_PERSON_SITE, content: "имиджевый сайт" },
    { url: PriceNamespace.PRICE_MOBILE_APP, content: "мобильное приложение" },
    {
      url: PriceNamespace.PRICE_CORPORATE_SITE,
      content: "Корпоративный сайт",
    },
    { url: PriceNamespace.PRICE_ONLINE_SHOP, content: "интернет магазин" },
    { url: PriceNamespace.PRICE_DESIGN, content: "Дизайн" },
    { url: PriceNamespace.PRICE_PERSON_SITE, content: "имиджевый сайт" },
  ],
};
