import { StylePattern } from "routes/pages/AboutPage/types";

export interface StylesPatterns {
  [key: string]: StylePattern[];
}

export const stylesPatterns: StylesPatterns = {
  firstPattern: [
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 600",
      height: "20px",
      width: "150px",
      marginLeft: `0`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 700",
      width: "70px",
      marginLeft: `10%`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "240px",
      marginLeft: `calc(100% - 240px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 400",
      width: "250px",
      marginLeft: "0%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 600",
      width: "153px",
      marginLeft: `calc(100% - 153px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      marginLeft: "10%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      height: "15px",
      marginLeft: "44%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      marginLeft: "55%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 600",
      marginLeft: "0",
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 400",
      width: "245px",
      marginLeft: `calc(100% - 245px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      marginLeft: `0`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      width: "187px",
      height: "20px",
      marginLeft: `calc(100% - 187px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      marginLeft: `40%`,
    },
  ],
  secondPattern: [
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 600",
      height: "20px",
      width: "150px",
      marginLeft: `calc(90% - 150px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 700",
      width: "70px",
      marginLeft: `calc(100% - 70px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "225px",
      marginLeft: `0`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      width: "240px",
      marginLeft: `calc(100% - 240px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "146px",
      marginLeft: "0",
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 700",
      marginLeft: "44%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      height: "15px",
      marginLeft: "8%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      marginLeft: "25%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 100",
      width: "213px",
      marginLeft: `calc(100% - 213px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      marginLeft: "10%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 400",
      width: "240px",
      marginLeft: `calc(100% - 240px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 200",
      height: "20px",
      marginLeft: `0%`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 200",
      marginLeft: `20%`,
    },
  ],
  thirdPattern: [
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      height: "20px",
      width: "150px",
      marginLeft: `30%`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "70px",
      marginLeft: `20%`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 500",
      width: "240px",
      marginLeft: `calc(100% - 240px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 100",
      marginLeft: "8%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "146px",
      marginLeft: "45%",
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      marginLeft: "0",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 800",
      height: "15px",
      width: "95px",
      marginLeft: `calc(100% - 95px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 400",
      marginLeft: "50%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 100",
      marginLeft: "8%",
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 600",
      marginLeft: "20%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 400",
      marginLeft: `3%`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 200",
      height: "20px",
      marginLeft: `40%`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 200",
      marginLeft: `35%`,
    },
  ],
  fourPattern: [
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      height: "20px",
      width: "150px",
      marginLeft: `50%`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 300",
      width: "70px",
      marginLeft: `70%`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 500",
      marginLeft: `1%`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 700",
      width: "250px",
      marginLeft: `calc(100% - 250px)`,
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 200",
      width: "146px",
      marginLeft: "0",
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 400",
      marginLeft: "50%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 600",
      height: "15px",
      marginLeft: "0",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 100",
      marginLeft: "4%",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 400",
      width: "213px",
      marginLeft: `calc(100% - 213px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 100",
      marginLeft: "0",
    },
    {
      fontFamily: "Roboto Slab",
      fontVariationSettings: "'wght' 200",
      width: "240px",
      marginLeft: `calc(100% - 240px)`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 500",
      height: "20px",
      marginLeft: `22%`,
    },
    {
      fontFamily: "Montserrat",
      fontVariationSettings: "'wght' 500",
      marginLeft: `9%`,
    },
  ],
};
