import styled from "styled-components";

const ImageContainerSC = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    &:first-child {
      width: 35%;
      margin-right: 28px;
    }

    &:nth-child(2) {
      //width: 135px;
      flex-grow: 1;
      width: 65%;
    }
  }
`;

const LinkSC = styled.a`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  max-width: 274px;

  text-align: center;
`;

const ContainerNumbersSC = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpanContainerSC = styled.span`
  text-transform: uppercase;
`;

export const CPUMobileStyles = () => ({
  ImageContainerSC,
  LinkSC,
  ContainerNumbersSC,
  SpanContainerSC,
});
