import React from "react";
import { useGiftPageMobileStyles } from "routes/pages/GiftPage/GiftPageMobile/useGPM.styles";
import { ReactComponent as LogoIndexImg } from "assets/icon/logoIndexCertificate.svg";
import { PagesNamespace } from "lib/constants/enums/PagesNamespace";
import GPDNMobile from "routes/pages/GiftPage/GiftPageMobile/GPDNMobile/GPDNMobile";
import GPMToggleInfo from "routes/pages/GiftPage/GiftPageMobile/GPMToggleInfo/GPMToggleInfo";
import { useGiftPageMobile } from "routes/pages/GiftPage/useGiftPageMobile";
import GPMToggleButton from "routes/pages/GiftPage/GiftPageMobile/GPMToggleButton/PopupToggleButton";
import GPMPopupCondition from "routes/pages/GiftPage/GiftPageMobile/GPMPopupCondition/GPMPopupCondition";

interface GiftPageMobileProps {
  isLoading: boolean;
  costData: number;
}

const GiftPageMobile = ({ costData, isLoading }: GiftPageMobileProps) => {
  const { selectedIcon, isOpenPopup, handleIconClick, handleTogglePopup } =
    useGiftPageMobile();

  return (
    <>
      <ContainerSC isLoading={isLoading}>
        <TopContainerSC>
          <ImageContainerSC>
            <LogoIndexImg />
          </ImageContainerSC>
          <GPMToggleButton
            isClicked={isOpenPopup}
            handleClickInfo={handleTogglePopup}
          />
        </TopContainerSC>
        <MainContainer>
          <GPMToggleInfo cost={costData} selectedIcon={selectedIcon} />
          <GPDNMobile
            selectedIcon={selectedIcon}
            handleIconClick={handleIconClick}
          />
        </MainContainer>
        <LinkSC to={PagesNamespace.INDEX}>на главную</LinkSC>
      </ContainerSC>

      {isOpenPopup && <GPMPopupCondition cost={costData} />}
    </>
  );
};

const { ContainerSC, TopContainerSC, ImageContainerSC, MainContainer, LinkSC } =
  useGiftPageMobileStyles();

export default React.memo(GiftPageMobile);
