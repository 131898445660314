import styled from "styled-components";
import { motion } from "framer-motion";

const ContainerSC = styled.li`
  text-align: justify;

  transition: margin-left 0.8s linear;

  display: flex;
  flex-wrap: nowrap;

  position: relative;

  height: 50px;
`;

const ItemSC = styled(motion.span)`
  position: absolute;
  font-size: 22px;
  font-style: normal;
  line-height: 27px;

  text-align: center;

  white-space: pre-wrap;

  transition: margin-left 1s cubic-bezier(0.21, 0.61, 0.35, 1);
`;

export const useAboutPageMobileItem = () => ({
  ContainerSC,
  ItemSC,
});
