export enum Timing {
  DURATION_FAST = 300,
  DURATION = 500,
  DURATION_TWO = 1000,
  MARQUEE = 100,
  MARQUE_INTERVAL = 177.5,
  CIRCULAR_PROGRESS_TIMING = 1000,
  CIRCULAR_PROGRESS_TIMING_2 = 1100,

  HEADER_SCROLL = 150,
  HEADER_SCROLL_MOBILE = 150,
  HEADER_CLOSE = 1000,
  HEADER_CLOSE_MOBILE = 1500,
  HEADER_NAVIGATE = 500,
  HEADER_NAVIGATE_MOBILE = 100,

  PARTNER_CHANGE = 1500,
  SWIPER_AUTOPLAY = 2500,
  ABOUT_DELAY = 2000,
}
