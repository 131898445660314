import React from "react";
import { usePopupFormStyles } from "components/DiscussProjectForm/DiscussProjectForm.styles";
import { ReactComponent as CloseIcon } from "assets/icon/CloseIcon.svg";
import { RespDataType } from "components/DiscussProjectForm/types";
import { changeCursorActive } from "store/cursorStore/useCursorStoreActions";

interface SuccessFormI {
  respData: RespDataType;
  onClickExit: () => void;
}

const SuccessForm = ({ respData, onClickExit }: SuccessFormI) => {
  const { changeMouseProps } = changeCursorActive();

  return (
    <>
      <TopContainerSC>
        <SuccessSubtitle>
          Ваш подарочный сертификат на {respData.cost} рублей. Инвестируй в свой
          бизнес!{" "}
        </SuccessSubtitle>
        <ButtonSC type={"button"} onClick={onClickExit}>
          <CloseIcon />
        </ButtonSC>
      </TopContainerSC>
      <ImgSC src={respData.qr || ""} />
      <LinkSC {...changeMouseProps} href={respData.url ?? ""}>
        Ссылка на сертификат
      </LinkSC>
      <SuccessSubtitle>
        <SpanSC
          {...changeMouseProps}
          onClick={() => downloadImage(respData.qr || "")}>
          Сохрани
        </SpanSC>{" "}
        QR-code или скопируй ссылку. Срок акции 1 месяц. Не упусти свой шанс!
      </SuccessSubtitle>
    </>
  );
};

const downloadImage = (respData: string) => {
  const link = document.createElement("a");
  link.href = respData;
  link.download = "qr.png";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const { SpanSC, SuccessSubtitle, ImgSC, TopContainerSC, ButtonSC, LinkSC } =
  usePopupFormStyles();

export default React.memo(SuccessForm);
