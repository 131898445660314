import { createGlobalStyle } from "styled-components";
import { DisableCursorMixin, OverflowMixin } from "theme/styles/mixins";
import { ColorScheme } from "theme/styles/colorScheme";
import { Breakpoints } from "theme/styles/breakpoints";

export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    ${OverflowMixin}
  }
  body {
    height: 100%;
    min-height: 100%;

    width: 100vw;
    font-family: "Montserrat", sans-serif;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};

    ${OverflowMixin}
  }
  
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
  }

  main {
    flex-grow: 1;
    position: relative;
  }

  .mobile__container {
    @media (max-width: ${Breakpoints.BIG_TABLET}px) {
      padding: 0 66px;
    }

    @media (max-width: ${Breakpoints.TABLE}px) {
      padding: 0 30px;
    }

    @media (max-width: ${Breakpoints.BIG_MOBILE}px) {
      padding: 0 15px;
    }
  }

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
  }

  button {
    border: 0;
    font-family: inherit;
    background-color: ${ColorScheme.TRANSPARENT};
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  li {
    list-style: none;
  }

  input,
  textarea {
    -webkit-appearance: none;
  }

  ${DisableCursorMixin}


  // TODO Обращание к marqueeUI
  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative; /* Добавляем позиционирование */
    height: 100px;
    width: 100%;
  }

  .marquee-content {
    display: inline-block;
    animation: marqueeAnimation 20s linear infinite;
    position: absolute; /* Добавляем позиционирование */
    top: 0; /* Устанавливаем верхний край на 0 */
    left: 100%; /* Перемещаем вне области контейнера */
  }

  @keyframes marqueeAnimation {
    0% {
      transform: translateX(0); 
    }
    100% {
      transform: translateX(-100%);
    }
  }
`;
