import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ICursorStoreState } from "store/cursorStore/types";

export const cursorStore = create<ICursorStoreState>()(
  devtools((set) => ({
    isActive: false,
    isLoading: false,
    changeLoading: (loading) =>
      set(() => ({
        isLoading: loading,
      })),
    changeActive: (active) =>
      set(() => ({
        isActive: active,
      })),
  })),
);
