import styled from "styled-components";

const ContainerSC = styled("nav")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonSC = styled("button")`
  border: 0;
  padding: 10px;
  background-color: transparent;
`;

const ParagraphSC = styled("p")`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const useCasesSwiperNavStyles = () => ({
  ContainerSC,
  ButtonSC,
  ParagraphSC,
});
