import styled from "styled-components";
import { Breakpoints } from "theme/styles/breakpoints";
import { Link } from "react-router-dom";
import { ColorScheme } from "theme/styles/colorScheme";

const ContainerSC = styled.div<{ isLoading: boolean }>`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
`;

const TopContainerSC = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 0;

  color: #fff;

  @media (max-width: ${Breakpoints.TABLE}px) {
    display: none;
  }
`;

const ImageContainerSC = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    gap: 36px;

    img {
      &:first-child {
        width: 82px;
      }

      &:nth-child(2) {
        width: 162px;
      }
    }
  }
`;

const MainContainer = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;

  padding: 0 20px;

  color: ${ColorScheme.WHITE};
  text-align: center;

  transition: all 0.4s linear;
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

  @media (max-width: ${Breakpoints.TABLE}px) {
    justify-content: flex-start;
    margin-top: 10vh;
  }
`;

const LinkSC = styled(Link)`
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  text-transform: uppercase;

  text-align: center;
  flex-basis: 60px;

  @media (max-width: ${Breakpoints.BIG_TABLET}px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: ${Breakpoints.TABLE}px) {
    font-size: 15px;
  }
`;

const HelloSC = styled.h6`
  margin-bottom: 8px;

  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
`;

const SubtitleSC = styled.p`
  margin-bottom: 24px;

  font-size: 30px;
  font-weight: 600;
`;

const PriceSC = styled.p`
  margin-bottom: 56px;

  font-size: 160px;
  font-weight: 600;
  line-height: 156.393px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const TextSC = styled.p`
  color: ${ColorScheme.GRAY_THREE};
  font-size: 14px;
  font-weight: 600;
`;

const TextContainerSC = styled.div`
  width: 100%;
  max-width: 810px;

  line-height: 1;
`;

export const useGiftPageStyles = () => ({
  TopContainerSC,
  ImageContainerSC,
  MainContainer,
  LinkSC,
  ContainerSC,
  HelloSC,
  SubtitleSC,
  PriceSC,
  TextSC,
  TextContainerSC,
});
